import { useTranslation } from "react-i18next";
import Text from "../../components/common/uikit/Text";

import attention from "../../assets/icons/icon-attention.svg";
import Slide from "../../components/common/carousels/Slide";

const DisplayWarningSlides = () => {
  const { t } = useTranslation("onboarding");

  return (
    <Slide>
      <div className="px-8 py-8">
        <img
          src={attention}
          className="mx-auto w-36 h-36"
          alt="warning"
        />
        <Text type="Headline M">{t("slide.scratch.p1")}</Text>

        <div className="p-2 my-8 bg-white rounded">
          <Text
            type="Headline M"
            className="text-easycheck-success-green"
            asChild
          >
            <span>{t("slide.scratch.p2")}</span>
          </Text>
          <Text
            type="Caption A"
            className="text-easycheck-success-green"
            asChild
          >
            <span>{t("slide.scratch.p3")}</span>
          </Text>
        </div>
      </div>
    </Slide>
  );
};

export default DisplayWarningSlides;
