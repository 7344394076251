import classNames from "classnames";
import React from "react";
import PaginationDot from "./PaginationDot";

type PaginationProps = {
  dots: number;
  index: number;
  onChangeIndex: any;
  handleOnPaginationClick: any;
  gray?: boolean;
};

class Pagination extends React.Component<PaginationProps> {
  static defaultProps = {
    gray: false,
  };
  handleOnPaginationClick = (event: any, index: number) => {
    this.props.handleOnPaginationClick(event, index);
  };

  render() {
    const children = [];

    for (let i = 0; i < this.props.dots; i += 1) {
      children.push(
        <PaginationDot
          key={i}
          index={i}
          active={i === this.props.index}
          handleOnClick={this.handleOnPaginationClick}
        />
      );
    }

    return (
      <div className="flex justify-center">
        <div
          className={classNames(
            "inline-flex items-center px-2 py-1 bg-opacity-75 rounded-full",
            { "bg-white": !(this.props.gray === true) },
            { "bg-gray-200": this.props.gray === true }
          )}
        >
          {children}
        </div>
      </div>
    );
  }
}

export default Pagination;
