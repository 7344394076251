import Button from "../components/common/uikit/Button";
import Text from "../components/common/uikit/Text";
import ProcessIndicator from "../components/common/uikit/ProcessIndicator";
import { RadioGroup, RadioGroupItem } from "../components/common/uikit/RadioGroup";
import BackButton from "../components/common/uikit/BackButton";
import Step from "../components/common/uikit/Step";
import Block from "../components/common/uikit/Block";

const UIKit = () => {
  return (
    <div className="max-w-screen-md p-4 mx-auto">
      <h1>UI Kit</h1>
      <div className="grid gap-8 md:grid-cols-2">
        <h2>Text</h2>
        <div className="grid gap-4">
          <Text type="Headline XL">Text XL</Text>
          <Text type="Headline L">Text Large</Text>
          <Text type="Headline M">Text Medium</Text>
          <Text type="Headline M small">Text Medium - small Screen</Text>
          <Text type="Headline S">Text Small</Text>
          <Text type="Headline S small">Text Small - small Screen</Text>
          <Text type="Body">Body</Text>
          <Text type="Caption A">Caption A</Text>
          <Text type="Caption B">Caption B</Text>
          <Text type="Caption C">Caption C</Text>
        </div>

        <h2>Colors</h2>
        <div className="grid gap-4">
          <div className="p-2 bg-easycheck-bluepurple">"easycheck-bluepurple": "#070084"</div>
          <div className="p-2 bg-easycheck-blue">"easycheck-blue": "#00368E"</div>
          <div className="p-2 bg-easycheck-red">"easycheck-red": "#FA0000"</div>
          <div className="p-2 bg-easycheck-shade-dark">"easycheck-shade-dark": "#4464E3"</div>
          <div className="p-2 bg-easycheck-shade-medium">"easycheck-shade-medium": "#92B4F8"</div>
          <div className="p-2 bg-easycheck-shade-light">"easycheck-shade-light": "#BED1F9"</div>
          <div className="p-2 bg-easycheck-light-blue">"easycheck-light-blue": "#EFF1FC"</div>
          <div className="p-2 bg-easycheck-light-gray">"easycheck-light-gray": "#F3F3F3"</div>
        </div>

        <h2>Process Indicator</h2>
        <div className="grid grid-cols-6 gap-4">
          <ProcessIndicator
            current={1}
            count={6}
          />
          <ProcessIndicator
            current={2}
            count={6}
          />
          <ProcessIndicator
            current={3}
            count={6}
          />
          <ProcessIndicator
            current={4}
            count={6}
          />
          <ProcessIndicator
            current={5}
            count={6}
          />
          <ProcessIndicator
            current={6}
            count={6}
          />

          <ProcessIndicator
            current={1}
            count={6}
            screenSize="small"
          />
          <ProcessIndicator
            current={2}
            count={6}
            screenSize="small"
          />
          <ProcessIndicator
            current={3}
            count={6}
            screenSize="small"
          />
          <ProcessIndicator
            current={4}
            count={6}
            screenSize="small"
          />
          <ProcessIndicator
            current={5}
            count={6}
            screenSize="small"
          />
          <ProcessIndicator
            current={6}
            count={6}
            screenSize="small"
          />
        </div>

        <h2>Blocks</h2>
        <div className="space-y-4">
          <Block size="small">Du hast noch 2 Tage</Block>
          <Block>Du hast noch 2 Tage</Block>
        </div>

        <h2>Buttons & Links</h2>
        <div className="space-y-4">
          <div className="space-x-10">
            <Button size="small">Los geht's</Button>
          </div>
          <div>
            <Button>Los geht's</Button>
          </div>
          <div className="inline-flex items-center justify-center p-4 space-x-10 bg-easycheck-light-gray">
            <BackButton size="small" />
            <BackButton />
          </div>
        </div>

        <h2>Radio Group</h2>
        <div className="grid gap-4">
          <RadioGroup>
            <RadioGroupItem
              value="default"
              title="Ja"
            />
          </RadioGroup>
          <RadioGroup defaultValue="nein">
            <RadioGroupItem
              value="nein"
              title="Nein"
            />
            <RadioGroupItem
              value="ja"
              title="Ja"
            />
          </RadioGroup>
        </div>

        <h2>Steps</h2>
        <div>
          <div className="inline-flex items-center justify-center p-4 space-x-10 bg-easycheck-light-gray">
            <Step title="1" />
            <Step title="2" />
            <Step title="3" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UIKit;
