import { useCallback, useEffect, useState } from "react";
import useEmblaCarousel from "embla-carousel-react";
import BackButton from "../components/common/uikit/BackButton";
import Button from "../components/common/uikit/Button";
import ProcessIndicator from "../components/common/uikit/ProcessIndicator";
import TutorialSlides from "./slides/TutorialSlides";
import TouchSettingsSlides from "./slides/TouchSettingsSlides";
import DisplayWarningSlides from "./slides/DisplayWarningSlides";
import { useTranslation } from "react-i18next";
import { PrismaSDK } from "@prismadelabs/prismaid";
import SDKSingleton from "../SDK";

export const NewOnboarding = () => {
  const { t } = useTranslation("onboarding");
  const [emblaRef, emblaApi] = useEmblaCarousel();

  const [current, setCurrent] = useState(1);
  const [count, setCount] = useState(1);

  useEffect(() => {
    // tmp set language
    // TODO remove
    const sdk: PrismaSDK = SDKSingleton.getInstance().sdk;
  }, []);

  const onPrevButtonClick = useCallback(() => {
    if (!emblaApi) return;

    if (current === 1) {
      alert("Going Back");
    } else {
      emblaApi.scrollPrev();
    }
  }, [emblaApi, current]);

  const onNextButtonClick = useCallback(() => {
    if (!emblaApi) return;

    if (current === count) {
      alert("Keep Going");
    } else {
      emblaApi.scrollNext();
    }
  }, [emblaApi, current, count]);

  useEffect(() => {
    if (!emblaApi) {
      return;
    }

    setCount(emblaApi.scrollSnapList().length);
    setCurrent(emblaApi.selectedScrollSnap() + 1);

    emblaApi.on("select", () => {
      setCurrent(emblaApi.selectedScrollSnap() + 1);
    });
  }, [emblaApi]);

  return (
    <div className="flex flex-col py-4 text-center min-h-dvh bg-easycheck-light-blue">
      <ProcessIndicator
        current={current}
        count={count}
      />

      <div
        className="flex flex-col mt-4 overflow-hidden grow"
        ref={emblaRef}
      >
        <div className="flex grow">
          <TutorialSlides />
          <TouchSettingsSlides />
          <DisplayWarningSlides />
        </div>
      </div>

      <div className="flex items-center justify-between px-8">
        <BackButton onClick={onPrevButtonClick} />
        <Button onClick={onNextButtonClick}>{t("button.ok_next")}</Button>
      </div>
    </div>
  );
};

export default NewOnboarding;
