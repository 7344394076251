import React from "react";
import { Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";
import { RootState } from "../redux";
import { setLicenseIsKnown, setLicenseType, setUserData } from "../redux/modules/user";
import {
  setInvocationIsValid,
  setDeviceIsSupported,
  setBrowserIsSupported,
  setScreenIsBigEnough,
  setDisplayZoomEnabled,
  setTouchSensitivityType,
} from "../redux/modules/app";
import { setScaleFactor } from "../redux/modules/swipe";
import { connect } from "react-redux";
import { PrismaSDK, UsabilityResponse } from "@prismadelabs/prismaid";
import { getScaleFactor } from "../helpers/scale";
import { setNeedsTouchSensitivity } from "../redux/modules/app";
import { withTranslation, WithTranslation } from "react-i18next";
import { compose } from "redux";
import ScrollableHeightWithHeader from "../components/common/layouts/ScrollableHeightWithHeader";
import Button from "../components/common/ui/Button";
import { LicenseType } from "../enums";
import SDKSingleton from "../SDK";
import { clearOldStorage } from "../helpers/localStorageHelper";

const mapStateToProps = (state: RootState) => ({});

const mapDispatchToProps = {
  setUserData,
  setInvocationIsValid,
  setDeviceIsSupported,
  setBrowserIsSupported,
  setScreenIsBigEnough,
  setDisplayZoomEnabled,
  setNeedsTouchSensitivity,
  setTouchSensitivityType,
  setScaleFactor,
  setLicenseType,
  setLicenseIsKnown,
};

type DemoProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps & WithTranslation;

type DemoStates = {
  redirect: string | null;
  scaleIsReady: boolean;
  supportedIsReady: boolean;
};

// component
class Demo extends React.Component<DemoProps, DemoStates> {
  driverId?: string;

  constructor(props: DemoProps) {
    super(props);

    clearOldStorage();

    this.state = {
      redirect: null,
      scaleIsReady: false,
      supportedIsReady: false,
    };

    // ?greeting=Ron&mode=code61&callbackUrl=nocallback&licenseType=1&originalURL=https%3A%2F%2Fgoogle.de&canRetry=true&driverId=abcde
    let data = {
      greeting: "Erika Mustermann",
      mode: "A028D",
      //   licenseType: 1,
      //   licenseIsKnown: true,
      callbackUrl: "nocallback",
      originalURL: "https://prismade.com",
      canRetry: true,
      driverId: "12345",
    };

    this.driverId = data.driverId;

    props.setUserData(data);
    props.setInvocationIsValid(true);

    this.getSDKInitializationData();
  }

  getSDKInitializationData() {
    const sdk: PrismaSDK = SDKSingleton.getInstance({ driverId: this.driverId }).sdk;

    sdk.getInitialisationSubject().subscribe((response) => {
      if (response.ppi) {
        var scale = getScaleFactor(response.ppi, response.devicePixelRatio);

        if (!Number.isNaN(scale)) {
          this.props.setScaleFactor(scale);
        }
      } else {
        this.props.setDeviceIsSupported(false);
      }

      if (response.deviceSupport?.requirements?.includes("touchsensitivity")) {
        this.props.setNeedsTouchSensitivity(true);
        this.props.setTouchSensitivityType("touchsensitivity");
      }

      if (response.deviceSupport?.requirements?.includes("glovemode")) {
        this.props.setNeedsTouchSensitivity(true);
        this.props.setTouchSensitivityType("glovemode");
      }

      if (response.deviceSupport?.requirements?.includes("pointerspeed")) {
        this.props.setNeedsTouchSensitivity(true);
        this.props.setTouchSensitivityType("pointerspeed");
      }

      if (response.isDisplayZoomEnabled) {
        this.props.setDisplayZoomEnabled(true);
      }

      setTimeout(() => {
        this.setState({
          scaleIsReady: true,
        });
      }, 10);
    });

    sdk.getUsabilitySubject().subscribe((response: UsabilityResponse) => {
      if (response.event === "device_not_supported") {
        this.props.setDeviceIsSupported(false);
      }

      if (response.event === "browser_not_supported") {
        this.props.setBrowserIsSupported(false);
      }

      if (response.event === "display_too_small_displacement") {
        this.props.setScreenIsBigEnough(false);
      }

      if (response.event === "display_small_should_add_to_home") {
        this.props.setScreenIsBigEnough(false);
      }

      this.setState({
        supportedIsReady: true,
      });
    });
  }

  componentWillUnmount() {
    // fix warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  onPaperClick = () => {
    this.props.setLicenseIsKnown(true);
    this.props.setLicenseType(LicenseType.Paper);
    this.setState({ redirect: "/" });
  };

  onPlasticClick = () => {
    this.props.setLicenseIsKnown(true);
    this.props.setLicenseType(LicenseType.Plastic);
    this.setState({ redirect: "/" });
  };

  onGrayClick = () => {
    this.props.setLicenseIsKnown(true);
    this.props.setLicenseType(LicenseType.Gray);
    this.setState({ redirect: "/" });
  };

  render() {
    // note: UsabilitySubject may also come back multiple times or not at all.
    // Redirect to next screen when scaleIsReady, and add some wait-time to allow for possible usabilitySubjectResponse.
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }

    if (this.state.scaleIsReady) {
      return (
        <ScrollableHeightWithHeader>
          <div className="flex flex-col justify-between flex-grow w-full px-6 pt-4 text-center whitespace-pre-wrap">
            <div>
              <h1 className="text-lg font-bold">{this.props.t("demo:title")}</h1>
            </div>
            <div>
              <Button
                title={this.props.t("demo:button_paper")}
                onClick={this.onPaperClick}
                className="w-full my-4"
              />
              <Button
                title={this.props.t("demo:button_plastic")}
                onClick={this.onPlasticClick}
                className="w-full my-4"
              />
              <Button
                title={this.props.t("demo:button_gray")}
                onClick={this.onGrayClick}
                className="w-full my-4"
              />
            </div>
          </div>
        </ScrollableHeightWithHeader>
      );
    }

    return (
      <div>
        <Helmet>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1"
          />
          <body className="bg-gray-900">Loading</body>
        </Helmet>
      </div>
    );
  }
}

export default compose<any>(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(Demo);
