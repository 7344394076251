import classNames from "classnames";
import React, { ReactNode } from "react";
import Header from "../Header";

interface Props {
  children: ReactNode;
  className?: string;
}

const ScrollableHeightWithHeader: React.FC<Props> = (props) => {
  return (
    <div className={classNames("w-screen min-h-dvh flex flex-col bg-white")}>
      <Header />
      {props.children}
    </div>
  );
};

export default ScrollableHeightWithHeader;
