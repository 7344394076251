import React from "react";
import { RootState } from "../../redux";
import { connect } from "react-redux";
import TutorialContentContainer from "./TutorialContentContainer";
import { LicenseType } from "../../enums";
import { Redirect } from "react-router-dom";
import TutorialSlide from "./TutorialSlide";
import { compose } from "redux";
import { withTranslation, WithTranslation } from "react-i18next";

// types
const mapStateToProps = (state: RootState) => ({
  licenseType: state.user.licenseType,
});

const mapDispatchToProps = {};

type TutorialContentProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  WithTranslation;

type TutorialContentStates = {};

// component
class TutorialContent extends React.Component<
  TutorialContentProps,
  TutorialContentStates
> {
  render() {
    if (this.props.licenseType === LicenseType.Paper) {
      return (
        <TutorialContentContainer>
          <TutorialSlide
            text={this.props.t("tutorial:paper.1")}
          ></TutorialSlide>
          <TutorialSlide
            text={this.props.t("tutorial:paper.2")}
          ></TutorialSlide>
          <TutorialSlide
            text={this.props.t("tutorial:paper.3")}
          ></TutorialSlide>
          <TutorialSlide
            text={this.props.t("tutorial:paper.4")}
          ></TutorialSlide>
        </TutorialContentContainer>
      );
    }

    if (this.props.licenseType === LicenseType.Gray) {
      // Text _erstmal_ wie bei paper
      return (
        <TutorialContentContainer>
          <TutorialSlide
            text={this.props.t("tutorial:paper.1")}
          ></TutorialSlide>
          <TutorialSlide
            text={this.props.t("tutorial:paper.2")}
          ></TutorialSlide>
          <TutorialSlide
            text={this.props.t("tutorial:paper.3")}
          ></TutorialSlide>
          <TutorialSlide
            text={this.props.t("tutorial:paper.4")}
          ></TutorialSlide>
        </TutorialContentContainer>
      );
    }

    if (this.props.licenseType === LicenseType.Plastic) {
      return (
        <TutorialContentContainer>
          <TutorialSlide
            text={this.props.t("tutorial:plastic.1")}
          ></TutorialSlide>
          <TutorialSlide
            text={this.props.t("tutorial:plastic.2")}
          ></TutorialSlide>
          <TutorialSlide
            text={this.props.t("tutorial:plastic.3")}
          ></TutorialSlide>
          <TutorialSlide
            text={this.props.t("tutorial:plastic.4")}
          ></TutorialSlide>
        </TutorialContentContainer>
      );
    }

    return <Redirect to="/error" />;
  }
}

export default compose<any>(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(TutorialContent);
