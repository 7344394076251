import { combineReducers } from "redux";
import { userReducer } from "./modules/user";
import { appReducer } from "./modules/app";
import { selectReducer } from "./modules/select";
import { tutorialReducer } from "./modules/tutorial";
import { swipeReducer } from "./modules/swipe";
import { errorReducer } from "./modules/error";
import { interactiveHelpReducer } from "./modules/interactiveHelp";
import { cookieReducer } from "./modules/cookie";

export const rootReducer = combineReducers({
  user: userReducer,
  app: appReducer,
  select: selectReducer,
  tutorial: tutorialReducer,
  swipe: swipeReducer,
  error: errorReducer,
  interactiveHelp: interactiveHelpReducer,
  cookie: cookieReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
