import classNames from "classnames";
import React, { CSSProperties } from "react";
import { connect } from "react-redux";
import { RootState } from "../../../redux";
import HookMessage from "./HookMessage";

// types
const mapStateToProps = (state: RootState) => ({
  count: state.swipe.count,
});

const mapDispatchToProps = {};

type ActiveSwipeCardCustomProps = {
  style?: CSSProperties;
  className?: string;
};
type ActiveSwipeCardProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  ActiveSwipeCardCustomProps;

// component
class ActiveSwipeCard extends React.Component<ActiveSwipeCardProps> {
  render() {
    return (
      <div
        className={classNames(
          "absolute flex flex-col w-screen h-dvh bg-white",
          this.props.className
        )}
        style={this.props.style}
      >
        <HookMessage key={this.props.count} />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ActiveSwipeCard);
