import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { GA, GAEventType } from "../../../helpers/GA/ga";
import { RootState } from "../../../redux";
import Button from "../../common/ui/Button";
import camera from "../img/icon_photoupload_color.svg";

type Props = {
  nextSlide: any;
};

function GreetingsSlide(props: Props) {
  const { t } = useTranslation("upload");
  const greeting = useSelector((state: RootState) => state.user.greeting);
  const isRollout = useSelector((state: RootState) => state.app.isRollout);

  const handleOnClick = () => {
    props.nextSlide();
    if (isRollout) {
      new GA().trackEvent(window, GAEventType.Upload_ClickToStartRollout);
    } else {
      new GA().trackEvent(window, GAEventType.Upload_ClickToStartUpload);
    }
  };

  return (
    <div className="flex flex-col justify-between h-full">
      <div className="px-6 pt-4 text-center whitespace-pre-wrap">
        <h1 className="text-lg font-bold">
          {t("welcome.title")}
          <br />
          {greeting}
        </h1>
      </div>
      <div className="px-8 text-center">
        <img src={camera} className="h-24 mx-auto" alt="camera" />
        <p className="mt-6 font-semibold">
          {isRollout ? t("rollout.p1") : t("welcome.p1")}
        </p>
      </div>
      <div className="grid grid-cols-1 gap-4 px-6 pb-4">
        <Button
          title={isRollout ? t("rollout.button") : t("welcome.button")}
          onClick={handleOnClick}
        />
      </div>
    </div>
  );
}

export default GreetingsSlide;
