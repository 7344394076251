import React from "react";
import { Redirect, RouteComponentProps, withRouter } from "react-router-dom";
import { RootState } from "../../../redux";
import { connect } from "react-redux";
import axios from "axios";
import anime from "animejs";
import {
  setNetworkStatus,
  setProgress,
  setShowDot,
  setShowRedAlert,
  setShowGreenAlert,
  setCount,
  addSwipeResult,
  setSwipeResults,
  setTransactionData,
  setTransactionDataSignature,
  SwipeResult,
  setShowSuccessAfterInteractiveHelp,
} from "../../../redux/modules/swipe";
import { setShowWarningBackground, setShowInteractiveHelp, setHelpScreen } from "../../../redux/modules/interactiveHelp";
import { setPageProgress } from "../../../redux/modules/app";
import { setCanRetry } from "../../../redux/modules/user";
import { LicenseType } from "../../../enums";
import ScaledImage from "../../common/ScaledImage";
import { withTranslation, WithTranslation } from "react-i18next";
import {
  ConnectivityResponse,
  ConnectivityStatus,
  DecoderResponseError,
  DecoderResponseSuccess,
  DevicePositionResponse,
  DevicePositionStatus,
  PrismaSDK,
  Subscription,
} from "@prismadelabs/prismaid";
import { Transition } from "@headlessui/react";
import { compose } from "redux";
import SDKSingleton from "../../../SDK";
import ScaledImageUnpositioned from "../../common/ScaledImageUnpositioned";
import DeviceOnTableWarning from "./DeviceOnTableWarning";
import { GA, GAEventType } from "../../../helpers/GA/ga";
import Sound from "../../common/Sound";

import paperFrame from "../../../assets/swipe/swipeback_paper@2x.png";
import paperFull from "../../../assets/swipe/paper_full@2x.png";

import grayFrame from "../../../assets/swipe/swipeback_gray@2x.png";
import grayFull from "../../../assets/swipe/gray_full@2x.png";

import plasticFrame from "../../../assets/swipe/swipeback_plastic@2x.png";

import paperEdge from "../../../assets/swipe/paper-edge@2x.png";
import dot from "../../../assets/swipe/dot@2x.png";

import plasticHologram from "../../../assets/swipe/dl-hologram@2x.png";
import pfeilTop from "../../../assets/swipe/pfeil@2x.png";
import pfeilBottom from "../../../assets/swipe/pfeil@2x.png";
import pfeilLinie from "../../../assets/swipe/pfeil-linie_lang@2x.png";
import swiper from "../../../assets/swipe/swipe-escalator@2x.png";
import pfeilMarker from "../../../assets/swipe/swipe-escalator-erweiterung@2x.png";

import touchPrint from "../../../assets/swipe/touch_fingerabdruck@2x.png";

import crystalAlert from "../../../assets/sounds/crystal_alert.mp3";
import helpAlert from "../../../assets/sounds/ding.mp3";

// types
const mapStateToProps = (state: RootState) => ({
  pageProgress: state.app.pageProgress,
  licenseType: state.user.licenseType,
  showWarningBackground: state.interactiveHelp.showWarningBackground,
  mode: state.user.mode,
  callbackUrl: state.user.callbackUrl,
  cancelUrl: state.user.cancelUrl,
  driverId: state.user.driverId,
  count: state.swipe.count,
  showDot: state.swipe.showDot,
  showRedAlert: state.swipe.showRedAlert,
  showGreenAlert: state.swipe.showGreenAlert,
  swipeResults: state.swipe.swipeResults,
  transactionData: state.swipe.transactionData,
  transacionDataSignature: state.swipe.transactionDataSignature,
  scaleFactor: state.swipe.scaleFactor,
  showInteractiveHelp: state.interactiveHelp.showInteractiveHelp,
});

const mapDispatchToProps = {
  setNetworkStatus,
  setProgress,
  setShowDot,
  setShowRedAlert,
  setShowGreenAlert,
  setCount,
  setTransactionData,
  setTransactionDataSignature,
  addSwipeResult,
  setSwipeResults,
  setCanRetry,
  setShowWarningBackground,
  setShowInteractiveHelp,
  setHelpScreen,
  setPageProgress,
  setShowSuccessAfterInteractiveHelp,
};

type SwipeFieldProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps & WithTranslation & RouteComponentProps;

type SwipeFieldStates = {
  redirect: any;
  isRedirecting: boolean;
  showDeviceOnTableWarning: boolean;
  hasBeenShownDeviceOnTableWarning: boolean;
};

// component
class SwipeField extends React.Component<SwipeFieldProps, SwipeFieldStates> {
  sdk: PrismaSDK = SDKSingleton.getInstance().sdk;
  private successSound: Sound;
  private helpSound: Sound;

  private progressSubject: Subscription | undefined;
  private connectivitySubject: Subscription | undefined;
  private detectionSuccessSubject: Subscription | undefined;
  private detectionErrorSubject: Subscription | undefined;
  private interactionSubject: Subscription | undefined;
  private interactiveHelperFlashSubject: Subscription | undefined;
  private interactiveHelperScreenSubject: Subscription | undefined;

  constructor(props: SwipeFieldProps) {
    super(props);

    this.state = {
      redirect: null,
      isRedirecting: false,
      showDeviceOnTableWarning: false,
      hasBeenShownDeviceOnTableWarning: false,
    };

    this.successSound = new Sound(crystalAlert);
    this.helpSound = new Sound(helpAlert);

    this.props.setNetworkStatus(ConnectivityStatus.ok);

    this.sdk = this.prepareSDK();
  }

  componentDidMount() {
    this.initialisePrismaSDK();
  }

  componentWillUnmount() {
    if (this.progressSubject !== undefined) {
      this.progressSubject.unsubscribe();
    }
    if (this.connectivitySubject !== undefined) {
      this.connectivitySubject.unsubscribe();
    }
    if (this.detectionSuccessSubject !== undefined) {
      this.detectionSuccessSubject.unsubscribe();
    }
    if (this.detectionErrorSubject !== undefined) {
      this.detectionErrorSubject.unsubscribe();
    }
    if (this.interactionSubject !== undefined) {
      this.interactionSubject.unsubscribe();
    }
    if (this.interactiveHelperFlashSubject !== undefined) {
      this.interactiveHelperFlashSubject.unsubscribe();
    }
    if (this.interactiveHelperScreenSubject !== undefined) {
      this.interactiveHelperScreenSubject.unsubscribe();
    }

    // fix warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  prepareSDK() {
    this.sdk.setScreenResolutionScaleFactor(this.props.scaleFactor);
    this.sdk.setHoldingThumbArea(241, 259, -80, 400); // unscaled pixel values of actualImageHeight, actualImageWidth, marginLeft, marginBottom

    this.progressSubject = this.sdk.getProgressSubject().subscribe((response) => {
      console.log("*) progress:", response.progress);
      this.props.setProgress(response.progress);
    });

    this.connectivitySubject = this.sdk.getConnectivitySubject().subscribe((response: ConnectivityResponse) => {
      console.log("*) connectivity response:", response.status);

      if (response.status === null) return;

      this.props.setNetworkStatus(response.status);

      switch (response.status) {
        case ConnectivityStatus.slow:
          new GA().trackEvent(window, GAEventType.Error_Network_Slow);
          break;

        case ConnectivityStatus.offline:
          new GA().trackEvent(window, GAEventType.Error_Network_Offline);
          break;
      }
    });

    return this.sdk;
  }

  initialisePrismaSDK = () => {
    this.detectionSuccessSubject = this.sdk.getDetectionSuccessSubject().subscribe((response) => {
      console.log("*) nextScreen:", response.rawData);
      new GA().trackEvent(window, GAEventType.Swipe_IncomingResponse_Success, response.rawData);

      this.props.setCount(this.props.count + 1);

      this.processResponse(response);
      this.flashGreenAlert();
    });

    this.detectionErrorSubject = this.sdk.getDetectionErrorSubject().subscribe((response) => {
      console.log("*) detection error:", response.description());
      new GA().trackEvent(window, GAEventType.Swipe_IncomingResponse_Failure, response.errorCode);

      response.hints.forEach((hint) => {
        console.log("*) hint:", hint.description());
      });

      this.processResponse(response);
      this.flashRedAlert();
    });

    this.interactionSubject = this.sdk.getInteractionSubject().subscribe((response) => {
      console.log("*) interaction event:", response.event, response.activeSignals);

      switch (response.event) {
        case "started":
          this.props.setShowDot(true);
          if (this.props.count === 0) {
            this.updateMessages({
              title: this.props.t("swipe:swipe.title"),
              message: this.props.t("swipe:swipe.body"),
            });
          }
          break;
        // FIXME: remove?
        case "changed":
          if (this.props.showWarningBackground) {
            this.props.setShowWarningBackground(false);
            this.updateMessages({
              title: this.props.t("swipe:swipe.title"),
              message: this.props.t("swipe:swipe.body"),
            });
          }
          break;
        case "complete":
          this.props.setShowDot(false);

          if (this.props.showWarningBackground) {
            this.props.setShowWarningBackground(false);
            this.props.setShowInteractiveHelp(true);

            this.updateMessages({
              title: this.props.t("swipe:swipe.title"),
              message: this.props.t("swipe:swipe.body"),
            });
          }
          break;

        default:
          break;
      }
    });

    this.interactiveHelperFlashSubject = this.sdk.getInteractiveHelperFlashSubject().subscribe((response) => {
      console.log("%c*) interactiveHelperFlash event:", "color: white; background-color: red;", response);
      new GA().trackInteractiveHelpFlash(window);

      if (!this.props.showWarningBackground) {
        this.props.setShowWarningBackground(true);
        this.helpSound.play();
        this.updateMessages({
          title: this.props.t("interactiveHelp:takeCard.title"),
          message: this.props.t("interactiveHelp:takeCard.body"),
        });
      }
    });

    this.interactiveHelperScreenSubject = this.sdk.getInteractiveHelperScreenSubject().subscribe((response) => {
      console.log("%c*) interactiveHelperScreen event:", "color: white; background-color: red;", response.nextScreen);
      setTimeout(() => {
        new GA().trackInteractiveHelpScreen(window, response.nextScreen.screenId, response.nextScreen.internalData);

        if (response.nextScreen.screenId === "failed") {
          console.warn("nextScreen: failure");
          // TODO replaxe axios with fetch
          // TODO then remove the axios package
          axios.post(this.props.cancelUrl).catch((err) => console.log(err));
          // fetch(this.props.cancelUrl, { method: "POST" }).catch((err) => console.log(err));

          this.props.setPageProgress(100);
          this.setState({
            redirect: "/failure",
          });
          this.props.setShowWarningBackground(false);
          return;
        }

        if (response.nextScreen.screenId === "other_device") {
          setCanRetry(true);

          this.props.setPageProgress(100);
          this.setState({
            redirect: "/failure",
          });
          this.props.setShowWarningBackground(false);
          return;
        }

        this.props.setShowWarningBackground(false);
        this.props.setHelpScreen(response.nextScreen);
        this.props.setShowInteractiveHelp(true);
      }, 50);
    });

    this.sdk.getDevicePositionSubject().subscribe((response: DevicePositionResponse) => {
      console.log("*) DevicePosition event:", response.status);

      if (response.status === DevicePositionStatus.ontable) {
        this.setState({ showDeviceOnTableWarning: true });
      } else {
        this.setState({ showDeviceOnTableWarning: false });
      }
    });

    const screen = document.querySelector("#swipeScreen");
    if (screen) {
      this.sdk.attachToElement(screen);
    }
    this.setCustomPayload();

    setTimeout(() => {
      this.startDemoAnimation();
    }, 1000);
  };

  unsubscribeFromIH = () => {
    this.interactiveHelperFlashSubject?.unsubscribe();
    this.interactiveHelperScreenSubject?.unsubscribe();
  };

  processResponse = (response: DecoderResponseError | DecoderResponseSuccess) => {
    // bail early if page is already redirecting
    if (this.state.isRedirecting) return;

    if (this.props.showWarningBackground) return;

    switch (response.rawData.nextScreen) {
      case "success":
        this.setState({
          isRedirecting: true,
        });

        this.unsubscribeFromIH();

        new GA().trackEvent(window, GAEventType.Swipe_Success);

        this.props.setTransactionData(undefined);
        this.props.setTransactionDataSignature(undefined);

        this.successSound.play();

        this.props.setPageProgress(100);

        if (this.props.showWarningBackground || this.props.showInteractiveHelp) {
          this.props.setShowSuccessAfterInteractiveHelp(true);
        }

        this.props.history.push("/success");
        break;

      case "help":
      case "swipe":
        this.props.setTransactionData(response.rawData.transactionData);
        this.props.setTransactionDataSignature(response.rawData.transactionDataSignature);
        this.setCustomPayload();

        this.props.setCount(this.props.count + 1);
        this.props.setPageProgress(this.props.pageProgress + 5);

        // error
        if (response instanceof DecoderResponseError) {
          let hintCode = "";
          // filter for handled error codes
          let codes = response.hints.filter((hint) => {
            return (
              hint.code === "card_unstable" ||
              hint.code === "invalid_signal" ||
              hint.code === "swipe_faster" ||
              hint.code === "swipe_slower" ||
              hint.code === "swipe_without_card"
            );
          });

          if (codes.length > 0) {
            hintCode = codes[0].code;
          }

          switch (hintCode) {
            case "card_unstable":
            case "invalid_signal":
            case "swipe_faster":
            case "swipe_slower":
            case "swipe_without_card":
              this.updateMessages({
                title: this.props.t("swipe:retry.title"),
                message: this.props.t("swipe:" + hintCode + ".body"),
              });
              break;
            default:
              // unknown code or ""
              this.updateMessages({
                title: this.props.t("swipe:retry.title"),
                message: this.props.t("swipe:retry.body"),
              });
              break;
          }
          new GA().trackEvent(window, GAEventType.Swipe_Error, hintCode);
        } else {
          // success
          new GA().trackEvent(window, GAEventType.Swipe_Success_Again);

          this.props.setPageProgress(this.props.pageProgress + 5);
          if (this.props.mode === "init") {
            this.updateMessages({
              title: this.props.t("swipe:retry.init.title"),
              message: this.props.t("swipe:retry.init.body"),
            });
          } else {
            this.updateMessages({
              title: this.props.t("swipe:retry.title"),
              message: this.props.t("swipe:retry.body"),
            });
          }
        }
        break;

      case "failure_hard":
        // possible fraud
        this.unsubscribeFromIH();

        new GA().trackEvent(window, GAEventType.Swipe_Error_HardFailure);

        this.props.setTransactionData(undefined);
        this.props.setTransactionDataSignature(undefined);

        this.updateMessages({
          title: this.props.t("swipe:failure.title"),
          message: this.props.t("swipe:failure.body"),
        });

        this.props.setCanRetry(false);

        this.props.setPageProgress(100);
        this.props.history.push("/failure");
        break;

      default:
        // nextScreen: "failure"
        this.unsubscribeFromIH();

        new GA().trackEvent(window, GAEventType.Swipe_Error_Failure);

        this.props.setTransactionData(undefined);
        this.props.setTransactionDataSignature(undefined);

        this.updateMessages({
          title: this.props.t("swipe:failure.title"),
          message: this.props.t("swipe:failure.body"),
        });

        this.props.setPageProgress(100);
        this.props.history.push("/failure");
        break;
    }
  };

  setCustomPayload = () => {
    this.sdk.setCustomPayload({
      transactionData: this.props.transactionData,
      transactionDataSignature: this.props.transacionDataSignature,
      signature: "123:7dktz4hxqgx39gsh634gt82n78dfgf8",
      mode: this.props.mode,
      callbackUrl: this.props.callbackUrl,
      licenseType: this.props.licenseType,
      driverId: this.props.driverId,
    });
  };

  updateMessages = (result: SwipeResult) => {
    let tmp = this.props.swipeResults;
    let count = tmp.push(result);

    if (count > 0) {
      tmp[count - 1].shouldRender = true;
    }
    // hide secondToLast message
    if (count > 1) {
      tmp[count - 2].shouldRender = false;
    }
    this.props.setSwipeResults(tmp);
  };

  flashRedAlert = () => {
    this.props.setShowRedAlert(true);

    setTimeout(() => {
      this.props.setShowRedAlert(false);
    }, 155);
  };

  flashGreenAlert = () => {
    this.props.setShowGreenAlert(true);

    setTimeout(() => {
      this.props.setShowGreenAlert(false);
    }, 155);
  };

  startDemoAnimation = () => {
    anime({
      targets: "#hologram",
      opacity: 1.0,
      duration: 500,
      easing: "linear",
    });
    anime({
      targets: "#hologram",
      scale: 1.8,
      duration: 1000,
      direction: "alternate",
      easing: "easeInOutQuad",
    });
    anime({
      targets: "#edge",
      opacity: 1.0,
      duration: 500,
      easing: "linear",
    });
    anime({
      targets: "#edge",
      scale: 1.2,
      duration: 1000,
      direction: "alternate",
      easing: "easeInOutQuad",
    });
    setTimeout(() => {
      anime({
        targets: "#edge",
        opacity: 0.5,
        duration: 1000,
        easing: "linear",
        complete: function () {
          anime({
            targets: "#edge",
            opacity: 1.0,
            duration: 1000,
            loop: true,
            direction: "alternate",
            easing: "linear",
          });
        },
      });
    }, 2000);

    setTimeout(() => {
      anime({
        targets: "#touchPrint",
        opacity: 0.6,
        duration: 1000,
        easing: "linear",
        complete: function () {
          anime({
            targets: "#touchPrint",
            opacity: 0.4,
            duration: 1000,
            loop: true,
            direction: "alternate",
            easing: "linear",
          });
        },
      });
    }, 1000);

    setTimeout(() => {
      anime({
        targets: ["#pfeilBottom", "#pfeilTop", "#pfeilLinie"],
        opacity: 1.0,
        duration: 1000,
        easing: "linear",
      });
    }, 3000);

    setTimeout(() => {
      anime({
        targets: "#pfeilLinie",
        translateY: -(780 * this.props.scaleFactor),
        duration: 5000,
        loop: true,
        easing: "linear",
      });
    }, 4000);

    // reset and repeat
    setTimeout(() => {
      anime({
        targets: "#swiper",
        keyframes: [
          // 1600 ~ Kartenhöhe + zusätzlicher Abstand
          { translateY: 0 - 1600 * this.props.scaleFactor, duration: 2000 },
          { opacity: 0, duration: 500 },
          { translateY: 0, duration: 10 },
          { opacity: 1, duration: 10 },
          { duration: 1000 },
        ],
        loop: true,
        easing: "linear",
      });
    }, 6000);
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }

    let swipeField;

    if (this.props.licenseType === LicenseType.Gray) {
      swipeField = (
        <>
          <ScaledImage
            src={grayFull}
            id="gray"
            alt="gray"
            horizontalAlign="left"
            verticalAlign="bottom"
            verticalOffset={1598}
            className="mb-2 ml-2 opacity-25"
          />

          <ScaledImage
            src={grayFrame}
            id="frame"
            alt="grayFrame"
            horizontalAlign="left"
            verticalAlign="bottom"
            className="mb-2 ml-2"
          />

          <ScaledImage
            src={paperEdge}
            id="edge"
            alt="grayEdge"
            horizontalAlign="left"
            verticalAlign="bottom"
            className="mb-2 ml-2"
            verticalOffset={-900}
            opacity={0}
          />
        </>
      );
    } else if (this.props.licenseType === LicenseType.Paper) {
      swipeField = (
        <>
          <ScaledImage
            src={paperFull}
            id="paper"
            alt="paper"
            horizontalAlign="left"
            verticalAlign="bottom"
            verticalOffset={262}
            className="mb-2 ml-2 opacity-25"
          />

          <ScaledImage
            src={paperFrame}
            id="frame"
            alt="paperFrame"
            horizontalAlign="left"
            verticalAlign="bottom"
            className="mb-2 ml-2"
          />

          <ScaledImage
            src={paperEdge}
            id="edge"
            alt="paperEdge"
            horizontalAlign="left"
            verticalAlign="bottom"
            className="mb-2 ml-2"
            verticalOffset={-900}
            opacity={0}
          />
        </>
      );
    } else {
      swipeField = (
        <>
          <ScaledImage
            src={plasticFrame}
            id="frame"
            alt="plasticFrame"
            horizontalAlign="left"
            verticalAlign="bottom"
            className="mb-2 ml-2"
          />
          <ScaledImage
            src={plasticHologram}
            id="hologram"
            alt=""
            horizontalAlign="left"
            verticalAlign="bottom"
            horizontalOffset={430}
            verticalOffset={-78}
            opacity={0}
          />
        </>
      );
    }

    return (
      <div className="absolute left-0 w-screen h-dvh bottom-4">
        {/* <div className="absolute top-0 left-0 w-screen h-dvh"> */}
        {swipeField}

        <ScaledImage
          src={touchPrint}
          id="touchPrint"
          alt=""
          horizontalAlign="left"
          verticalAlign="bottom"
          horizontalOffset={-80}
          verticalOffset={-400}
          opacity={0}
        />

        <Transition
          show={this.props.showDot}
          enter="transition-opacity duration-100"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-150"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <ScaledImage
            src={pfeilMarker}
            id="markerTop"
            alt=""
            horizontalAlign="left"
            verticalAlign="bottom"
            horizontalOffset={426}
            verticalOffset={-1114}
          />

          <ScaledImage
            src={pfeilMarker}
            id="markerBottom"
            alt=""
            horizontalAlign="left"
            verticalAlign="bottom"
            horizontalOffset={426}
            verticalOffset={4}
          />

          <ScaledImage
            src={dot}
            id="dot"
            alt=""
            horizontalAlign="left"
            verticalAlign="bottom"
            horizontalOffset={420}
            verticalOffset={-1150}
          />
        </Transition>

        <ScaledImage
          src={pfeilTop}
          id="pfeilTop"
          alt=""
          horizontalAlign="left"
          verticalAlign="bottom"
          horizontalOffset={426}
          verticalOffset={-960}
          opacity={0}
        />

        <div
          className="absolute overflow-hidden"
          style={{
            width: this.props.scaleFactor * 99 + "px",
            height: this.props.scaleFactor * 770 + "px",
            left: this.props.scaleFactor * 426 + "px",
            bottom: this.props.scaleFactor * 180 + "px",
          }}
        >
          <ScaledImageUnpositioned
            src={pfeilLinie}
            id="pfeilLinie"
            alt=""
            className="top-0 "
            opacity={0}
          />
        </div>

        <ScaledImage
          src={pfeilBottom}
          id="pfeilBottom"
          alt=""
          horizontalAlign="left"
          verticalAlign="bottom"
          horizontalOffset={426}
          verticalOffset={-120}
          opacity={0}
        />

        <ScaledImage
          src={swiper}
          id="swiper"
          alt=""
          horizontalAlign="left"
          verticalAlign="bottom"
          horizontalOffset={395}
          verticalOffset={830}
        />

        <Transition
          show={this.props.showRedAlert}
          enter="transition-opacity duration-150"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            id="redAlert"
            className="absolute top-0 h-dvh bg-opacity-75 bg-status-red"
          />
        </Transition>

        <Transition
          show={this.props.showGreenAlert}
          enter="transition-opacity duration-150"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            id="greenAlert"
            className="absolute top-0 h-dvh bg-opacity-75 bg-status-green"
          />
        </Transition>

        <Transition
          show={this.state.showDeviceOnTableWarning && !this.state.hasBeenShownDeviceOnTableWarning}
          enter="transition-opacity duration-150"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          afterLeave={() => {
            this.setState({ hasBeenShownDeviceOnTableWarning: true });
          }}
        >
          <DeviceOnTableWarning />
        </Transition>
      </div>
    );
  }
}

export default withRouter(compose<any>(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(SwipeField));
