import React from "react";
import { Redirect, RouteComponentProps, withRouter } from "react-router-dom";
import { RootState } from "../redux";
import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation, WithTranslation } from "react-i18next";
import Button from "../components/common/ui/Button";
import SimpleToggle from "../components/touch/TouchToggle";
import CenteredLayout from "../components/common/ui/CenteredLayout";
import Card from "../components/common/ui/Card";
import { setErrorCode } from "../redux/modules/error";
import { ErrorCode } from "../enums";
import { GA, GAEventType } from "../helpers/GA/ga";
import StartDateChecker from "../components/common/StartDateChecker";
import WelcomeBackground from "./WelcomeBackground";
import { setPageProgress } from "../redux/modules/app";

// types
const mapStateToProps = (state: RootState) => ({
  touchSensitivityIsSet: state.app.touchSensitivityIsSet,
  touchSensitivityType: state.app.touchSensitivityType,
  invocationIsValid: state.app.invocationIsValid,
});

const mapDispatchToProps = {
  setErrorCode,
  setPageProgress,
};

type TouchProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  WithTranslation &
  RouteComponentProps;

type TouchStates = {
  redirect: any;
  type: string;
};

// component
class Touch extends React.Component<TouchProps, TouchStates> {
  constructor(props: TouchProps) {
    super(props);

    this.state = {
      redirect: null,
      type: "_touchSensitivity",
    };
  }

  componentDidMount() {
    if (!this.props.invocationIsValid) {
      new GA().trackEvent(window, GAEventType.Error_InvalidInvocation);
      this.setErrorCodeAndRedirect(ErrorCode.InvalidInvocation);
      return;
    }

    switch (this.props.touchSensitivityType) {
      case "glovemode":
        this.setState({ type: "glovemode" });
        break;
      case "pointerspeed":
        this.setState({ type: "pointerspeed" });
        break;
      default:
        this.setState({ type: "touchSensitivity" });
        break;
    }

    new GA().trackPageView(window, "/touch");
  }

  setErrorCodeAndRedirect = (errorCode: ErrorCode) => {
    this.props.setErrorCode(errorCode);
    this.setState({
      redirect: "/error",
    });
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }

    // wait for touchSensitivityType
    if (
      typeof this.props.touchSensitivityType === "undefined" ||
      this.props.touchSensitivityType === null
    ) {
      return null;
    }

    return (
      <StartDateChecker>
        <div className="absolute top-0 w-screen h-dvh overflow-hidden">
          <WelcomeBackground />
        </div>
        <div className="absolute top-0 w-screen h-dvh overflow-hidden">
          <CenteredLayout className="bg-gray-200 bg-opacity-50 backdrop-blur">
            <Card noPadding={true}>
              <h1
                dangerouslySetInnerHTML={{
                  __html: this.props.t("touch:title_" + this.state.type),
                }}
                className="px-2 py-4"
              />
              <div className="w-full px-4 pt-4 pb-5 font-semibold text-left bg-gradient-to-b from-blue-100 to-white">
                <div className="flow-root">
                  <ul className="-mb-8">
                    <li>
                      <div className="relative pb-8">
                        <span
                          className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-edding-blue"
                          aria-hidden="true"
                        ></span>
                        <div className="relative flex space-x-3">
                          <div className="flex items-center justify-center w-8 h-8 font-semibold text-white rounded-full bg-edding-blue">
                            1
                          </div>
                          <div className="flex-1 pt-1 font-semibold">
                            {this.props.t("touch:p1_" + this.state.type)}
                          </div>
                        </div>
                      </div>
                    </li>

                    <li>
                      <div className="relative pb-8">
                        <span
                          className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-edding-blue"
                          aria-hidden="true"
                        ></span>
                        <div className="relative flex space-x-3">
                          <div className="flex items-center justify-center w-8 h-8 font-semibold text-white rounded-full bg-edding-blue">
                            2
                          </div>
                          <div className="flex-1 pt-1 font-semibold">
                            {this.props.t("touch:p2_" + this.state.type)}
                          </div>
                        </div>
                      </div>
                    </li>

                    <li>
                      <div className="relative pb-8">
                        <div className="relative flex space-x-3">
                          <div>
                            <span className="flex items-center justify-center w-8 h-8 font-semibold text-white rounded-full bg-edding-blue">
                              3
                            </span>
                          </div>
                          <div className="flex-1 pt-1 font-semibold">
                            {this.props.t("touch:p3_" + this.state.type)}
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>

                <div className="grid grid-cols-2 gap-4 mt-8">
                  <div className="flex flex-col items-center justify-around">
                    <p className="text-edding-blue">
                      {this.props.t("touch:label")}
                    </p>
                    <SimpleToggle />
                  </div>
                  <Button
                    onClick={() => {
                      new GA().trackEvent(
                        window,
                        GAEventType.Touch_ClickToConfirm
                      );

                      this.props.setPageProgress(10);
                      this.props.history.push("/tutorial");
                    }}
                    title={this.props.t("touch:button")}
                    disabled={!this.props.touchSensitivityIsSet}
                    className="w-full"
                  />
                </div>
              </div>
            </Card>
          </CenteredLayout>
        </div>
      </StartDateChecker>
    );
  }
}

export default withRouter(
  compose<any>(
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps)
  )(Touch)
);
