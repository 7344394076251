import classNames from "classnames";
import React, { ReactNode } from "react";

interface Props {
  children: ReactNode;
  className?: string;
}

const ScrollableHeightNoHeader: React.FC<Props> = (props) => {
  return (
    <div className={classNames("w-screen min-h-dvh flex flex-col bg-white")}>
      {props.children}
    </div>
  );
};

export default ScrollableHeightNoHeader;
