import React, { ReactNode } from "react";
import { RootState } from "../../redux";
import { connect } from "react-redux";
import { setCurrentSlide } from "../../redux/modules/tutorial";
import SwipeableViews from "react-swipeable-views";
import anime from "animejs";
import { GA, GAEventType } from "../../helpers/GA/ga";
import { setPageProgress } from "../../redux/modules/app";

// types
const mapStateToProps = (state: RootState) => ({
  currentSlide: state.tutorial.currentSlide,
});

const mapDispatchToProps = {
  setCurrentSlide,
  setPageProgress,
};

type TutorialContentSpecificProps = {
  children: ReactNode;
};

type TutorialContentContainerProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  TutorialContentSpecificProps;

type TutorialContentContainerStates = {};

// component
class TutorialContentContainer extends React.Component<
  TutorialContentContainerProps,
  TutorialContentContainerStates
> {
  // animates switching on swiping, NOT on clicking
  // buttonClickAnimation is implemented in Tutorial
  onChangeIndex = (index: number) => {
    new GA().trackEvent(
      window,
      GAEventType.Tutorial_SwipeToSelectSlide,
      "",
      index
    );

    anime({
      targets: ".tutorialImage",
      opacity: 0,
      duration: 200,
      complete: () => {
        this.props.setCurrentSlide(index);
        this.props.setPageProgress((index + 1) * 10);
        anime({ targets: ".tutorialImage", opacity: 1, duration: 500 });
      },
    });
  };

  render() {
    return (
      <div>
        <SwipeableViews
          enableMouseEvents
          onChangeIndex={this.onChangeIndex}
          className={"px-4 h-full"}
          index={this.props.currentSlide}
        >
          {this.props.children}
        </SwipeableViews>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TutorialContentContainer);
