// TODO The tutorial internally currently uses react-swipeable-views, which officially only supports "react": "^15.3.0 || ^16.0.0 || ^17.0.0"
// TODO To enable React 18 support, we set a override in the package.json
// TODO replace react-swipeable-views and remove the override
// TODO possible replacement is https://www.embla-carousel.com

import React from "react";
import { Redirect, RouteComponentProps, withRouter } from "react-router-dom";
import { RootState } from "../redux";
import { connect } from "react-redux";
import { setCurrentSlide } from "../redux/modules/tutorial";
import SDKSingleton from "../SDK";
import { compose } from "redux";
import { withTranslation, WithTranslation } from "react-i18next";
import anime from "animejs";
import { setErrorCode } from "../redux/modules/error";
import { ErrorCode, LicenseType } from "../enums";
import { GA, GAEventType } from "../helpers/GA/ga";

import Header from "../components/common/Header";
import LockedHeightNoHeader from "../components/common/layouts/LockedHeightNoHeader";
import BackButton from "../components/common/ui/BackButton";
import TutorialContent from "../components/tutorial/TutorialContent";
import Button from "../components/common/ui/Button";
import TutorialImage from "../components/tutorial/TutorialImage";
import Pagination from "../components/common/ui/Pagination";

import Plastic_1 from "../assets/tutorials/plastic/01_welcome-plastic.gif";
import Plastic_2 from "../assets/tutorials/plastic/02_placement-plastic.gif";
import Plastic_3 from "../assets/tutorials/plastic/03_swipe-plastic.gif";
import Plastic_4 from "../assets/tutorials/plastic/04_verified-plastic.gif";
import Paper_1 from "../assets/tutorials/paper/01_welcome-paper.gif";
import Paper_2 from "../assets/tutorials/paper/02_placement-paper.gif";
import Paper_3 from "../assets/tutorials/paper/03_swipe-paper.gif";
import Paper_4 from "../assets/tutorials/paper/04_verified-paper.gif";
import Gray_1 from "../assets/tutorials/gray/01_welcome-gray.gif";
import Gray_2 from "../assets/tutorials/gray/02_placement-gray.gif";
import Gray_3 from "../assets/tutorials/gray/03_swipe-gray.gif";
import Gray_4 from "../assets/tutorials/gray/04_verified-gray.gif";
import StartDateChecker from "../components/common/StartDateChecker";
import { setSwipeResults } from "../redux/modules/swipe";
import { setPageProgress } from "../redux/modules/app";

// types
const mapStateToProps = (state: RootState) => ({
  licenseType: state.user.licenseType,
  licenseIsKnown: state.user.licenseIsKnown,
  needsTouchSensitivity: state.app.needsTouchSensitivity,
  currentSlide: state.tutorial.currentSlide,
  invocationIsValid: state.app.invocationIsValid,
});

const mapDispatchToProps = {
  setPageProgress,
  setCurrentSlide,
  setErrorCode,
  setSwipeResults,
};

type TutorialProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps & WithTranslation & RouteComponentProps;

type TutorialStates = {
  redirect: any;
  imagePaper1IsReady: boolean;
  imagePaper2IsReady: boolean;
  imagePaper3IsReady: boolean;
  imagePaper4IsReady: boolean;
  imagePlastic1IsReady: boolean;
  imagePlastic2IsReady: boolean;
  imagePlastic3IsReady: boolean;
  imagePlastic4IsReady: boolean;
  imageGray1IsReady: boolean;
  imageGray2IsReady: boolean;
  imageGray3IsReady: boolean;
  imageGray4IsReady: boolean;
};

// component
class Tutorial extends React.Component<TutorialProps, TutorialStates> {
  constructor(props: TutorialProps) {
    super(props);

    this.state = {
      redirect: null,
      imagePaper1IsReady: false,
      imagePaper2IsReady: false,
      imagePaper3IsReady: false,
      imagePaper4IsReady: false,
      imagePlastic1IsReady: false,
      imagePlastic2IsReady: false,
      imagePlastic3IsReady: false,
      imagePlastic4IsReady: false,
      imageGray1IsReady: false,
      imageGray2IsReady: false,
      imageGray3IsReady: false,
      imageGray4IsReady: false,
    };
  }

  componentDidMount() {
    if (!this.props.invocationIsValid) {
      new GA().trackEvent(window, GAEventType.Error_InvalidInvocation);
      this.setErrorCodeAndRedirect(ErrorCode.InvalidInvocation);
      return;
    }

    new GA().trackPageView(window, "/tutorial");

    // preload images
    var allImages = [];

    if (this.props.licenseType === LicenseType.Paper) {
      const img_paper_1 = new Image();
      img_paper_1.src = Paper_1; // by setting an src, you trigger browser download
      img_paper_1.onload = () => {
        // when it finishes loading, update the component state
        this.setState({ imagePaper1IsReady: true });
      };
      const img_paper_2 = new Image();
      img_paper_2.src = Paper_2;
      img_paper_2.onload = () => {
        this.setState({ imagePaper2IsReady: true });
      };
      const img_paper_3 = new Image();
      img_paper_3.src = Paper_3;
      img_paper_3.onload = () => {
        this.setState({ imagePaper3IsReady: true });
      };
      const img_paper_4 = new Image();
      img_paper_4.src = Paper_4;
      img_paper_4.onload = () => {
        this.setState({ imagePaper4IsReady: true });
      };

      allImages.push(img_paper_1);
      allImages.push(img_paper_2);
      allImages.push(img_paper_3);
      allImages.push(img_paper_4);
    }

    if (this.props.licenseType === LicenseType.Plastic) {
      const img_plastic_1 = new Image();
      img_plastic_1.src = Plastic_1; // by setting an src, you trigger browser download
      img_plastic_1.onload = () => {
        // when it finishes loading, update the component state
        this.setState({ imagePlastic1IsReady: true });
      };
      const img_plastic_2 = new Image();
      img_plastic_2.src = Plastic_2;
      img_plastic_2.onload = () => {
        this.setState({ imagePlastic2IsReady: true });
      };
      const img_plastic_3 = new Image();
      img_plastic_3.src = Plastic_3;
      img_plastic_3.onload = () => {
        this.setState({ imagePlastic3IsReady: true });
      };
      const img_plastic_4 = new Image();
      img_plastic_4.src = Plastic_4;
      img_plastic_4.onload = () => {
        this.setState({ imagePlastic4IsReady: true });
      };

      allImages.push(img_plastic_1);
      allImages.push(img_plastic_2);
      allImages.push(img_plastic_3);
      allImages.push(img_plastic_4);
    }

    if (this.props.licenseType === LicenseType.Gray) {
      const img_gray_1 = new Image();
      img_gray_1.src = Gray_1; // by setting an src, you trigger browser download
      img_gray_1.onload = () => {
        // when it finishes loading, update the component state
        this.setState({ imageGray1IsReady: true });
      };
      const img_gray_2 = new Image();
      img_gray_2.src = Gray_2;
      img_gray_2.onload = () => {
        this.setState({ imageGray2IsReady: true });
      };
      const img_gray_3 = new Image();
      img_gray_3.src = Gray_3;
      img_gray_3.onload = () => {
        this.setState({ imageGray3IsReady: true });
      };
      const img_gray_4 = new Image();
      img_gray_4.src = Gray_4;
      img_gray_4.onload = () => {
        this.setState({ imageGray4IsReady: true });
      };

      allImages.push(img_gray_1);
      allImages.push(img_gray_2);
      allImages.push(img_gray_3);
      allImages.push(img_gray_4);
    }

    setPageProgress(10);
  }

  componentDidUpdate() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  componentWillUnmount() {
    // FIXME: is not removing??
    document.removeEventListener("touchmove", function (e) {
      e.preventDefault();
    });

    // fix warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  startButtonIsClicked = () => {
    new GA().trackEvent(window, GAEventType.Tutorial_ClickToFinishTutorial);

    this.props.setSwipeResults([
      {
        title: this.props.t("swipe:place.title"),
        message: this.props.t("swipe:place.body"),
        shouldRender: true,
      },
    ]);

    if (typeof DeviceMotionEvent !== "undefined" && typeof (DeviceMotionEvent as any).requestPermission === "function") {
      (DeviceMotionEvent as any)
        .requestPermission()
        .then((response: string) => {
          if (response == "granted") {
            //window.addEventListener( "devicemotion", this.handleDeviceMotion)
          }
        })
        .catch(console.error);
    } else {
      console.log("DeviceMotionEvent is not defined");
    }
    if (typeof DeviceOrientationEvent !== "undefined" && typeof (DeviceOrientationEvent as any).requestPermission === "function") {
      (DeviceOrientationEvent as any)
        .requestPermission()
        .then((response: string) => {
          if (response === "granted") {
            //window.addEventListener('deviceorientation', () => {});
          }
        })
        .catch(console.error);
    } else {
      // handle regular non iOS 13+ devices
    }

    let sdk = SDKSingleton.getInstance().sdk;
    sdk.getInteractiveHelper().reset();

    this.props.history.push("/swipe");

    setTimeout(() => {
      this.props.setCurrentSlide(0);
    }, 1000);
  };

  changeSlide = () => {
    const nextSlide = this.props.currentSlide + 1;

    new GA().trackEvent(window, GAEventType.Tutorial_ClickToSelectNextSlide, "", nextSlide);

    // animates switching on buttonClick, NOT on swipe
    // swipeAnimation is implemented in TutorialContentContainer
    anime({
      targets: ".tutorialImage",
      opacity: 0,
      duration: 200,
      complete: () => {
        this.props.setCurrentSlide(nextSlide);
        this.props.setPageProgress((nextSlide + 1) * 10);
        anime({ targets: ".tutorialImage", opacity: 1, duration: 500 });
      },
    });
  };

  onChangeIndex = (index: number) => {
    this.props.setCurrentSlide(index);
    this.props.setPageProgress((index + 1) * 10);
  };

  setErrorCodeAndRedirect = (errorCode: ErrorCode) => {
    this.props.setErrorCode(errorCode);
    this.setState({
      redirect: "/error",
    });
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }

    let buttonTitle = "";
    let onClick: any;

    // FIXME: get slideCount programmatically
    if (this.props.currentSlide === 3) {
      buttonTitle = "tutorial:startApplication";
      onClick = this.startButtonIsClicked;
    } else {
      buttonTitle = "tutorial:nextSlide";
      onClick = this.changeSlide;
    }

    if (
      (this.props.licenseType === LicenseType.Paper &&
        this.state.imagePaper1IsReady &&
        this.state.imagePaper2IsReady &&
        this.state.imagePaper3IsReady &&
        this.state.imagePaper4IsReady) ||
      (this.props.licenseType === LicenseType.Plastic &&
        this.state.imagePlastic1IsReady &&
        this.state.imagePlastic2IsReady &&
        this.state.imagePlastic3IsReady &&
        this.state.imagePlastic4IsReady) ||
      (this.props.licenseType === LicenseType.Gray &&
        this.state.imageGray1IsReady &&
        this.state.imageGray2IsReady &&
        this.state.imageGray3IsReady &&
        this.state.imageGray4IsReady)
    ) {
      return (
        <div className="relative">
          <StartDateChecker>
            <LockedHeightNoHeader>
              <div className="absolute top-0 h-dvh overflow-hidden">
                <Header className="absolute top-0" />
                <TutorialImage />
              </div>

              <div className="absolute bottom-0 flex items-end w-full h-dvh">
                <div className="flex flex-col justify-end w-full h-full pb-4 mt-12">
                  <TutorialContent />
                  <div className="mb-2">
                    <Pagination
                      dots={4}
                      index={this.props.currentSlide}
                      onChangeIndex={this.onChangeIndex}
                      handleOnPaginationClick={() => {}}
                    />
                  </div>
                  <div className="px-6">
                    <Button
                      title={this.props.t(buttonTitle)}
                      onClick={onClick}
                      className="w-full"
                    />
                  </div>
                </div>
              </div>

              <div className="absolute top-0 left-0 mt-12">
                {this.props.currentSlide === 0 ? (
                  <BackButton
                    onClick={() => {
                      new GA().trackEvent(window, GAEventType.Tutorial_ClickToGoBackToStart);

                      this.props.setPageProgress(0);
                      if (this.props.licenseIsKnown === true) {
                        this.props.history.push("/welcome");
                      } else {
                        this.props.history.push("/select");
                      }
                    }}
                  />
                ) : (
                  <BackButton
                    onClick={() => {
                      new GA().trackEvent(window, GAEventType.Tutorial_ClickToSelectPreviousSlide, "", this.props.currentSlide - 1);

                      this.props.setCurrentSlide(this.props.currentSlide - 1);
                      this.props.setPageProgress(this.props.currentSlide * 10);
                    }}
                  />
                )}
              </div>
            </LockedHeightNoHeader>
          </StartDateChecker>
        </div>
      );
    } else {
      return (
        <StartDateChecker>
          <LockedHeightNoHeader>
            <Header className="absolute top-0" />
            <div className="flex items-center justify-center w-screen h-dvh text-edding-blue">
              <svg
                className="w-10 h-10 animate-spin"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            </div>
          </LockedHeightNoHeader>
        </StartDateChecker>
      );
    }
  }
}

export default withRouter(compose<any>(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(Tutorial));
