import React from "react";
import { RootState } from "../redux";
import { connect } from "react-redux";
import { withTranslation, WithTranslation } from "react-i18next";
import { compose } from "redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import Kachel1 from "../components/failure/rework/Kachel1";
import Kachel2 from "../components/failure/rework/Kachel2";
import QRPopup from "../components/failure/rework/QRPopup";
import Video1 from "../components/failure/rework/Video1";
import Video2 from "../components/failure/rework/Video2";
import Video3 from "../components/failure/rework/Video3";

// types
const mapStateToProps = (state: RootState) => ({
  deviceIsSupported: state.app.deviceIsSupported,
  canRetry: state.user.canRetry,
  uploadUrl: state.user.uploadUrl,
});

type PathParams = {
  version: "qrpopup" | "kachel1" | "kachel2" | "video1" | "video2" | "video3";
  page: "a" | "b" | "c" | "d" | "e";
};

type ReworkProps = ReturnType<typeof mapStateToProps> & WithTranslation & RouteComponentProps;

type ReworkStates = {};

// component
class Rework extends React.Component<ReworkProps, ReworkStates> {
  version: string;
  page: string;

  constructor(props: ReworkProps) {
    super(props);

    const { version, page } = this.props.match.params as PathParams;
    this.version = version;
    this.page = page;
  }

  componentDidMount() {}

  render() {
    switch (this.page) {
      case "a":
        return (
          <CustomTag
            sorry
            excuse2
            suggestion2
            version={this.version}
          />
        );
      case "b":
        return (
          <CustomTag
            excuse3
            availableOptions
            uploadUrl="#"
            // uploadUrl={this.props.uploadUrl}
            manualControl
            calendly="#"
            // calendly
            version={this.version}
          />
        );
      case "c":
        return (
          <CustomTag
            sorry
            excuse2
            qrCode
            otherDevice
            availableOptions
            uploadUrl="#"
            // uploadUrl={this.props.uploadUrl}
            manualControl
            // no calendly
            calendly="#"
            version={this.version}
          />
        );
      case "d":
        return (
          <CustomTag
            excuse3
            availableOptions
            uploadUrl="#"
            // uploadUrl={this.props.uploadUrl}
            manualControl
            calendly="#"
            // calendly
            version={this.version}
          />
        );
      case "e":
        return (
          <CustomTag
            incompatibleDevice
            excuse3
            qrCode
            otherDevice
            availableOptions
            uploadUrl="#"
            // uploadUrl={this.props.uploadUrl}
            manualControl
            version={this.version}
          />
        );
      default:
        return "missing page name";
    }
  }
}

export default withRouter(compose<any>(withTranslation(), connect(mapStateToProps))(Rework));

const CustomTag = (props: any) => {
  if (props.version === "qrpopup") {
    return <QRPopup {...props} />;
  }
  if (props.version === "kachel1") {
    return <Kachel1 {...props} />;
  }
  if (props.version === "kachel2") {
    return <Kachel2 {...props} />;
  }
  if (props.version === "video1") {
    return <Video1 {...props} />;
  }
  if (props.version === "video2") {
    return <Video2 {...props} />;
  }
  if (props.version === "video3") {
    return <Video3 {...props} />;
  }

  return <div className="absolute top-0 left-0 w-screen h-dvh bg-red-500">Fallback</div>;
};
