import React from "react";
import { RootState } from "../redux";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { clearSwipeResults } from "../redux/modules/swipe";
import { withTranslation, WithTranslation } from "react-i18next";
import { compose } from "redux";
import ResultIcon from "../components/swipe/card/ResultIcon";
import { Redirect, RouteComponentProps, withRouter } from "react-router-dom";
import LockedHeightNoHeader from "../components/common/layouts/LockedHeightNoHeader";
import { setErrorCode } from "../redux/modules/error";
import { setFaqReturn } from "../redux/modules/app";
import { ErrorCode } from "../enums";
import { GA, GAEventType } from "../helpers/GA/ga";
import StartDateChecker from "../components/common/StartDateChecker";

// types
const mapStateToProps = (state: RootState) => ({
  count: state.swipe.count,
  needsTouchSensitivity: state.app.needsTouchSensitivity,
  invocationIsValid: state.app.invocationIsValid,
  showSuccessAfterInteractiveHelp: state.swipe.showSuccessAfterInteractiveHelp,
});

const mapDispatchToProps = {
  clearSwipeResults,
  setErrorCode,
  setFaqReturn,
};

type ResultProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  WithTranslation &
  RouteComponentProps;

type ResultStates = {
  redirect: any;
  successText: String;
};

// component
class Success extends React.Component<ResultProps, ResultStates> {
  constructor(props: ResultProps) {
    super(props);

    this.state = {
      redirect: null,
      successText: "",
    };
  }

  componentDidMount() {
    if (!this.props.invocationIsValid) {
      new GA().trackEvent(window, GAEventType.Error_InvalidInvocation);
      this.setErrorCodeAndRedirect(ErrorCode.InvalidInvocation);
      return;
    }

    if (this.props.showSuccessAfterInteractiveHelp) {
      this.setState({
        successText: "swipe:success.bodyAfterIH",
      });
    } else {
      this.setState({
        successText: "swipe:success.body",
      });
    }

    new GA().trackPageView(window, "/success");
  }

  setErrorCodeAndRedirect = (errorCode: ErrorCode) => {
    this.props.setErrorCode(errorCode);
    this.setState({
      redirect: "/error",
    });
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }

    return (
      <StartDateChecker>
        <div>
          <Helmet>
            <meta
              name="viewport"
              content="viewport-fit=cover, width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"
            />
          </Helmet>
          <LockedHeightNoHeader className="absolute top-0">
            <div className="flex flex-col h-dvh">
              <div>
                <div className="mx-4 mt-4 mb-2 text-lg font-bold text-center whitespace-pre-wrap">
                  {this.props.t("swipe:success.title")}
                </div>
                <div className="mx-4 my-2 text-center whitespace-pre-wrap">
                  {this.props.t("swipe:success.body")}
                </div>
              </div>
              <ResultIcon type="success" />
            </div>
          </LockedHeightNoHeader>
        </div>
      </StartDateChecker>
    );
  }
}

export default withRouter(
  compose<any>(
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps)
  )(Success)
);
