import classNames from "classnames";
import React from "react";
import ChevronLeft from "../../../assets/arrow_left.svg";

// types
type BackButtonProps = {
  onClick: any;
  className?: string;
};

// component
class BackButton extends React.Component<BackButtonProps> {
  render() {
    return (
      <button
        className={classNames("p-4", this.props.className)}
        onClick={this.props.onClick}
      >
        <img src={ChevronLeft} alt="chevron left" />
      </button>
    );
  }
}

export default BackButton;
