import { typedAction } from "../helpers";

type TutorialState = {
  currentSlide: number;
};

const initialState: TutorialState = {
  currentSlide: 0,
};

export const resetData = () => {
  return typedAction("tutorial/RESET_DATA");
};

export const setCurrentSlide = (currentSlide: number) => {
  return typedAction("tutorial/SET_CURRENT_SLIDE", currentSlide);
};

type TutorialAction = ReturnType<typeof resetData | typeof setCurrentSlide>;

export function tutorialReducer(
  state = initialState,
  action: TutorialAction
): TutorialState {
  switch (action.type) {
    case "tutorial/RESET_DATA":
      return {
        ...initialState,
      };
    case "tutorial/SET_CURRENT_SLIDE":
      return {
        ...state,
        currentSlide: action.payload,
      };
    default:
      return state;
  }
}
