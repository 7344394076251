import classNames from "classnames";
import React from "react";
import { connect } from "react-redux";
import { LicenseType } from "../../enums";
import { RootState } from "../../redux";
import { setLicenseType } from "../../redux/modules/user";
import { setCurrentSelect } from "../../redux/modules/select";
import { GA, GAEventType } from "../../helpers/GA/ga";

const mapStateToProps = (state: RootState) => ({
  licenseType: state.user.licenseType,
});

const mapDispatchToProps = {
  setLicenseType,
  setCurrentSelect,
};

type CardChoiceContainerSpecificProps = {
  type: LicenseType;
  src: string;
  title: string;
  subtitle: string;
};

type CardChoiceContainerProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  CardChoiceContainerSpecificProps;

type CardChoiceContainerStates = {
  redirect: any;
};

// component
class CardChoiceContainer extends React.Component<
  CardChoiceContainerProps,
  CardChoiceContainerStates
> {
  render() {
    return (
      <button
        onClick={() => {
          this.props.setLicenseType(this.props.type);
          if (this.props.type === LicenseType.Gray) {
            this.props.setCurrentSelect(2);
          } else if (this.props.type === LicenseType.Paper) {
            this.props.setCurrentSelect(1);
          } else {
            // default plastic
            this.props.setCurrentSelect(0);
          }

          new GA().trackEvent(
            window,
            GAEventType.Select_SelectCardType,
            LicenseType[this.props.type]
          );

          window.scrollTo({
            top: document.body.scrollHeight,
            behavior: "smooth",
          });
        }}
      >
        <div
          className={classNames(
            "px-4 py-2 mx-1 mb-4 rounded-lg shadow-lg md:flex-grow-0 md:w-76",
            {
              "bg-edding-blue": this.props.type === this.props.licenseType,
            }
          )}
          onClick={() => {}}
          style={{ touchAction: "pan-x pan-y" }}
        >
          <img
            src={this.props.src}
            alt={this.props.type.toString()}
            className="object-contain w-auto h-40 mx-auto rounded-lg"
          />
          <div
            className={classNames("mt-2 font-semibold text-left ", {
              "text-edding-blue": this.props.type !== this.props.licenseType,
              "text-white": this.props.type === this.props.licenseType,
            })}
          >
            {this.props.title}
            <div
              className={classNames(
                "font-normal italic text-left -mt-1 text-sm",
                {
                  "text-gray-400": this.props.type !== this.props.licenseType,
                  "text-gray-200": this.props.type === this.props.licenseType,
                }
              )}
            >
              {this.props.subtitle}
            </div>
          </div>
        </div>
      </button>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CardChoiceContainer);
