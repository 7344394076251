import React from "react";
import { RootState } from "../../redux";
import { connect } from "react-redux";
import { LicenseType } from "../../enums";

import Plastic_1 from "../../assets/tutorials/plastic/01_welcome-plastic.gif";
import Plastic_2 from "../../assets/tutorials/plastic/02_placement-plastic.gif";
import Plastic_3 from "../../assets/tutorials/plastic/03_swipe-plastic.gif";
import Plastic_4 from "../../assets/tutorials/plastic/04_verified-plastic.gif";
import Paper_1 from "../../assets/tutorials/paper/01_welcome-paper.gif";
import Paper_2 from "../../assets/tutorials/paper/02_placement-paper.gif";
import Paper_3 from "../../assets/tutorials/paper/03_swipe-paper.gif";
import Paper_4 from "../../assets/tutorials/paper/04_verified-paper.gif";
import Gray_1 from "../../assets/tutorials/gray/01_welcome-gray.gif";
import Gray_2 from "../../assets/tutorials/gray/02_placement-gray.gif";
import Gray_3 from "../../assets/tutorials/gray/03_swipe-gray.gif";
import Gray_4 from "../../assets/tutorials/gray/04_verified-gray.gif";
import TutorialImageContainer from "./TutorialImageContainer";
import classNames from "classnames";

// types
const mapStateToProps = (state: RootState) => ({
  licenseType: state.user.licenseType,
  currentSlide: state.tutorial.currentSlide,
});

const mapDispatchToProps = {};

type TutorialImageSpecificProps = {
  className?: string;
};

type TutorialImageProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  TutorialImageSpecificProps;

type TutorialImageStates = {
  gif: string;
  loaded: string;
};

type ImageDetails = {
  gif: string;
  title: string;
};

// component
class TutorialImage extends React.Component<
  TutorialImageProps,
  TutorialImageStates
> {
  images: ImageDetails[];

  constructor(props: TutorialImageProps) {
    super(props);
    this.images = [];

    if (this.props.licenseType === LicenseType.Paper) {
      this.images = [
        { gif: Paper_1, title: "welcome" },
        { gif: Paper_2, title: "placement" },
        { gif: Paper_3, title: "swipe" },
        { gif: Paper_4, title: "verified" },
      ];
    }

    if (this.props.licenseType === LicenseType.Plastic) {
      this.images = [
        { gif: Plastic_1, title: "welcome" },
        { gif: Plastic_2, title: "placement" },
        { gif: Plastic_3, title: "swipe" },
        { gif: Plastic_4, title: "verified" },
      ];
    }

    if (this.props.licenseType === LicenseType.Gray) {
      this.images = [
        { gif: Gray_1, title: "welcome" },
        { gif: Gray_2, title: "placement" },
        { gif: Gray_3, title: "swipe" },
        { gif: Gray_4, title: "verified" },
      ];
    }
  }

  render() {
    return (
      <TutorialImageContainer>
        <img
          src={this.images[this.props.currentSlide]?.gif || ""}
          alt={this.images[this.props.currentSlide]?.title || "no gif found"}
          className={classNames(
            "w-screen h-auto overflow-hidden object-cover tutorialImage md:rounded-lg md:shadow-lg",
            this.props.className
          )}
        />
      </TutorialImageContainer>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TutorialImage);
