import React, { useEffect, useState } from "react";
import queryString from "query-string";
import { ErrorCode, LicenseType } from "../enums";
import LockedHeightNoHeader from "../components/common/layouts/LockedHeightNoHeader";
import Header from "../components/common/Header";
import ProgressBar from "../components/upload/ProgressBar";
import { Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GA, GAEventType } from "../helpers/GA/ga";
import { RootState } from "../redux";
import { PrismaSDK } from "@prismadelabs/prismaid";
import SDKSingleton from "../SDK";

function Upload() {
  const dispatch = useDispatch();

  const [redirect, setRedirect] = useState<any>();

  const progress = useSelector((state: RootState) => state.app.pageProgress);

  const isMobileDevice = () => {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent) || /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.platform)) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    // invoke sdk to set the language
    const sdk: PrismaSDK = SDKSingleton.getInstance().sdk;

    new GA().trackPageView(window, "/upload");

    if (!isMobileDevice()) {
      console.log("is not mobile");
      new GA().trackEvent(window, GAEventType.Upload_UnsupportedDevice);
      dispatch({
        type: "error/SET_ERRORCODE",
        payload: ErrorCode.InvalidUploadDevice,
      });
      setRedirect("/error");
      return;
    }

    // /upload?greeting=TestTest&driverId=12345&quizNumber=1&uploadUrl=edding.de&licenseType=0
    // /upload?greeting=TestTest&driverId=12345&quizNumber=1&uploadUrl=edding.de&licenseType=1
    // /upload?greeting=TestTest&driverId=12345&quizNumber=1&uploadUrl=edding.de&licenseType=2
    const parsed = queryString.parse(window.location.search);
    var data = {} as any;

    if (parsed.greeting) {
      dispatch({
        type: "user/SET_GREETING",
        payload: parsed.greeting,
      });
      data = { ...data, greeting: parsed.greeting };
    }

    let needsSlideForLicenseSelection = true;
    if (parsed.driverId) {
      dispatch({
        type: "user/SET_DRIVERID",
        payload: parsed.driverId,
      });
      data = { ...data, driverId: parsed.driverId };
    }
    if (parsed.licenseType === LicenseType.Plastic.toString()) {
      dispatch({
        type: "user/SET_CARDTYPE",
        payload: LicenseType.Plastic,
      });
      dispatch({
        type: "user/SET_LICENSE_IS_KNOWN",
        payload: true,
      });
      data = { ...data, licenseType: LicenseType.Plastic };
      needsSlideForLicenseSelection = false;
    }
    if (parsed.licenseType === LicenseType.Paper.toString()) {
      dispatch({
        type: "user/SET_CARDTYPE",
        payload: LicenseType.Paper,
      });
      dispatch({
        type: "user/SET_LICENSE_IS_KNOWN",
        payload: true,
      });
      data = { ...data, licenseType: LicenseType.Paper };
      needsSlideForLicenseSelection = false;
    }
    if (parsed.licenseType === LicenseType.Gray.toString()) {
      dispatch({
        type: "user/SET_CARDTYPE",
        payload: LicenseType.Gray,
      });
      dispatch({
        type: "user/SET_LICENSE_IS_KNOWN",
        payload: true,
      });
      data = { ...data, licenseType: LicenseType.Gray };
      needsSlideForLicenseSelection = false;
    }

    let count = 6;

    if (needsSlideForLicenseSelection) {
      count = count + 1;
    }

    dispatch({
      type: "app/SET_SLIDECOUNT",
      payload: count,
    });
    data = { ...data, count: count };

    if (parsed.rollout === "true") {
      dispatch({
        type: "app/SET_IS_ROLLOUT",
        payload: true,
      });
      data = { ...data, rollout: true };
      count = 6;
    } else {
      dispatch({
        type: "app/SET_IS_ROLLOUT",
        payload: false,
      });
      data = { ...data, rollout: false };
    }

    if (parsed.quizNumber) {
      let quiz = "task." + parsed.quizNumber;
      // currently same task for both front pictures
      dispatch({
        type: "user/SET_QUIZNUMBER",
        payload: quiz,
      });
      data = { ...data, quizNumber: quiz };
    }

    // url is the endpoint where to send form data, not to be confused with the open-param with the same name
    if (parsed.uploadUrl) {
      dispatch({
        type: "user/SET_UPLOADURL",
        payload: parsed.uploadUrl.toString(),
      });
      data = { ...data, uploadUrl: parsed.uploadUrl.toString() };
    }

    if (parsed.pending === "true") {
      data = { ...data, pending: true };
    }

    console.log(data);

    if (data.hasOwnProperty("pending") && data.pending) {
      new GA().trackEvent(window, GAEventType.Upload_Pending);
      dispatch({
        type: "error/SET_ERRORCODE",
        payload: ErrorCode.UploadIsPending,
      });
      setRedirect("/error");
    } else if (data.hasOwnProperty("greeting") && data.hasOwnProperty("driverId") && data.hasOwnProperty("quizNumber") && data.hasOwnProperty("uploadUrl")) {
      dispatch({
        type: "app/SET_INVOKATION_IS_VALID",
        payload: true,
      });
      setRedirect("/form");
    } else {
      console.log(data);
      new GA().trackEvent(window, GAEventType.Upload_MissingParameters);
      dispatch({
        type: "error/SET_ERRORCODE",
        payload: ErrorCode.InvalidUploadLink,
      });
      setRedirect("/error");
    }
  }, []);

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <LockedHeightNoHeader>
      <div className="flex flex-col h-dvh">
        <ProgressBar progress={progress} />
        <Header />
      </div>
    </LockedHeightNoHeader>
  );
}

export default Upload;
