import { useTranslation } from "react-i18next";
import Text from "../../components/common/uikit/Text";
import Slide from "../../components/common/carousels/Slide";

const TutorialSlides = () => {
  const { t } = useTranslation("onboarding");

  return (
    <>
      <Slide>
        <div>
          <Text type="Headline M">{t("slide.prepare.p1")}</Text>
          <Text type="Caption A">{t("slide.prepare.p2")}</Text>
        </div>
      </Slide>
      <Slide>
        <div>
          <Text type="Headline M">{t("slide.placeCard.p1")}</Text>
        </div>
      </Slide>
      <Slide>
        <div>
          <Text type="Headline M">
            {t("slide.placeFingers.p1")}
            <br />
            {t("slide.placeFingers.p2")}
          </Text>
        </div>
      </Slide>
      <Slide>
        <div>
          <Text type="Headline M">
            {t("slide.swipe.p1")}
            <br />
            {t("slide.swipe.p2")}
          </Text>
        </div>
      </Slide>
    </>
  );
};

export default TutorialSlides;
