import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getImageData } from "../../../helpers/uploadHelper";
import { LicenseSide, LicenseType } from "../../../enums";
import SlideCard from "./SlideCard";
import CameraButton from "../../common/ui/CameraButton";

import plastic from "../img/license_plastic-front@2x.png";
import paper from "../img/license_paper-front@2x.png";
import gray from "../img/license_gray-front@2x.png";

import backPlastic from "../img/license_plastic-back@2x.png";
import backPaper from "../img/license_paper-back@2x.png";
import backGray from "../img/license_gray-back@2x.png";

import thumb from "../img/finger_thumb@2x.png";
import index from "../img/finger_index@2x.png";
import small from "../img/finger_small@2x.png";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux";
import { GA, GAEventType } from "../../../helpers/GA/ga";

type Props = {
  side: LicenseSide;
  task?: string;
  takePhoto: any;
  withHighlighting?: boolean;
};

enum Finger {
  Thumb = "thumb",
  Index = "index",
  Small = "small",
}

enum Position {
  BottomRight,
  TopRight,
  Right,
  Top,
  Bottom,
  BottomLeft,
  TopLeft,
  Left,
}

function TaskSlide(props: Props) {
  const { t } = useTranslation("upload");
  const licenseType = useSelector((state: RootState) => state.user.licenseType);

  const [src, setSrc] = useState("");
  const [title, setTitle] = useState("");
  const [lines, setLines] = useState([""]);
  const [finger, setFinger] = useState<any>();
  const [position, setPosition] = useState<any>();

  useEffect(() => {
    let src = "";
    let title = "";
    let lines = [""];

    switch (props.task) {
      // right hand
      case "task.1":
      case "task.2":
      case "task.3":
      case "task.4":
      case "task.5":
      // left hand
      case "task.16":
      case "task.17":
      case "task.18":
        setFinger(Finger.Index);
        break;
      // right hand
      case "task.6":
      case "task.7":
      case "task.8":
      case "task.9":
      case "task.10":
      // left hand
      case "task.19":
      case "task.20":
      case "task.21":
        setFinger(Finger.Thumb);
        break;
      // right hand
      case "task.11":
      case "task.12":
      case "task.13":
      case "task.14":
      case "task.15":
      // left hand
      case "task.22":
      case "task.23":
      case "task.24":
        setFinger(Finger.Small);
        break;
    }

    switch (props.task) {
      case "task.1":
      case "task.6":
      case "task.11":
        setPosition(Position.BottomRight);
        break;
      case "task.2":
      case "task.7":
      case "task.12":
        setPosition(Position.TopRight);
        break;
      case "task.3":
      case "task.8":
      case "task.13":
        setPosition(Position.Right);
        break;
      case "task.4":
      case "task.9":
      case "task.14":
        setPosition(Position.Top);
        break;
      case "task.5":
      case "task.10":
      case "task.15":
        setPosition(Position.Bottom);
        break;
      case "task.16":
      case "task.19":
      case "task.22":
        setPosition(Position.BottomLeft);
        break;
      case "task.17":
      case "task.20":
      case "task.23":
        setPosition(Position.TopLeft);
        break;
      case "task.18":
      case "task.21":
      case "task.24":
        setPosition(Position.Left);
        break;
    }

    switch (props.side) {
      case LicenseSide.Front1:
        switch (licenseType) {
          case LicenseType.Plastic:
            src = plastic;
            title = t("front.title.plastic");
            props.task && lines.push(t(props.task));
            break;
          case LicenseType.Paper:
            src = paper;
            title = t("front.title.paper");
            props.task && lines.push(t(props.task));
            break;
          case LicenseType.Gray:
            src = gray;
            title = t("front.title.gray");
            props.task && lines.push(t(props.task));
            break;
          default:
            break;
        }
        break;

      case LicenseSide.Back1:
        switch (licenseType) {
          case LicenseType.Plastic:
            src = backPlastic;
            title = t("back.title.plastic");
            lines = [t("back.turnAndPhotograph")];
            break;
          case LicenseType.Paper:
            src = backPaper;
            title = t("back.title.paper");
            lines = [t("back.turnAndPhotograph")];
            break;
          case LicenseType.Gray:
            src = backGray;
            title = t("back.title.gray");
            lines = [t("back.turnAndPhotograph")];
            break;
          default:
            break;
        }
        break;

      default:
        break;
    }

    setSrc(src);
    setTitle(title);
    setLines(lines);
  }, [props]);

  const handleChange = (e: any) => {
    new GA().trackEvent(window, GAEventType.Upload_Task_ClickTakePhoto);
    let image = getImageData(e);
    props.takePhoto(image);
  };

  return (
    <div className="flex flex-col justify-end h-full">
      <div className="relative flex items-center w-1/2 mx-auto mb-24">
        <img
          src={src}
          alt="license"
          className="object-contain"
        />
        {/* currently this is implicitly only called for backside 1/2 */}
        {/* HINT: if needed check for side */}
        {props.withHighlighting && (
          <>
            <div className="absolute inset-x-0 text-sm font-semibold text-center -top-16 text-edding-red">
              {licenseType === LicenseType.Plastic ? t("visibility.labelAndNumber") : t("visibility.label")}
            </div>
            {/* {licenseType === LicenseType.Plastic && <div className="absolute left-0 right-0 border-2 rounded top-45/100 border-edding-red bottom-15/100" />} */}
            {licenseType === LicenseType.Paper && <div className="absolute top-0 left-0 border-2 rounded right-75/100 border-edding-red bottom-15/100" />}
            {licenseType === LicenseType.Gray && <div className="absolute left-0 border-2 rounded right-60/100 top-65/100 border-edding-red bottom-10/100" />}
          </>
        )}

        {finger === Finger.Index && position === Position.BottomRight && (
          <img
            src={index}
            alt=""
            className="absolute object-contain w-32 transform -right-24 rotate-40 -bottom-24"
          />
        )}
        {finger === Finger.Thumb && position === Position.BottomRight && (
          <img
            src={thumb}
            alt=""
            className="absolute object-contain w-32 transform -right-28 -bottom-20"
          />
        )}
        {finger === Finger.Small && position === Position.BottomRight && (
          <img
            src={small}
            alt=""
            className="absolute object-contain w-32 transform -right-20 rotate-40 -bottom-24"
          />
        )}

        {finger === Finger.Index && position === Position.TopRight && (
          <img
            src={index}
            alt=""
            className="absolute object-contain w-32 transform -right-28 -top-10"
          />
        )}
        {finger === Finger.Thumb && position === Position.TopRight && (
          <img
            src={thumb}
            alt=""
            className="absolute object-contain w-32 transform -right-28 -top-14"
          />
        )}
        {finger === Finger.Small && position === Position.TopRight && (
          <img
            src={small}
            alt=""
            className="absolute object-contain w-32 transform -right-28 rotate-5 -top-4"
          />
        )}

        {finger === Finger.Index && position === Position.Right && (
          <img
            src={index}
            alt=""
            className="absolute object-contain w-32 transform -right-28 top-20/100"
          />
        )}
        {finger === Finger.Thumb && position === Position.Right && (
          <img
            src={thumb}
            alt=""
            className="absolute top-0 object-contain w-32 transform -right-28"
          />
        )}
        {finger === Finger.Small && position === Position.Right && (
          <img
            src={small}
            alt=""
            className="absolute object-contain w-32 transform -right-28 top-40/100 rotate-10"
          />
        )}

        {finger === Finger.Index && position === Position.Top && (
          <img
            src={index}
            alt=""
            className="absolute object-contain w-32 transform left-1/3 -top-24 -rotate-70"
          />
        )}
        {finger === Finger.Thumb && position === Position.Top && (
          <img
            src={thumb}
            alt=""
            className="absolute object-contain w-32 transform -rotate-45 -top-24 left-40/100"
          />
        )}
        {finger === Finger.Small && position === Position.Top && (
          <img
            src={small}
            alt=""
            className="absolute object-contain w-32 transform left-40/100 -top-22 -rotate-70"
          />
        )}

        {finger === Finger.Index && position === Position.Bottom && (
          <img
            src={index}
            alt=""
            className="absolute object-contain w-32 transform rotate-40 -bottom-24 left-30/100"
          />
        )}
        {finger === Finger.Thumb && position === Position.Bottom && (
          <img
            src={thumb}
            alt=""
            className="absolute object-contain w-32 transform rotate-40 -bottom-28 left-4/10"
          />
        )}
        {finger === Finger.Small && position === Position.Bottom && (
          <img
            src={small}
            alt=""
            className="absolute object-contain w-32 transform rotate-40 -bottom-26 left-30/100"
          />
        )}

        {finger === Finger.Index && position === Position.BottomLeft && (
          <img
            src={index}
            alt=""
            className="absolute object-contain w-32 transform -left-28 -bottom-16 -scale-x-1"
          />
        )}
        {finger === Finger.Thumb && position === Position.BottomLeft && (
          <img
            src={thumb}
            alt=""
            className="absolute object-contain w-32 transform -left-24 -bottom-24 -scale-x-1 -rotate-30"
          />
        )}
        {finger === Finger.Small && position === Position.BottomLeft && (
          <img
            src={small}
            alt=""
            className="absolute object-contain w-32 transform -left-24 -rotate-30 -bottom-26 -scale-x-1"
          />
        )}

        {finger === Finger.Index && position === Position.TopLeft && (
          <img
            src={index}
            alt=""
            className="absolute object-contain w-32 transform -left-28 -top-8 -scale-x-1"
          />
        )}
        {finger === Finger.Thumb && position === Position.TopLeft && (
          <img
            src={thumb}
            alt=""
            className="absolute object-contain w-32 transform -left-26 -top-7 -scale-x-1 -rotate-30"
          />
        )}
        {finger === Finger.Small && position === Position.TopLeft && (
          <img
            src={small}
            alt=""
            className="absolute object-contain w-32 transform -left-28 -top-3 -scale-x-1"
          />
        )}

        {finger === Finger.Index && position === Position.Left && (
          <img
            src={index}
            alt=""
            className="absolute object-contain w-32 transform -left-28 top-4 -scale-x-1"
          />
        )}
        {finger === Finger.Thumb && position === Position.Left && (
          <img
            src={thumb}
            alt=""
            className="absolute object-contain w-32 transform -left-26 top-6 -scale-x-1 -rotate-30"
          />
        )}
        {finger === Finger.Small && position === Position.Left && (
          <img
            src={small}
            alt=""
            className="absolute object-contain w-32 transform -left-28 top-40/100 -scale-x-1 -rotate-10"
          />
        )}
      </div>
      <div className="px-6 mb-6">
        <SlideCard
          title={title}
          lines={lines}
        />
      </div>
      <div>
        <div className="px-6 pb-4">
          <CameraButton
            title={t("button.takePhoto")}
            onChange={(e: any) => handleChange(e)}
          />
        </div>
      </div>
    </div>
  );
}

export default TaskSlide;
