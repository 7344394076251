import React, { useEffect, useState } from "react";
import { GA, GAEventType } from "../../../helpers/GA/ga";

import calendly from "../../../assets/failure/icon_calendly.svg";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux";

const OptionCalendly2 = () => {
  const eventUrl = useSelector((state: RootState) => state.user.eventUrl);
  const [calendlyUrl, setCalendlyUrl] = useState("");

  useEffect(() => {
    if (!eventUrl) {
      setCalendlyUrl("https://calendly.com/easycheck-by-edding/fsk");
    } else {
      setCalendlyUrl(eventUrl!);
    }
  }, []);

  return (
    <div className="bg-status-green bg-opacity-20">
      <div className="px-2 py-3 mx-auto rounded md:max-w-sm">
        <a
          href={calendlyUrl}
          onClick={() => {
            new GA().trackEvent(window, GAEventType.Failure_ClickToGoToCalendly, calendlyUrl);
          }}
          className="flex flex-col items-center justify-center text-left"
        >
          <img
            src={calendly}
            alt=""
            className="w-16 h-16 mt-2"
          />
          <div
            className="text-sm underline"
            dangerouslySetInnerHTML={{
              __html: "Video-Ident",
            }}
          ></div>
        </a>
      </div>
    </div>
  );
};

export default OptionCalendly2;
