import React, { useEffect, useState } from "react";
import SwipeableViews from "react-swipeable-views";
import { LicenseSide } from "../../enums";
import GreetingsSlide from "./slides/GreetingsSlide";
import SelectLicenseSlide from "./slides/SelectLicenseSlide";
import PlacementSlide from "./slides/PlacementSlide";
import TaskSlide from "./slides/TaskSlide";
import ConfirmSlide from "./slides/ConfirmSlide";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux";

type Props = {
  progress: number;
};

function FormSlides(props: Props) {
  const dispatch = useDispatch();
  const slideCount = useSelector((state: RootState) => state.app.slideCount);
  const quizNumber = useSelector((state: RootState) => state.user.quizNumber);

  const licenseIsKnown = useSelector(
    (state: RootState) => state.user.licenseIsKnown
  );

  const currentFormSlide = useSelector(
    (state: RootState) => state.app.currentFormSlide
  );

  const data = useSelector((state: RootState) => state.app.uploadData);

  const [slides, setSlides] = useState<any>([]);

  const setCurrentSlide = (index: number) => {
    dispatch({
      type: "app/SET_CURRENT_FORM_SLIDE",
      payload: index,
    });

    if (slideCount) {
      const progress = (100 / (slideCount - 1)) * index;
      dispatch({
        type: "app/SET_PAGE_PROGRESS",
        payload: progress,
      });
      // TODO log as page
    }
  };

  const onChangeIndex = (index: number) => {
    setCurrentSlide(index);
  };

  const nextSlide = () => {
    if (currentFormSlide || currentFormSlide === 0)
      setCurrentSlide(currentFormSlide + 1);
  };

  const takePhoto = (index: number, image: any, retry: boolean = false) => {
    let images = [...data];
    images[index] = image;
    // setData(images);
    dispatch({
      type: "app/SET_UPLOAD_DATA",
      payload: images,
    });

    if (!retry) {
      nextSlide();
    }
  };

  useEffect(() => {
    let views = [];

    views.push(<GreetingsSlide nextSlide={nextSlide} key="greeting" />);

    if (!licenseIsKnown) {
      views.push(<SelectLicenseSlide nextSlide={nextSlide} key="select" />);
    }
    // front1
    views.push(
      <PlacementSlide
        side={LicenseSide.Front1}
        nextSlide={nextSlide}
        key="place_front1"
      />
    );
    views.push(
      <TaskSlide
        side={LicenseSide.Front1}
        task={quizNumber}
        takePhoto={(image: any) => {
          takePhoto(0, image);
        }}
        key="task_front1"
      />
    );
    views.push(
      <ConfirmSlide
        side={LicenseSide.Front1}
        task={quizNumber}
        takePhoto={(image: any) => {
          takePhoto(0, image, true);
        }}
        image={data[0]}
        nextSlide={nextSlide}
        key="confirm_front1"
      />
    );
    // back1
    views.push(
      <TaskSlide
        side={LicenseSide.Back1}
        takePhoto={(image: any) => {
          takePhoto(2, image);
        }}
        withHighlighting
        key="task_back1"
      />
    );
    views.push(
      <ConfirmSlide
        side={LicenseSide.Back1}
        takePhoto={(image: any) => {
          takePhoto(2, image, true);
        }}
        image={data[2]}
        nextSlide={nextSlide}
        key="confirm_back1"
        isFinalUpload={true}
      />
    );

    setSlides(views);
  }, [licenseIsKnown, currentFormSlide, data]);

  return (
    <SwipeableViews
      enableMouseEvents
      disabled
      className="flex flex-col"
      index={currentFormSlide}
      onChangeIndex={onChangeIndex}
    >
      {slides}
    </SwipeableViews>
  );
}

export default FormSlides;
