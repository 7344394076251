import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Resizer from "react-image-file-resizer";
import { getImageData } from "../../../helpers/uploadHelper";
import { LicenseSide, LicenseType } from "../../../enums";
import Button from "../../common/ui/Button";
import CameraButton from "../../common/ui/CameraButton";
import { Dialog } from "@headlessui/react";
import Spinner from "../../common/ui/Spinner";
import SlideCard from "./SlideCard";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux";
import { useHistory } from "react-router-dom";
import { GA, GAEventType } from "../../../helpers/GA/ga";

type Props = {
  side: LicenseSide;
  task?: string;
  isFinalUpload?: boolean;
  takePhoto: any;
  image: any;
  nextSlide: any;
};

function ConfirmSlide(props: Props) {
  const history = useHistory();
  const { t } = useTranslation("upload");

  const driverId = useSelector((state: RootState) => state.user.driverId);
  const licenseType = useSelector((state: RootState) => state.user.licenseType);
  const uploadUrl = useSelector((state: RootState) => state.user.uploadUrl);
  const isRollout = useSelector((state: RootState) => state.app.isRollout);

  const [lines, setLines] = useState([""]);
  const [preview, setPreview] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    let lines = [""];

    switch (props.side) {
      case LicenseSide.Front1:
        switch (licenseType) {
          case LicenseType.Plastic:
            lines = [t("front.confirm.visibility.plastic")];
            break;
          case LicenseType.Paper:
            lines = [t("front.confirm.visibility.paper")];
            break;
          case LicenseType.Gray:
            lines = [t("front.confirm.visibility.gray")];
            break;
          default:
            break;
        }
        break;

      case LicenseSide.Back1:
        lines = licenseType === LicenseType.Plastic ? [t("back.confirm.labelAndNumberVisible")] : [t("back.confirm.labelVisible")];
        break;

      default:
        break;
    }

    setLines(lines);

    if (props.image?.photoURL) {
      setPreview(props.image.photoURL);
    }
  }, [props]);

  const onConfirm = () => {
    uploadImage();
    new GA().trackEvent(window, GAEventType.Upload_Confirm_ClickConfirm);
  };

  const checkImageWidth = async (file: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event: any) => {
        const image = new Image();
        image.src = event.target.result;
        image.onload = () => {
          resolve(image.width);
          return image.width;
        };
        reader.onerror = (err) => reject(err);
      };
    });
  };

  const resizeFile = (file: any) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        4096,
        2048,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "file"
      );
    });

  const uploadImage = async () => {
    if (!props.image?.file) {
      return;
    }

    if (!driverId) {
      console.warn("no driver id");
      return;
    }
    if (!uploadUrl) {
      console.warn("no uploadUrl");
      return;
    }

    if (!(licenseType === LicenseType.Plastic || licenseType === LicenseType.Paper || licenseType === LicenseType.Gray)) {
      console.warn("no licenseType");
      return;
    }

    setIsSubmitting(true);

    let file = props.image.file;

    const width = await checkImageWidth(file);
    if (Number(width) > 2048) {
      const resizedImage = await resizeFile(file);
      file = resizedImage;
    }

    const data = new FormData();
    data.append("file", file);
    data.append("driverId", driverId);
    data.append("imageId", props.side);
    data.append("licenseType", licenseType.toString());

    if (isRollout) {
      data.append("rollout", "true");
    }

    if (props.isFinalUpload) {
      data.append("isFinal", "true");
    }

    fetch(uploadUrl, {
      method: "POST",
      body: data,
    })
      .then(function (response) {
        if (!(response.status === 200)) {
          const error = (response && response.statusText) || response.status;
          return Promise.reject(error);
        }
        new GA().trackEvent(window, GAEventType.Upload_Confirm_UploadSuccess);
        return response.text();
      })
      .then((data) => {
        setTimeout(() => {
          setIsSubmitting(false);
          if (props.isFinalUpload) {
            history.push("/uploadsuccess");
          } else {
            props.nextSlide();
          }
        }, 200);
      })

      .catch((error) => {
        console.error(error);
        alert(t("error"));
        new GA().trackEvent(window, GAEventType.Upload_Confirm_UploadError, error);
        setIsSubmitting(false);
      });
  };

  const handleChange = (e: any) => {
    let image = getImageData(e);
    props.takePhoto(image);
    new GA().trackEvent(window, GAEventType.Upload_Confirm_ClickRetry);
  };

  return (
    <div className="flex flex-col justify-end h-full">
      <div className="px-6">
        <img
          src={preview}
          alt="preview"
          className="object-contain w-3/4 mx-auto h-72"
        />
      </div>
      <div className="px-6 mb-6">
        <SlideCard
          title=""
          lines={lines}
        />
      </div>
      <div className="grid grid-cols-2 gap-4 px-6 pb-4">
        <CameraButton
          title={t("button.retry")}
          onChange={(e: any) => handleChange(e)}
        />
        <Button
          title={t("button.confirm")}
          onClick={onConfirm}
          className="w-full"
        />
      </div>
      <Dialog
        open={isSubmitting}
        onClose={() => {
          setIsSubmitting(false);
        }}
        className="fixed inset-0 z-10 overflow-y-auto"
      >
        <div className="flex items-center justify-center min-h-dvh">
          <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
          <Spinner />
          <p className="text-gray-50 opacity-5">Uploading...</p>
        </div>
      </Dialog>
    </div>
  );
}

export default ConfirmSlide;
