import { Dialog } from "@headlessui/react";
import React, { useState } from "react";
import { Info } from "react-feather";
import { useTranslation } from "react-i18next";
import { GA, GAEventType } from "../../../helpers/GA/ga";
import Button from "../../common/ui/Button";
import Card from "../../common/ui/Card";

import manual from "../../../assets/failure/icon_sichtkontrolle.svg";

const OptionManual = () => {
  const { t } = useTranslation();
  const [showInfoText, setShowInfoText] = useState(false);

  return (
    <>
      <div className="bg-gray-500 bg-opacity-15">
        <div className="px-2 py-3 mx-auto rounded md:max-w-sm">
          <button
            onClick={() => {
              setShowInfoText(true);

              new GA().trackEvent(window, GAEventType.Failure_ClickToShowInfo);
            }}
            className="relative flex flex-row items-center space-x-4 text-left"
          >
            <img src={manual} alt="" className="flex-grow-0 w-20 h-20" />
            <div className="flex-grow text-sm">{t("failure:manual")}</div>
            <div className="absolute top-0 -right-4">
              <Info className="w-8 h-8 text-edding-blue" />
            </div>
          </button>
        </div>
      </div>

      <Dialog
        open={showInfoText}
        onClose={() => {
          setShowInfoText(false);
        }}
        className="fixed inset-0 z-10 overflow-y-auto"
      >
        <div className="flex items-center justify-center min-h-dvh">
          <Dialog.Overlay className="fixed inset-0 bg-black opacity-70 " />
          <div className="relative">
            <Card>
              <div className="grid p-4">
                <Dialog.Title>{t("failure:infoAlert.text")}</Dialog.Title>
                <Button
                  onClick={() => {
                    setShowInfoText(false);
                    new GA().trackEvent(window, GAEventType.Failure_ClickToCloseInfo);
                  }}
                  title={t("failure:close")}
                  className="w-full mt-8"
                />
              </div>
            </Card>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default OptionManual;
