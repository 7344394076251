import React from "react";
import Card from "../../common/ui/Card";

type Props = {
  title: string;
  lines: string[];
};

function SlideCard(props: Props) {
  return (
    <Card>
      <p className="mb-2 font-semibold">{props.title}</p>
      <ul className="">
        {props.lines.map((line, index) => {
          return (
            <li
              key={index}
              dangerouslySetInnerHTML={{
                __html: line,
              }}
            />
          );
        })}
      </ul>
    </Card>
  );
}

export default SlideCard;
