import React from "react";
import { connect } from "react-redux";

import easycheck from "../../assets/logos/logo_easycheck-by-edding.svg";
import { RootState } from "../../redux";
import HeaderBar from "./ui/HeaderBar";

// types
const mapStateToProps = (state: RootState) => ({
});

const mapDispatchToProps = {};

type CustomHeaderProps = {
  className?: string;
};

type HeaderProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  CustomHeaderProps;

// component
class Header extends React.Component<HeaderProps> {
  render() {
    return (
      <HeaderBar className="sticky top-0 w-screen">
        <img
          src={easycheck}
          alt="Logo easycheck by edding"
          className="h-6 m-2"
        />
      </HeaderBar>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
