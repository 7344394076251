import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import ScrollableHeightWithHeader from "../../common/layouts/ScrollableHeightWithHeader";
import OptionPhotoUpload2 from "./../options/OptionPhotoUpload2";
import OptionManual2 from "./../options/OptionManual2";
import OptionCalendly2 from "./../options/OptionCalendly2";
import OptionQRCode3 from "./../options/OptionQRCode3";

interface Props {
  incompatibleDevice?: boolean;
  sorry?: boolean;
  excuse1?: boolean;
  excuse2?: boolean;
  excuse3?: boolean;
  suggestion1?: boolean;
  suggestion2?: boolean;
  qrCode?: boolean;
  otherDevice?: boolean;
  availableOptions?: boolean;
  uploadUrl?: string;
  manualControl?: boolean;
  calendly?: boolean;
}

const Kachel2: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();

  return (
    <div>
      <Helmet>
        <meta
          name="viewport"
          content="viewport-fit=cover, width=device-width, initial-scale=1.0"
        />
      </Helmet>
      <ScrollableHeightWithHeader>
        <div className="flex flex-col justify-between flex-grow h-full gap-4 px-8 py-4 whitespace-pre-wrap">
          <div className="mx-4 mt-4 mb-2 space-y-2 font-bold text-center">
            <p>{props.incompatibleDevice && t("failure:incompatibleDevice")}</p>

            {/* <p>{props.sorry && t("failure:sorry")}</p> */}
            <p>{props.excuse1 && t("failure:excuse1")}</p>
            <p>{props.excuse2 && t("failure:excuse2")}</p>
            <p>{props.excuse3 && t("failure:excuse3")}</p>
            <p>{props.suggestion1 && t("failure:suggestion1")}</p>
            <p>{props.suggestion2 && t("failure:suggestion2")}</p>
          </div>

          <div className="space-y-4">
            <div className="grid grid-cols-2 gap-4">
              {props.qrCode && <OptionQRCode3 />}
              {props.calendly && <OptionCalendly2 />}
              {props.uploadUrl && <OptionPhotoUpload2 uploadUrl={props.uploadUrl} />}
              {props.manualControl && <OptionManual2 />}
            </div>
          </div>
        </div>
      </ScrollableHeightWithHeader>
    </div>
  );
};

export default Kachel2;
