import React from "react";

// types
type Props = {
  title: string;
  onChange: any;
};

// component
function CameraButton(props: Props) {
  return (
    <label className="flex items-center justify-center px-3 py-5 text-lg font-medium text-white rounded-md bg-edding-blue">
      {props.title}
      <input
        type="file"
        capture="environment"
        accept="image/*"
        className="hidden"
        onChange={props.onChange}
      />
    </label>
  );
}

export default CameraButton;
