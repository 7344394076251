import { Slot } from "@radix-ui/react-slot";
import Text from "./Text";
import classNames from "classnames";

type ButtonProps = {
  size?: "default" | "small";
  asChild?: boolean;
  children: React.ReactNode;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const baseClasses = "bg-easycheck-bluepurple text-center rounded-full w-auto h-auto active:bg-easycheck-blue group";

const sizeClasses: Record<NonNullable<ButtonProps["size"]>, string> = {
  default: "py-3 px-9",
  small: "py-3 px-8",
};

const Button = ({ size = "default", asChild, children, ...restProps }: ButtonProps) => {
  const Comp = asChild ? Slot : "button";
  const type = size === "small" ? "Body" : "Headline M";

  return (
    <Comp
      {...restProps}
      className={classNames(baseClasses, sizeClasses[size])}
    >
      <Text
        type={type}
        className="text-white group-active:text-easycheck-shade-light"
      >
        {children}
      </Text>
    </Comp>
  );
};

export default Button;
