import React, { useEffect } from "react";
import LockedHeightNoHeader from "../components/common/layouts/LockedHeightNoHeader";
import Header from "../components/common/Header";
import ProgressBar from "../components/upload/ProgressBar";
import { GA } from "../helpers/GA/ga";
import SuccessSlide from "../components/upload/slides/SuccessSlide";

function UploadSuccess() {
  useEffect(() => {
    new GA().trackPageView(window, "/uploadsuccess");
  }, []);

  return (
    <LockedHeightNoHeader>
      <div className="flex flex-col h-dvh">
        <ProgressBar progress={100} />
        <Header />
        <div className="flex justify-between flex-grow upload">
          <SuccessSlide />
        </div>
      </div>
    </LockedHeightNoHeader>
  );
}

export default UploadSuccess;
