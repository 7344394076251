import React from "react";
import { Helmet } from "react-helmet";
import { RootState } from "../redux";
import { connect } from "react-redux";
import { Redirect, RouteComponentProps, withRouter } from "react-router-dom";
import { ErrorCode } from "../enums";
import Card from "../components/common/ui/Card";
import CenteredLayout from "../components/common/ui/CenteredLayout";
import Button from "../components/common/ui/Button";
import WelcomeBackground from "./WelcomeBackground";
import { compose } from "redux";
import { withTranslation, WithTranslation } from "react-i18next";
import { setFaqReturn } from "../redux/modules/app";
import { GA, GAEventType } from "../helpers/GA/ga";
import SDKSingleton from "../SDK";
import i18n from "../i18n";

const mapStateToProps = (state: RootState) => ({
  errorCode: state.error.errorCode,
});

const mapDispatchToProps = {
  setFaqReturn,
};

type InvalidProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps & WithTranslation & RouteComponentProps;

type InvalidStates = {
  redirect: any;
};

type ErrorMessage = {
  title: string;
  body: string;
  button?: ErrorButton;
};

type ErrorButton = {
  title: string;
  action: any;
};

// component
class Notification extends React.Component<InvalidProps, InvalidStates> {
  private errorMessage: ErrorMessage;

  constructor(props: InvalidProps) {
    super(props);

    this.state = {
      redirect: null,
    };

    try {
      const sdk = SDKSingleton.getInstance().sdk;
      i18n.changeLanguage(sdk.getFinalSDKLanguage());
    } catch (error) {}

    switch (this.props.errorCode) {
      case ErrorCode.UnsupportedDevice: {
        this.errorMessage = {
          title: props.t("invalid:invalidDevice.title"),
          body: props.t("invalid:invalidDevice.body"),
          button: {
            title: props.t("faq"),
            action: this.redirectToFaq,
          },
        };
        this.props.history.push("/failure");
        break;
      }

      case ErrorCode.UnsupportedBrowser: {
        this.errorMessage = {
          title: props.t("invalid:invalidBrowser.title"),
          body: props.t("invalid:invalidBrowser.body"),
          button: {
            title: props.t("faq"),
            action: this.redirectToFaq,
          },
        };
        break;
      }

      case ErrorCode.ScreenTooSmall: {
        this.errorMessage = {
          title: props.t("invalid:screenTooSmall.title"),
          body: props.t("invalid:screenTooSmall.body"),
          button: {
            title: props.t("invalid:proceedAnyway"),
            action: this.redirectToApp,
          },
        };
        break;
      }

      case ErrorCode.DisplayZoomEnabled: {
        this.errorMessage = {
          title: props.t("invalid:zoomedDisplay.title"),
          body: props.t("invalid:zoomedDisplay.body"),
          button: {
            title: props.t("invalid:proceedAnyway"),
            action: this.redirectToApp,
          },
        };
        break;
      }

      case ErrorCode.InvalidInvocation: {
        this.errorMessage = {
          title: props.t("invalidLink.title"),
          body: props.t("invalidLink.body"),
        };
        break;
      }

      case ErrorCode.SessionExpired: {
        this.errorMessage = {
          title: props.t("sessionExpired.title"),
          body: props.t("sessionExpired.body"),
        };
        break;
      }

      case ErrorCode.LinkRequested: {
        this.errorMessage = {
          title: props.t("linkRequested.title"),
          body: props.t("linkRequested.body"),
        };
        break;
      }

      case ErrorCode.InvalidUploadDevice: {
        this.errorMessage = {
          title: props.t("invalidUploadDevice.title"),
          body: props.t("invalidUploadDevice.body"),
        };
        break;
      }
      case ErrorCode.InvalidUploadLink: {
        this.errorMessage = {
          title: props.t("invalidUploadLink.title"),
          body: props.t("invalidUploadLink.body"),
        };
        break;
      }
      case ErrorCode.UploadIsPending: {
        this.errorMessage = {
          title: props.t("uploadIsPending.title"),
          body: props.t("uploadIsPending.body"),
        };
        break;
      }

      default: {
        this.errorMessage = {
          title: props.t("error.title"),
          body: props.t("error.body"),
        };
        break;
      }
    }
  }

  componentDidMount() {
    new GA().trackPageView(window, "/error");
  }

  componentDidUpdate() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  redirectToFaq = () => {
    new GA().trackEvent(window, GAEventType.Notification_ClickToGoToFAQ);
    this.props.setFaqReturn("/invalid");
    this.props.history.push("/faq");
  };

  redirectToApp = () => {
    new GA().trackEvent(window, GAEventType.Notification_ClickToGoToTutorial);
    this.props.history.push("/tutorial");
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }

    const btn = this.errorMessage.button ? (
      <Button title={this.errorMessage.button.title} onClick={this.errorMessage.button.action} className="w-full mt-6" />
    ) : (
      ""
    );

    return (
      <div>
        <Helmet>
          <meta name="viewport" content="width=device-width, initial-scale=1" />
        </Helmet>
        <div className="absolute top-0 w-screen h-dvh overflow-hidden">
          <WelcomeBackground />
        </div>
        <div className="absolute top-0 w-screen h-dvh overflow-hidden">
          <CenteredLayout className="bg-gray-200 bg-opacity-50 backdrop-blur">
            <Card>
              <h1 className="text-lg font-extrabold">{this.errorMessage.title}</h1>
              <p className="mt-2 whitespace-pre-wrap">{this.errorMessage.body}</p>
              {btn}
            </Card>
          </CenteredLayout>
        </div>
      </div>
    );
  }
}

export default withRouter(compose<any>(withTranslation("invalid"), connect(mapStateToProps, mapDispatchToProps))(Notification));
