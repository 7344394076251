import anime from "animejs";
import React, { useEffect } from "react";

type Props = {
  progress: number;
};

const ProgressBar = (props: Props) => {
  useEffect(() => {
    anime({
      targets: "#pageProgressBar",
      keyframes: [
        { scaleY: 3, scaleX: 1.1 },
        { scaleY: 1, scaleX: 1 },
      ],
      duration: 750,
      easing: "easeOutQuad",
    });
  }, [props.progress]);

  return (
    <div className="relative">
      <div
        className="absolute top-0 left-0 w-full h-1"
        style={{
          backgroundImage:
            // 25% steps light gray with small white ticks
            "linear-gradient(90deg, lightgray 24.5%, white 24.5%, white 25%, lightgray 25%,lightgray 49.5%, white 49.5%, white 50%, lightgray 50%,lightgray 74.5%, white 74.5%, white 75%, lightgray 75%)",
        }}
      >
        <div
          id="pageProgressBar"
          className="absolute top-0 left-0 w-full h-1 transition-all bg-status-green animate-progress"
          style={{ width: `${props.progress}%` }}
        />
      </div>
    </div>
  );
};

export default ProgressBar;
