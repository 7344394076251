import React from "react";
import { RootState } from "../redux";
import { connect } from "react-redux";
import { withTranslation, WithTranslation } from "react-i18next";
import { compose } from "redux";
import { Redirect, RouteComponentProps, withRouter } from "react-router-dom";
import { ErrorCode } from "../enums";
import { setErrorCode } from "../redux/modules/error";

import { GA, GAEventType } from "../helpers/GA/ga";
import UniversalFailureScreen from "../components/failure/UniversalFailureScreen";

// types
const mapStateToProps = (state: RootState) => ({
  count: state.swipe.count,
  needsTouchSensitivity: state.app.needsTouchSensitivity,
  invocationIsValid: state.app.invocationIsValid,
  deviceIsSupported: state.app.deviceIsSupported,
  canRetry: state.user.canRetry,
  uploadUrl: state.user.uploadUrl,
});

const mapDispatchToProps = {
  setErrorCode,
};

type FailureProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  WithTranslation &
  RouteComponentProps;

type FailureStates = {
  redirect: any;
};

// component
class Failure extends React.Component<FailureProps, FailureStates> {
  constructor(props: FailureProps) {
    super(props);

    this.state = {
      redirect: null,
    };
  }

  componentDidMount() {
    if (!this.props.invocationIsValid) {
      new GA().trackEvent(window, GAEventType.Error_InvalidInvocation);
      this.setErrorCodeAndRedirect(ErrorCode.InvalidInvocation);
      return;
    }
  }

  setErrorCodeAndRedirect = (errorCode: ErrorCode) => {
    this.props.setErrorCode(errorCode);
    this.setState({
      redirect: "/error",
    });

    new GA().trackPageView(window, "/error");
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }

    if (!this.props.deviceIsSupported) {
      return (
        <UniversalFailureScreen
          incompatibleDevice
          excuse3
          qrCode
          otherDevice
          availableOptions
          uploadUrl={this.props.uploadUrl}
          manualControl
        />
      );
    }

    // standard
    if (this.props.canRetry === true) {
      return (
        <UniversalFailureScreen
          sorry
          excuse2
          qrCode
          otherDevice
          availableOptions
          uploadUrl={this.props.uploadUrl}
          manualControl
        />
      );
    } else {
      return (
        <UniversalFailureScreen
          excuse3
          availableOptions
          uploadUrl={this.props.uploadUrl}
          manualControl
          calendly
        />
      );
    }
  }
}

export default withRouter(
  compose<any>(
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps)
  )(Failure)
);
