import { useTranslation } from "react-i18next";
import Text from "../../components/common/uikit/Text";
import Step from "../../components/common/uikit/Step";
import Slide from "../../components/common/carousels/Slide";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";

const TouchSettingsSlides = () => {
  const { t } = useTranslation("onboarding");

  const { touchSetting } = useSelector((state: RootState) => {
    return {
      touchSetting: state.app.touchSensitivityType,
    };
  });

  if (!touchSetting) return null;

  return (
    <Slide>
      <div className="px-8">
        <Text type="Headline M">{t("slide.touch.title_" + touchSetting)}</Text>
        <div className="grid grid-cols-[3rem,auto] xs:grid-cols-1 mt-8 gap-4 xs:text-center text-left">
          <Step
            title="1"
            className="mx-auto"
          />
          <Text
            type="Body"
            className="inline-flex items-center justify-start xs:justify-center"
          >
            {t("slide.touch.p1_" + touchSetting)}
          </Text>
          <Step
            title="2"
            className="mx-auto xs:mt-2"
          />
          <Text
            type="Body"
            className="inline-flex items-center justify-start xs:justify-center"
          >
            {t("slide.touch.p2_" + touchSetting)}
          </Text>
          <Step
            title="3"
            className="mx-auto xs:mt-2"
          />
          <Text
            type="Body"
            className="inline-flex items-center justify-start xs:justify-center"
          >
            {t("slide.touch.p3_" + touchSetting)}
          </Text>
        </div>
      </div>
    </Slide>
  );
};

export default TouchSettingsSlides;
