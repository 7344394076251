import classNames from "classnames";
import React from "react";
import { X } from "react-feather";

// types
type CloseButtonProps = {
  onClick: any;
  className?: string;
};

// component
class CloseButton extends React.Component<CloseButtonProps> {
  render() {
    return (
      <button
        className={classNames("p-2", this.props.className)}
        onClick={this.props.onClick}
        style={{ touchAction: "auto" }}
      >
        <X />
      </button>
    );
  }
}

export default CloseButton;
