import * as RadioGroupPrimitive from "@radix-ui/react-radio-group";
import classNames from "classnames";
import React from "react";

const RadioGroup = React.forwardRef<React.ElementRef<typeof RadioGroupPrimitive.Root>, React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Root>>(
  ({ className, ...props }, ref) => {
    return (
      <RadioGroupPrimitive.Root
        className={classNames("space-x-4 group", className)}
        {...props}
        ref={ref}
      />
    );
  }
);

const RadioGroupItem = React.forwardRef<React.ElementRef<typeof RadioGroupPrimitive.Item>, React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Item>>(
  ({ title, className, ...props }, ref) => {
    return (
      <RadioGroupPrimitive.Item
        ref={ref}
        className={classNames(
          // baseclasses
          "uppercase rounded-full py-[0.375rem] px-6",
          // default
          "bg-easycheck-shade-light text-easycheck-bluepurple",
          // active
          "data-[state=checked]:bg-easycheck-bluepurple data-[state=checked]:text-white",
          // inactive => item is inactive, and parent has an active child
          "group-has-[*[data-state=checked]]:data-[state=unchecked]:bg-easycheck-light-blue group-has-[*[data-state=checked]]:data-[state=unchecked]:text-easycheck-bluepurple/30",
          className
        )}
        {...props}
      >
        {title}
        <RadioGroupPrimitive.Indicator></RadioGroupPrimitive.Indicator>
      </RadioGroupPrimitive.Item>
    );
  }
);

export { RadioGroup, RadioGroupItem };
