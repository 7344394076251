import React from "react";
import Card from "../../common/ui/Card";
import CenteredLayout from "../../common/ui/CenteredLayout";
import { useTranslation } from "react-i18next";

const DeviceOnTableWarning = () => {
  const { t } = useTranslation();

  return (
    <div className="absolute top-0 w-screen bg-blue-400 bg-opacity-40">
      <CenteredLayout>
        <Card>{t("interactiveHelp:device_in_hand")}</Card>
      </CenteredLayout>
    </div>
  );
};

export default DeviceOnTableWarning;
