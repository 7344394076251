import { Slot } from "@radix-ui/react-slot";
import Text from "./Text";
import classNames from "classnames";

type BlockProps = {
  tone?: "info";
  size?: "default" | "small";
  asChild?: boolean;
  children: React.ReactNode;
};

const baseClasses = " text-white text-center rounded uppercase w-fit";

const sizeClasses: Record<NonNullable<BlockProps["size"]>, string> = {
  default: "px-3 py-1.5",
  small: "px-3 py-1",
};

const toneClasses: Record<NonNullable<BlockProps["tone"]>, string> = {
  info: "bg-easycheck-shade-dark text-white",
};

const Block = ({ tone = "info", size = "default", asChild, children, ...restProps }: BlockProps) => {
  const Comp = asChild ? Slot : "div";
  const textType = size === "small" ? "Caption B" : "Caption C";

  return (
    <Comp {...restProps}>
      <Text
        className={classNames(baseClasses, sizeClasses[size], toneClasses[tone])}
        type={textType}
        asChild
      >
        <span>{children}</span>
      </Text>
    </Comp>
  );
};

export default Block;
