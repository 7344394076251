import React, { useEffect, useState } from "react";
import LockedHeightNoHeader from "../components/common/layouts/LockedHeightNoHeader";
import Header from "../components/common/Header";
import ProgressBar from "../components/upload/ProgressBar";
import { useDispatch, useSelector } from "react-redux";
import { GA, GAEventType } from "../helpers/GA/ga";
import FormSlides from "../components/upload/FormSlides";
import { RootState } from "../redux";
import { ErrorCode } from "../enums";
import { Redirect } from "react-router-dom";

function UploadForm() {
  const dispatch = useDispatch();
  const [redirect, setRedirect] = useState<any>();
  const progress = useSelector((state: RootState) => state.app.pageProgress);
  const invocationIsValid = useSelector(
    (state: RootState) => state.app.invocationIsValid
  );

  useEffect(() => {
    new GA().trackPageView(window, "/uploadform");

    if (!invocationIsValid) {
      new GA().trackEvent(window, GAEventType.Error_InvalidInvocation);

      dispatch({
        type: "error/SET_ERRORCODE",
        payload: ErrorCode.InvalidInvocation,
      });

      setRedirect("/error");
      return;
    }
  }, []);

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <LockedHeightNoHeader>
      <div className="flex flex-col h-dvh">
        <ProgressBar progress={progress} />
        <Header />
        <div className="flex justify-between flex-grow upload">
          <FormSlides progress={progress} />
        </div>
      </div>
    </LockedHeightNoHeader>
  );
}

export default UploadForm;
