import React from "react";
import { connect } from "react-redux";
import { RootState } from "../../redux";
import { setTouchSensitivityIsSet } from "../../redux/modules/app";
import { GA, GAEventType } from "../../helpers/GA/ga";
import classNames from "classnames";

const mapStateToProps = function (state: RootState) {
  return {
    touchSensitivityIsSet: state.app.touchSensitivityIsSet,
  };
};

const mapDispatchToProps = {
  setTouchSensitivityIsSet,
};

type CustomProps = {
  color?: string;
};

type ToggleProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  CustomProps;

function TouchToggle({ color = "edding-blue", ...props }: ToggleProps) {
  // function TouchToggle(props: ToggleProps) {
  const borderColor = "border-" + color;
  const bgColor = "bg-" + color;

  return (
    <button
      type="button"
      aria-pressed={props.touchSensitivityIsSet}
      onClick={() => {
        new GA().trackEvent(
          window,
          GAEventType.Touch_ToggleSensitivity,
          "toggle to " + props.touchSensitivityIsSet
        );
        props.setTouchSensitivityIsSet(!props.touchSensitivityIsSet);
      }}
      className={classNames(
        "relative inline-flex flex-shrink-0 h-6 transition-colors duration-200 ease-in-out border-2 rounded-full cursor-pointer w-11 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500",
        borderColor,
        props.touchSensitivityIsSet ? bgColor : "",
        // { bgColor: props.touchSensitivityIsSet },
        { "bg-white": !props.touchSensitivityIsSet }
      )}
    >
      <span className="sr-only">Use setting</span>
      <span
        aria-hidden="true"
        className={classNames(
          "inline-block w-5 h-5 transition duration-200 ease-in-out transform translate-x-0 rounded-full ring-0 border border-white",
          { "translate-x-5 bg-white": props.touchSensitivityIsSet },
          { "translate-x-0": !props.touchSensitivityIsSet },
          !props.touchSensitivityIsSet ? bgColor : ""
        )}
      ></span>
    </button>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(TouchToggle);
