import React, { ReactNode } from "react";
import { RootState } from "../../redux";
import { connect } from "react-redux";
import { setCurrentSelect } from "../../redux/modules/select";
import SwipeableViews from "react-swipeable-views";
import Pagination from "../common/ui/Pagination";
import { GA, GAEventType } from "../../helpers/GA/ga";

// types
const mapStateToProps = (state: RootState) => ({
  currentSelect: state.select.currentSelect,
});

const mapDispatchToProps = {
  setCurrentSelect,
};

type CardChoiceSlideSpecificProps = {
  children: ReactNode;
};

type CardChoiceSlideContainerProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  CardChoiceSlideSpecificProps;

type CardChoiceSlideContainerStates = {};

// component
class CardChoiceSlideContainer extends React.Component<
  CardChoiceSlideContainerProps,
  CardChoiceSlideContainerStates
> {
  onChangeIndex = (index: number) => {
    new GA().trackEvent(window, GAEventType.Select_SwipeCardTypeSlide);

    this.props.setCurrentSelect(index);
  };

  handleOnPaginationClick = (event: any, index: number) => {
    new GA().trackEvent(window, GAEventType.Select_ClickCardTypeSlide);

    this.props.setCurrentSelect(index);
  };

  render() {
    return (
      <>
        <SwipeableViews
          enableMouseEvents
          onChangeIndex={this.onChangeIndex}
          // edit here to adjust preview to next slide
          className={"px-12"}
          index={this.props.currentSelect}
        >
          {this.props.children}
        </SwipeableViews>
        <div>
          <Pagination
            dots={React.Children.count(this.props.children)}
            index={this.props.currentSelect}
            onChangeIndex={this.onChangeIndex}
            handleOnPaginationClick={this.handleOnPaginationClick}
            gray={true}
          />
        </div>
      </>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CardChoiceSlideContainer);
