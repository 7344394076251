import classNames from "classnames";
import React from "react";

type PaginationDotProps = {
  active: boolean;
  index: number;
  handleOnClick: any;
};

class PaginationDot extends React.Component<PaginationDotProps> {
  handleOnClick = (event: any) => {
    this.props.handleOnClick(event, this.props.index);
  };

  render() {
    return (
      <button type="button" onClick={this.handleOnClick}>
        <div
          className={classNames(
            "h-2 w-2 m-1 rounded-full",
            {
              "bg-gray-400": !this.props.active,
            },
            {
              "bg-edding-blue": this.props.active,
            }
          )}
        />
      </button>
    );
  }
}

export default PaginationDot;
