import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Code from "../Code";
import { Dialog } from "@headlessui/react";
import Card from "../../common/ui/Card";
import Button from "../../common/ui/Button";

const OptionQRCode2 = () => {
  const { t } = useTranslation();
  const [showInfoText, setShowInfoText] = useState(false);

  return (
    <div className="space-y-4">
      {/* <div className="text-sm font-bold text-center">{t("failure:qrCode1")}</div> */}
      <div className="bg-white bg-opacity-15">
          <button
            onClick={() => {
              setShowInfoText(true);

              // new GA().trackEvent(window, GAEventType.Failure_ClickToShowInfo);
            }}>
        <div className="flex flex-row items-center px-2 py-3 mx-auto space-x-4 text-left rounded md:max-w-sm">
          <div className="flex-grow-0 flex-shrink-0 w-20 h-20">

            <Code />
          </div>
          <div className="flex-grow text-sm">Mit dem QR-Code kannst du die Prüfung auf einem anderen Gerät durchführen</div>
        </div>
            </button>
      </div>

      <Dialog
        open={showInfoText}
        onClose={() => {
          setShowInfoText(false);
        }}
        className="fixed inset-0 z-10 overflow-y-auto"
      >
        <div className="flex items-center justify-center min-h-dvh">
          <Dialog.Overlay className="fixed inset-0 bg-black opacity-70" />
          <div className="relative p-4">
            <Card>
              <div className="grid p-4">
                {/* <Dialog.Title>{t("failure:infoAlert.text")}</Dialog.Title> */}
                <Code />
                <Button
                  onClick={() => {
                    setShowInfoText(false);
                    // new GA().trackEvent(window, GAEventType.Failure_ClickToCloseInfo);
                  }}
                  title={t("failure:close")}
                  className="w-full mt-8"
                />
              </div>
            </Card>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default OptionQRCode2;
