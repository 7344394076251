import React from "react";
import { Redirect, RouteComponentProps, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setLicenseType } from "../redux/modules/user";
import { ErrorCode, LicenseType } from "../enums";
import CardChoice from "../components/select/CardChoice";
import { compose } from "redux";
import { withTranslation, WithTranslation } from "react-i18next";
import Button from "../components/common/ui/Button";
import CardChoiceSlide from "../components/select/CardChoiceSlide";
import ScrollableHeightWithHeader from "../components/common/layouts/ScrollableHeightWithHeader";
import { RootState } from "../redux";
import { setErrorCode } from "../redux/modules/error";
import { screenHeightIsBigEnough } from "../helpers/screenCheck";
import { GA, GAEventType } from "../helpers/GA/ga";
import StartDateChecker from "../components/common/StartDateChecker";
import { setPageProgress } from "../redux/modules/app";

// types
const mapStateToProps = (state: RootState) => ({
  greeting: state.user.greeting,
  licenseType: state.user.licenseType,
  screenIsBigEnough: state.app.screenIsBigEnough,
  scaleFactor: state.swipe.scaleFactor,
  needsTouchSensitivity: state.app.needsTouchSensitivity,
  currentSelect: state.select.currentSelect,
  invocationIsValid: state.app.invocationIsValid,
});

const mapDispatchToProps = {
  setLicenseType,
  setErrorCode,
  setPageProgress,
};

type SelectProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  WithTranslation &
  RouteComponentProps;

type SelectStates = {
  redirect: any;
};

// component
class Select extends React.Component<SelectProps, SelectStates> {
  constructor(props: SelectProps) {
    super(props);

    this.state = {
      redirect: null,
    };
  }
  componentDidMount() {
    if (!this.props.invocationIsValid) {
      new GA().trackEvent(window, GAEventType.Error_InvalidInvocation);
      this.setErrorCodeAndRedirect(ErrorCode.InvalidInvocation);
      return;
    }

    new GA().trackPageView(window, "/select");
  }

  selectPlasticCard = () => {
    this.props.setLicenseType(LicenseType.Plastic);
  };

  selectPaperCard = () => {
    this.props.setLicenseType(LicenseType.Paper);
  };

  selectGrayCard = () => {
    this.props.setLicenseType(LicenseType.Gray);
  };

  setErrorCodeAndRedirect = (errorCode: ErrorCode) => {
    this.props.setErrorCode(errorCode);
    this.setState({
      redirect: "/error",
    });
  };

  onClick = () => {
    new GA().trackEvent(window, GAEventType.Select_ClickToStart);

    if (!this.props.screenIsBigEnough) {
      new GA().trackEvent(window, GAEventType.Error_ScreenTooSmall);
      this.setErrorCodeAndRedirect(ErrorCode.ScreenTooSmall);
      return;
    }

    if (
      this.props.licenseType === LicenseType.Paper &&
      !screenHeightIsBigEnough(this.props.scaleFactor)
    ) {
      new GA().trackEvent(window, GAEventType.Error_ScreenTooSmall);
      this.setErrorCodeAndRedirect(ErrorCode.ScreenTooSmall);
      return;
    }

    if (
      this.props.licenseType === LicenseType.Gray &&
      !screenHeightIsBigEnough(this.props.scaleFactor)
    ) {
      new GA().trackEvent(window, GAEventType.Error_ScreenTooSmall);
      this.setErrorCodeAndRedirect(ErrorCode.ScreenTooSmall);
      return;
    }

    if (this.props.needsTouchSensitivity) {
      this.props.history.push("/touch");
    } else {
      this.props.setPageProgress(10);
      this.props.history.push("/tutorial");
    }
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }

    return (
      <StartDateChecker>
        <ScrollableHeightWithHeader>
          <div className="flex flex-col justify-between flex-grow bg-white">
            <div className="p-4 text-center">
              <h1 className="text-lg font-bold">
                {this.props.t("select:title")}
                <br />
                {this.props.greeting}
              </h1>
              <p className="mt-4">{this.props.t("select:p1")}</p>
            </div>

            <div className="pb-4">
              <div className="md:hidden">
                <CardChoiceSlide>
                  <CardChoice id="plastic" />
                  <CardChoice id="paper" />
                  <CardChoice id="gray" />
                </CardChoiceSlide>
              </div>
              <div className="hidden md:justify-center md:flex md:space-x-2">
                {/* <CardChoiceSlide> */}
                <CardChoice id="plastic" />
                <CardChoice id="paper" />
                <CardChoice id="gray" />
                {/* </CardChoiceSlide> */}
              </div>
              <div className="px-4 pt-2 text-center">
                <Button
                  onClick={this.onClick}
                  title={this.props.t("select:button")}
                  disabled={
                    !(
                      this.props.licenseType === LicenseType.Plastic ||
                      this.props.licenseType === LicenseType.Paper ||
                      this.props.licenseType === LicenseType.Gray
                    )
                  }
                  className="w-full"
                />
              </div>
            </div>
          </div>
        </ScrollableHeightWithHeader>
      </StartDateChecker>
    );
  }
}

export default withRouter(
  compose<any>(
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps)
  )(Select)
);
