import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Code from "../Code";
import { Dialog } from "@headlessui/react";
import Card from "../../common/ui/Card";
import Button from "../../common/ui/Button";

const OptionQRCode2 = () => {
  const { t } = useTranslation();
  const [showInfoText, setShowInfoText] = useState(false);

  return (
    <div className="bg-yellow-400 bg-opacity-15">
      <div className="px-2 py-3 mx-auto rounded md:max-w-sm">
        <button
          onClick={() => {
            setShowInfoText(true);

            // new GA().trackEvent(window, GAEventType.Failure_ClickToShowInfo);
          }}
          className="flex flex-col items-center justify-center w-full text-center"
        >
          <div className="mt-2 w-14 h-14">
            <Code />
          </div>

          <div
            className="mt-2 text-sm underline"
            dangerouslySetInnerHTML={{
              __html: "Anderes Gerät",
            }}
          ></div>
        </button>
      </div>

      <Dialog
        open={showInfoText}
        onClose={() => {
          setShowInfoText(false);
        }}
        className="fixed inset-0 z-10 overflow-y-auto"
      >
        <div className="flex items-center justify-center min-h-dvh">
          <Dialog.Overlay className="fixed inset-0 bg-black opacity-70" />
          <div className="relative p-4">
            <Card>
              <div className="grid p-4">
                <Code />
                <Button
                  onClick={() => {
                    setShowInfoText(false);
                  }}
                  title={t("failure:close")}
                  className="w-full mt-8"
                />
              </div>
            </Card>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default OptionQRCode2;
