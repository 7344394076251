import { useCallback, useEffect, useState } from "react";
import useEmblaCarousel from "embla-carousel-react";
import BackButton from "../components/common/uikit/BackButton";
import Button from "../components/common/uikit/Button";
import { useTranslation } from "react-i18next";
import { PrismaSDK } from "@prismadelabs/prismaid";
import SDKSingleton from "../SDK";
import Text from "../components/common/uikit/Text";

import paper from "../assets/select/license_paper@2x.png";
import plastic from "../assets/select/license_plastic@2x.png";
import gray from "../assets/select/license_gray@2x.png";

export const NewSelect = () => {
  const { t } = useTranslation("onboarding");
  const [emblaRef, emblaApi] = useEmblaCarousel();

  const [current, setCurrent] = useState(1);
  const [count, setCount] = useState(1);

  useEffect(() => {
    // tmp set language
    // TODO remove
    const sdk: PrismaSDK = SDKSingleton.getInstance().sdk;
  }, []);

  const onPrevButtonClick = useCallback(() => {
    alert("Going Back");
  }, []);

  const onNextButtonClick = useCallback(() => {
    if (!emblaApi) return;

    console.log(current);

    alert("select");
  }, [emblaApi, current, count]);

  useEffect(() => {
    if (!emblaApi) {
      return;
    }

    setCount(emblaApi.scrollSnapList().length);
    setCurrent(emblaApi.selectedScrollSnap() + 1);

    emblaApi.on("select", () => {
      setCurrent(emblaApi.selectedScrollSnap() + 1);
    });
  }, [emblaApi]);

  return (
    <div className="flex flex-col py-4 text-center min-h-dvh bg-easycheck-light-blue">
      <Text
        type="Headline M"
        className="mt-8"
      >
        Format wählen und loslegen!
      </Text>

      <div
        className="flex flex-col mt-4 overflow-hidden grow"
        ref={emblaRef}
      >
        <div className="flex grow">
          <div className="flex-[0_0_calc(100vw-4rem)] min-w-0 ml-[2rem]">
            <div className="h-[50dvh] p-4">
              <div className="p-4 bg-white rounded-lg">
                <img src={plastic} />
              </div>
              <div className="mt-6">
                <Text type="Body">{t("plastic")}</Text>
                <Text type="Caption A">{t("plastic.issued")}</Text>
              </div>
            </div>
          </div>

          <div className="flex-[0_0_calc(100vw-4rem)] min-w-0 -ml-[1rem]">
            <div className="h-[50dvh] p-4">
              <div className="p-4 bg-white rounded-lg">
                <img src={paper} />
              </div>
              <div className="mt-6">
                <Text type="Body">{t("paper")}</Text>
                <Text type="Caption A">{t("paper.issued")}</Text>
              </div>
            </div>
          </div>
          <div className="flex-[0_0_calc(100vw-4rem)] min-w-0 -ml-[1rem] mr-[2rem]">
            <div className="h-[50dvh] p-4">
              <div className="p-4 bg-white rounded-lg">
                <img src={gray} />
              </div>
              <div className="mt-6">
                <Text type="Body">{t("gray")}</Text>
                <Text type="Caption A">{t("gray.issued")}</Text>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex items-center justify-between px-8">
        <BackButton onClick={onPrevButtonClick} />
        <Button onClick={onNextButtonClick}>{t("button.ok_next")}</Button>
      </div>
    </div>
  );
};

export default NewSelect;
