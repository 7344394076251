import React from "react";
import { useTranslation } from "react-i18next";
import Code from "../Code";

const OptionQRCode = () => {
  const { t } = useTranslation();

  return (
    <div className="space-y-4">
      <div className="text-sm font-bold text-center">{t("failure:qrCode1")}</div>
      <div className="bg-white bg-opacity-15">
        <div className="flex flex-row items-center px-2 py-3 mx-auto space-x-4 text-left rounded md:max-w-sm">
          <div className="flex-grow-0 flex-shrink-0 w-20 h-20">
            <Code />
          </div>
          <div className="flex-grow text-sm">{t("failure:qrCode2")}</div>
        </div>
      </div>
    </div>
  );
};

export default OptionQRCode;
