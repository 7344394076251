import React from "react";
import classNames from "classnames";

// types
type ButtonProps = {
  title: string;
  onClick: any;
  className?: string;
  color?: string;
  disabled?: boolean;
  primary?: boolean;
};

// component
class Button extends React.Component<ButtonProps> {
  static defaultProps = {
    primary: true,
    color: "edding-blue",
  };

  bgColor = "bg-" + this.props.color;

  render() {
    return (
      <button
        type="button"
        className={classNames(
          "inline-flex items-center px-3 py-5 text-sm leading-4 rounded-md",
          { "cursor-not-allowed": this.props.disabled },
          { "": !this.props.disabled },
          // primary, enabled
          {
            "text-white":
              // "text-white bg-edding-blue":
              this.props.primary && !this.props.disabled,
          },
          this.props.primary && !this.props.disabled ? this.bgColor : "",
          // primary, disabled
          {
            "text-white bg-edding-gray":
              this.props.primary && this.props.disabled,
          },
          // secondary, enabled
          {
            "text-edding-gray bg-white":
              !this.props.primary && !this.props.disabled,
          },
          // secondary, disabled
          {
            "text-edding-gray-light bg-white":
              !this.props.primary && this.props.disabled,
          },

          this.props.className
        )}
        onClick={this.props.onClick}
        disabled={this.props.disabled}
      >
        <div className="w-full text-lg font-medium">{this.props.title}</div>
      </button>
    );
  }
}

export default Button;
