import React, { ReactNode } from "react";
import { RootState } from "../../redux";
import { connect } from "react-redux";

// types
const mapStateToProps = (state: RootState) => ({
  currentSlide: state.tutorial.currentSlide,
});

const mapDispatchToProps = {};

type TutorialImageSpecificProps = {
  children: ReactNode;
};

type TutorialImageContainerProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  TutorialImageSpecificProps;

type TutorialImageContainerStates = {};

// component
class TutorialImageContainer extends React.Component<
  TutorialImageContainerProps,
  TutorialImageContainerStates
> {
  render() {
    return (
      <div className="flex items-center justify-center w-screen h-dvh overflow-hidden bg-white">
        <div className="max-w-md">{this.props.children}</div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TutorialImageContainer);
