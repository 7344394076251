import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

type Props = {};

function SuccessSlide(props: Props) {
  const { t } = useTranslation("upload");
  const [lines, setLines] = useState([""]);

  useEffect(() => {
    let lines = [t("submit.p1"), t("submit.p2"), t("submit.p3")];
    setLines(lines);
  }, [props, t]);

  return (
    <div className="h-full p-16 space-y-4 text-lg font-semibold text-center">
      {lines.map((line, index) => (
        <p key={index}>{line}</p>
      ))}
    </div>
  );
}

export default SuccessSlide;
