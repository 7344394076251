import React from "react";
import Card from "../common/ui/Card";

type TutorialSlideProps = {
  text: string;
};

class TutorialSlide extends React.Component<TutorialSlideProps> {
  render() {
    return (
      // onClick to catch doubleTaps
      // touchAction to catch pinch-to-zoom
      <div
        className="flex items-end h-dvh"
        onClick={() => {}}
        style={{ touchAction: "pan-x pan-y" }}
      >
        <div className="w-full mx-2 my-3 text-sm">
          <Card small={true}>
            <div
              dangerouslySetInnerHTML={{
                __html: this.props.text,
              }}
            />
          </Card>
        </div>
      </div>
    );
  }
}

export default TutorialSlide;
