import React, { useEffect } from "react";
import { Dialog } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import Button from "../common/ui/Button";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux";

function CookieBanner() {
  const { t } = useTranslation("cookies");
  const dispatch = useDispatch();

  const { showCookieBanner } = useSelector((state: RootState) => {
    return {
      showCookieBanner: state.cookie.showCookieBanner,
      isCookieAllowed: state.cookie.isCookieAllowed,
    };
  });

  const [cookie, setCookie] = useCookies(["eDLC_cookies_allowed"]);

  const onDecline = () => {
    dispatch({
      type: "cookie/SET_COOKIE_DATA",
      payload: { showCookieBanner: false, isCookieAllowed: false },
    });
  };

  const onAccept = () => {
    dispatch({
      type: "cookie/SET_COOKIE_DATA",
      payload: { showCookieBanner: false, isCookieAllowed: true },
    });

    setCookie("eDLC_cookies_allowed", "true", {
      expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
    });
  };

  useEffect(() => {
    // skip banner if cookie is already set
    const cookieValue = cookie.eDLC_cookies_allowed;

    if (cookieValue && cookieValue === "true") {
      dispatch({
        type: "cookie/SET_COOKIE_DATA",
        payload: { showCookieBanner: false, isCookieAllowed: true },
      });
      return;
    }
  }, []); // ignore dependency warning!

  return (
    <Dialog
      open={showCookieBanner}
      onClose={() => {}}
      as="div"
      className=""
    >
      <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-50" />

      <div className="absolute top-0 left-0 flex items-center justify-center w-screen p-8 text-center h-dvh">
        <div className="p-6 bg-white rounded-lg shadow-lg">
          <Dialog.Title>
            <p className="text-lg font-bold">{t("title")}</p>
          </Dialog.Title>

          <p className="mt-4">{t("body")}</p>

          <div className="grid grid-cols-2 gap-4 mt-8">
            <Button
              onClick={onDecline}
              primary={false}
              title={t("decline")}
            />
            <Button
              onClick={onAccept}
              title={t("accept")}
            />
          </div>
        </div>
      </div>
    </Dialog>
  );
}

export default CookieBanner;
