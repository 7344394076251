import React from "react";
import { Helmet } from "react-helmet";
import { RootState } from "../redux";
import { connect } from "react-redux";
import { Redirect, RouteComponentProps, withRouter } from "react-router-dom";
import HeaderBar from "../components/common/ui/HeaderBar";
import BackButton from "../components/common/ui/BackButton";
import { compose } from "redux";
import { withTranslation, WithTranslation } from "react-i18next";
import { setErrorCode } from "../redux/modules/error";
import { setFaqReturn } from "../redux/modules/app";
import { ErrorCode } from "../enums";
import ScrollableHeightNoHeader from "../components/common/layouts/ScrollableHeightNoHeader";
import { GA, GAEventType } from "../helpers/GA/ga";
import StartDateChecker from "../components/common/StartDateChecker";

// types
const mapStateToProps = (state: RootState) => ({
  invocationIsValid: state.app.invocationIsValid,
  faqReturn: state.app.faqReturn,
});

const mapDispatchToProps = {
  setErrorCode,
  setFaqReturn,
};

type FaqProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  WithTranslation &
  RouteComponentProps;

type FaqStates = {
  redirect: any;
};

// component
class Faq extends React.Component<FaqProps, FaqStates> {
  constructor(props: FaqProps) {
    super(props);

    this.state = {
      redirect: null,
    };
  }

  componentDidMount() {
    if (!this.props.invocationIsValid) {
      new GA().trackEvent(window, GAEventType.Error_InvalidInvocation);
      this.setErrorCodeAndRedirect(ErrorCode.InvalidInvocation);
      return;
    }

    new GA().trackPageView(window, "/faq");
  }

  componentDidUpdate() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  setErrorCodeAndRedirect = (errorCode: ErrorCode) => {
    this.props.setErrorCode(errorCode);
    this.setState({
      redirect: "/error",
    });
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }

    return (
      <StartDateChecker>
        <ScrollableHeightNoHeader>
          <Helmet>
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1"
            />
            <body className=""></body>
          </Helmet>
          <div className="bg-white">
            <HeaderBar className="justify-center h-12">
              <div className="absolute left-0">
                <BackButton
                  onClick={() => {
                    new GA().trackEvent(window, GAEventType.FAQ_ClickToGoBack);

                    this.props.history.push(this.props.faqReturn);
                    this.props.setFaqReturn("");
                  }}
                />
              </div>
              <h1 className="text-lg font-bold">{this.props.t("faq:title")}</h1>
            </HeaderBar>
            <div className="p-4 mx-auto mb-4 prose text-gray-900">
              <p>{this.props.t("intro")}</p>

              <details className="mt-4">
                <summary className="text-lg font-semibold">
                  {this.props.t("q1")}
                </summary>
                <div className="mx-4">
                  <p>{this.props.t("a1_1")}</p>
                  <ul>
                    <li>{this.props.t("a1_2")}</li>
                    <li>{this.props.t("a1_3")}</li>
                    <li>{this.props.t("a1_4")}</li>
                    <li>{this.props.t("a1_5")}</li>
                    <li>{this.props.t("a1_6")}</li>
                    <li>{this.props.t("a1_7")}</li>
                  </ul>
                </div>
              </details>

              <details className="mt-4">
                <summary className="text-lg font-semibold">
                  {this.props.t("q2")}
                </summary>
                <div className="mx-4">
                  <p>{this.props.t("a2")}</p>
                </div>
              </details>

              <details className="mt-4">
                <summary className="text-lg font-semibold">
                  {this.props.t("q3")}
                </summary>
                <div className="mx-4">
                  <p>{this.props.t("a3")}</p>
                </div>
              </details>

              <details className="mt-4">
                <summary className="text-lg font-semibold">
                  {this.props.t("q4")}
                </summary>
                <div className="mx-4">
                  <p>{this.props.t("a4")}</p>
                </div>
              </details>

              <details className="mt-4">
                <summary className="text-lg font-semibold">
                  {this.props.t("q5")}
                </summary>
                <div className="mx-4">
                  <p>{this.props.t("a5")}</p>
                </div>
              </details>
            </div>
          </div>
        </ScrollableHeightNoHeader>
      </StartDateChecker>
    );
  }
}

export default withRouter(
  compose<any>(
    withTranslation("faq"),
    connect(mapStateToProps, mapDispatchToProps)
  )(Faq)
);
