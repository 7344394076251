import React, { ReactNode } from "react";
import { RootState } from "../../redux";
import { Redirect } from "react-router-dom";
import { ErrorCode } from "../../enums";
import { setErrorCode } from "../../redux/modules/error";
import { GA, GAEventType } from "../../helpers/GA/ga";
import { connect } from "react-redux";

const mapStateToProps = (state: RootState) => ({
  startDate: state.app.startDate,
});

const mapDispatchToProps = {
  setErrorCode,
};

type StartDateCheckerCustomProps = {
  children: ReactNode;
};

type StartDateCheckerProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps & StartDateCheckerCustomProps;

type StartDateCheckerStates = {
  redirect: any;
};

class StartDateChecker extends React.Component<StartDateCheckerProps, StartDateCheckerStates> {
  constructor(props: StartDateCheckerProps) {
    super(props);

    this.state = {
      redirect: null,
    };
  }

  componentDidMount() {
    let now = new Date();
    let timeDiffInMS: number = now.getTime() - this.props.startDate.getTime();
    let hoursDiff: number = timeDiffInMS / (1000 * 60 * 60);

    // SessionExpired if link was opened more than 1 hour ago
    if (hoursDiff > 1) {
      new GA().trackEvent(window, GAEventType.Error_SessionExpired, "hoursDiff: " + hoursDiff);

      this.setErrorCodeAndRedirect(ErrorCode.SessionExpired);
    }
  }

  componentWillUnmount() {
    // fix warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  setErrorCodeAndRedirect = (errorCode: ErrorCode) => {
    this.props.setErrorCode(errorCode);
    this.setState({
      redirect: "/error",
    });
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }

    return <>{this.props.children}</>;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(StartDateChecker);
