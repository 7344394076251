import React from "react";
import CardChoiceContainer from "./CardChoiceContainer";

import paper from "../../assets/select/license_paper@2x.png";
import plastic from "../../assets/select/license_plastic@2x.png";
import gray from "../../assets/select/license_gray@2x.png";

import { LicenseType } from "../../enums";
import { WithTranslation, withTranslation } from "react-i18next";
import { compose } from "redux";

// types
type CardChoiceCustomProps = {
  id: string;
};

type CardChoiceProps = WithTranslation & CardChoiceCustomProps;

// component
class CardChoice extends React.Component<CardChoiceProps> {
  render() {
    if (this.props.id === "plastic") {
      return (
        <CardChoiceContainer
          type={LicenseType.Plastic}
          src={plastic}
          title={this.props.t("plastic")}
          subtitle={this.props.t("plastic.issued")}
        />
      );
    }

    if (this.props.id === "paper") {
      return (
        <CardChoiceContainer
          type={LicenseType.Paper}
          src={paper}
          title={this.props.t("paper")}
          subtitle={this.props.t("paper.issued")}
        />
      );
    }

    if (this.props.id === "gray") {
      return (
        <CardChoiceContainer
          type={LicenseType.Gray}
          src={gray}
          title={this.props.t("gray")}
          subtitle={this.props.t("gray.issued")}
        />
      );
    }

    return null;
  }
}

export default compose<any>(withTranslation())(CardChoice);
