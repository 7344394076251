import React from "react";

import success from "../../../assets/swipe/glyph_success.svg";
import error from "../../../assets/swipe/glyph_error.svg";

interface Props {
  type: string;
}

const ResultIcon: React.FC<Props> = (props: Props) => {
  if (props.type === "success") {
    return <img src={success} alt="" className="w-20 h-20 m-auto" />;
  } else {
    return <img src={error} alt="" className="w-20 h-20 m-auto" />;
  }
};

export default ResultIcon;
