import { HelpScreenButtonType } from "@prismadelabs/prismaid/dist/model/HelpScreen";
import Cookies from "universal-cookie";
import { addToLog } from "../localStorageHelper";

export enum GAEventType {
  // invalid app call
  Error_InvalidInvocation,

  // session expired
  Error_SessionExpired,

  // device check
  Error_UnsupportedDevice,
  Error_UnsupportedBrowser,
  Error_ScreenTooSmall,
  Error_DisplayZoomEnabled,

  // network connection
  Error_Network_Slow,
  Error_Network_Offline,

  // Notification
  Notification_ClickToGoToFAQ,
  Notification_ClickToGoToStart,

  // WELCOME
  Welcome_ClickToStart,

  // SELECT
  Select_SelectCardType,
  Select_ClickCardTypeSlide,
  Select_SwipeCardTypeSlide,
  Select_ClickToStart,
  // TUTORIAL
  Tutorial_ClickToGoBackToStart,
  Tutorial_ClickToSelectPreviousSlide,
  Tutorial_SwipeToSelectSlide,
  Tutorial_ClickToSelectNextSlide,
  Tutorial_ClickToFinishTutorial,
  // TOUCH
  Touch_ToggleSensitivity,
  Touch_ClickToConfirm,
  // SWIPE
  Swipe_IncomingResponse,
  Swipe_IncomingResponse_Success,
  Swipe_IncomingResponse_Failure,
  Swipe_ClickToGoBack,
  Swipe_ClickToGoToHelp,
  Swipe_Success,
  Swipe_Success_Again,
  Swipe_Error,
  Swipe_Error_Failure,
  Swipe_Error_HardFailure,
  // FAILURE
  Failure_ClickToShowInfo,
  Failure_ClickToCloseInfo,
  Failure_ClickToGoToCalendly,
  Failure_ClickToGoToPhotoUpload,
  // HELP
  Help_ClickToGoToFAQ,
  Help_ClickToGoToTutorial,
  Help_ClickToGoToSwipe,
  // FAQ
  FAQ_ClickToGoBack,
  Notification_ClickToGoToTutorial,

  // DEMO
  Demo_ClickToStartPaper,
  Demo_ClickToStartPlastic,

  // UPLOAD
  Upload_UnsupportedDevice,
  Upload_Pending,
  Upload_MissingParameters,
  Upload_ClickToStartUpload,
  Upload_ClickToStartRollout,
  Upload_Placement_ClickConfirm,
  Upload_Task_ClickTakePhoto,
  Upload_Confirm_ClickRetry,
  Upload_Confirm_ClickConfirm,
  Upload_Confirm_Upload,
  Upload_Confirm_UploadSuccess,
  Upload_Confirm_UploadError,
}

interface GAEvent {
  category: string;
  action: string;
  label?: string;
  value?: string;
}

export class GA {
  ga_events: GAEvent[] = [];

  constructor() {
    // invalid app call
    this.ga_events[GAEventType.Error_InvalidInvocation] = {
      category: "error",
      action: "link_invalid",
    };
    // session expired
    this.ga_events[GAEventType.Error_SessionExpired] = {
      category: "error",
      action: "session_expired",
    };
    // network
    this.ga_events[GAEventType.Error_Network_Slow] = {
      category: "warning",
      action: "network_slow",
    };
    this.ga_events[GAEventType.Error_Network_Offline] = {
      category: "warning",
      action: "network_offline",
    };
    // Notification
    this.ga_events[GAEventType.Error_UnsupportedBrowser] = {
      category: "notification",
      action: "notification_browser_unsupported",
    };
    this.ga_events[GAEventType.Error_UnsupportedDevice] = {
      category: "notification",
      action: "notification_device_unsupported",
    };
    this.ga_events[GAEventType.Error_ScreenTooSmall] = {
      category: "notification",
      action: "notification_screen_too_small",
    };
    this.ga_events[GAEventType.Error_DisplayZoomEnabled] = {
      category: "notification",
      action: "notification_displayzoom_enabled",
    };
    this.ga_events[GAEventType.Notification_ClickToGoToFAQ] = {
      category: "notification",
      action: "notification_faq_click",
    };
    this.ga_events[GAEventType.Notification_ClickToGoToStart] = {
      category: "notification",
      action: "notification_start_click",
    };
    this.ga_events[GAEventType.Notification_ClickToGoToTutorial] = {
      category: "notification",
      action: "notification_tutorial_click",
    };

    // Welcome
    this.ga_events[GAEventType.Welcome_ClickToStart] = {
      category: "welcome",
      action: "welcome_start_click",
    };

    // Select
    this.ga_events[GAEventType.Select_SelectCardType] = {
      category: "card_select",
      action: "card_select_select",
    };
    this.ga_events[GAEventType.Select_ClickCardTypeSlide] = {
      category: "card_select",
      action: "card_select_dot",
    };
    this.ga_events[GAEventType.Select_SwipeCardTypeSlide] = {
      category: "card_select",
      action: "card_select_swipe",
    };
    this.ga_events[GAEventType.Select_ClickToStart] = {
      category: "card_select",
      action: "card_select_start_click",
    };

    // Tutorial
    this.ga_events[GAEventType.Tutorial_ClickToGoBackToStart] = {
      category: "tutorial",
      action: "tutorial_backtostart_click",
    };
    this.ga_events[GAEventType.Tutorial_ClickToSelectPreviousSlide] = {
      category: "tutorial",
      action: "tutorial_slideback_click",
    };
    this.ga_events[GAEventType.Tutorial_ClickToSelectNextSlide] = {
      category: "tutorial",
      action: "tutorial_slidenext_click",
    };
    this.ga_events[GAEventType.Tutorial_SwipeToSelectSlide] = {
      category: "tutorial",
      action: "tutorial_swipe",
    };
    this.ga_events[GAEventType.Tutorial_ClickToFinishTutorial] = {
      category: "tutorial",
      action: "tutorial_finish_click",
    };

    // Touch
    this.ga_events[GAEventType.Touch_ToggleSensitivity] = {
      category: "touchsensitivity",
      action: "touchsensitivity_toggle",
    };
    this.ga_events[GAEventType.Touch_ClickToConfirm] = {
      category: "touchsensitivity",
      action: "touchsensitivity_confirm",
    };

    // Swipe screen
    this.ga_events[GAEventType.Swipe_IncomingResponse] = {
      category: "swipescreen",
      action: "swipescreen_incoming_response",
    };
    this.ga_events[GAEventType.Swipe_IncomingResponse_Success] = {
      category: "swipescreen",
      action: "swipescreen_incoming_response_success",
    };
    this.ga_events[GAEventType.Swipe_IncomingResponse_Failure] = {
      category: "swipescreen",
      action: "swipescreen_incoming_response_failure",
    };
    this.ga_events[GAEventType.Swipe_ClickToGoBack] = {
      category: "swipescreen",
      action: "swipescreen_back_click",
    };
    this.ga_events[GAEventType.Swipe_ClickToGoToHelp] = {
      category: "swipescreen",
      action: "swipescreen_help_click",
    };
    this.ga_events[GAEventType.Swipe_Success] = {
      category: "swipescreen",
      action: "swipescreen_success_done",
    };
    this.ga_events[GAEventType.Swipe_Success_Again] = {
      category: "swipescreen",
      action: "swipescreen_success_again",
    };
    this.ga_events[GAEventType.Swipe_Error] = {
      category: "swipescreen",
      action: "swipescreen_error",
    };
    this.ga_events[GAEventType.Swipe_Error_Failure] = {
      category: "swipescreen",
      action: "swipescreen_failure_final_canretry",
    };
    this.ga_events[GAEventType.Swipe_Error_HardFailure] = {
      category: "swipescreen",
      action: "swipescreen_failure_final",
    };

    // Failure
    this.ga_events[GAEventType.Failure_ClickToShowInfo] = {
      category: "failurescreen",
      action: "failurescreen_infotext_open_click",
    };
    this.ga_events[GAEventType.Failure_ClickToCloseInfo] = {
      category: "failurescreen",
      action: "failurescreen_infotext_close_click",
    };
    this.ga_events[GAEventType.Failure_ClickToGoToCalendly] = {
      category: "failurescreen",
      action: "failurescreen_calendly_click",
    };
    this.ga_events[GAEventType.Failure_ClickToGoToPhotoUpload] = {
      category: "failurescreen",
      action: "failurescreen_photoupload_click",
    };

    // Help
    this.ga_events[GAEventType.Help_ClickToGoToFAQ] = {
      category: "help",
      action: "help_faq_click",
    };
    this.ga_events[GAEventType.Help_ClickToGoToSwipe] = {
      category: "help",
      action: "help_retry_click",
    };
    this.ga_events[GAEventType.Help_ClickToGoToTutorial] = {
      category: "help",
      action: "help_tutorial_click",
    };

    // FAQ
    this.ga_events[GAEventType.FAQ_ClickToGoBack] = {
      category: "FAQ",
      action: "faq_back_click",
    };

    // Demo
    this.ga_events[GAEventType.Demo_ClickToStartPaper] = {
      category: "demo",
      action: "demo_start_click_paper",
    };
    this.ga_events[GAEventType.Demo_ClickToStartPlastic] = {
      category: "demo",
      action: "demo_start_click_plastic",
    };

    // Upload
    this.ga_events[GAEventType.Upload_UnsupportedDevice] = {
      category: "upload",
      action: "upload_unsupportedDevice",
    };
    this.ga_events[GAEventType.Upload_Pending] = {
      category: "upload",
      action: "upload_isPending",
    };
    this.ga_events[GAEventType.Upload_MissingParameters] = {
      category: "upload",
      action: "upload_missingParameters",
    };
    this.ga_events[GAEventType.Upload_ClickToStartUpload] = {
      category: "upload",
      action: "upload_start_upload",
    };
    this.ga_events[GAEventType.Upload_ClickToStartRollout] = {
      category: "upload",
      action: "upload_start_rollout",
    };
    this.ga_events[GAEventType.Upload_Placement_ClickConfirm] = {
      category: "upload",
      action: "upload_placement_click_confirm",
    };
    this.ga_events[GAEventType.Upload_Task_ClickTakePhoto] = {
      category: "upload",
      action: "upload_task_click_takePhoto",
    };
    this.ga_events[GAEventType.Upload_Confirm_ClickConfirm] = {
      category: "upload",
      action: "upload_confirm_click_confirm",
    };
    this.ga_events[GAEventType.Upload_Confirm_ClickRetry] = {
      category: "upload",
      action: "upload_confirm_click_retry",
    };
    this.ga_events[GAEventType.Upload_Confirm_Upload] = {
      category: "upload",
      action: "upload_confirm_upload",
    };
    this.ga_events[GAEventType.Upload_Confirm_UploadSuccess] = {
      category: "upload",
      action: "upload_confirm_uploadSuccess",
    };
    this.ga_events[GAEventType.Upload_Confirm_UploadError] = {
      category: "upload",
      action: "upload_confirm_uploadError",
    };
  }

  trackPageView(clientWindow: any, page: string) {
    addToLog("page " + page);

    this.enableGAWindowProperty(clientWindow);

    if (clientWindow.gtag && this.gaIsAllowed()) {
      clientWindow.gtag("event", "pageview", {
        event_category: "pageview",
        event_label: page,
      });
    }
  }

  trackInteractiveHelpFlash(clientWindow: any) {
    addToLog("interactiveHelpFlash");

    this.enableGAWindowProperty(clientWindow);

    if (clientWindow.gtag) {
      clientWindow.gtag("event", "interactiveHelpFlash", {
        event_category: "interactiveHelp",
      });
    }
  }

  trackInteractiveHelpScreen(clientWindow: any, helpScreen: string, data?: any) {
    addToLog("helpScreen " + helpScreen);

    this.enableGAWindowProperty(clientWindow);

    if (clientWindow.gtag) {
      clientWindow.gtag("event", "interactiveHelp_" + helpScreen, {
        event_category: "interactiveHelp",
        value: data,
      });
    }
  }

  trackInteractiveHelpClick(clientWindow: any, helpScreen: string, buttonType: HelpScreenButtonType) {
    this.enableGAWindowProperty(clientWindow);

    let type;
    switch (buttonType) {
      case HelpScreenButtonType.ok:
        type = "ok";
        break;
      case HelpScreenButtonType.yes:
        type = "yes";
        break;
      case HelpScreenButtonType.no:
        type = "no";
        break;
      default:
        type = "error";
        break;
    }

    addToLog("interactiveHelpClick_" + helpScreen + "_" + type);

    if (clientWindow.gtag && this.gaIsAllowed()) {
      clientWindow.gtag("event", "interactiveHelpClick_" + helpScreen + "_" + type, {
        event_category: "interactiveHelp",
      });
    }
  }

  trackInteractiveHelpDismiss(clientWindow: any, helpScreen: string) {
    this.enableGAWindowProperty(clientWindow);

    addToLog("interactiveHelpClick_" + helpScreen + "_dismiss");

    if (clientWindow.gtag && this.gaIsAllowed()) {
      clientWindow.gtag("event", "interactiveHelpClick_" + helpScreen + "_dismiss", {
        event_category: "interactiveHelp",
      });
    }
  }

  trackEvent(clientWindow: any, eventType: GAEventType, label?: string, value?: number) {
    this.enableGAWindowProperty(clientWindow);

    const event = this.ga_events[eventType];
    addToLog(
      "event - " +
        event.category +
        " - " +
        event.action +
        " - " +
        event.label +
        " - " +
        label +
        " - " +
        (label ?? event.label) +
        " - " +
        value
    );

    if (clientWindow.gtag && this.gaIsAllowed()) {
      clientWindow.gtag("event", event.action, {
        event_category: event.category,
        event_label: label ?? event.label,
        value,
      });
    }
  }

  enableGAWindowProperty(clientWindow: any) {
    if (this.gaIsAllowed()) {
      const property: string = "ga-disable-" + process.env.REACT_APP_GA_ID;

      if (clientWindow[property] === false) {
        return;
      }

      clientWindow[property] = false;
      clientWindow.gtag("js", new Date());
      clientWindow.gtag("config", process.env.REACT_APP_GA_ID);
    }
  }

  gaIsAllowed() {
    const cookies = new Cookies();
    const gaIsAllowed: string = cookies.get("eDLC_cookies_allowed");

    if (gaIsAllowed && gaIsAllowed.toLowerCase() === "true") {
      return true;
    } else {
      return false;
    }
  }
}
