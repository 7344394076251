import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { LicenseType } from "../../../enums";
import { RootState } from "../../../redux";
import Button from "../../common/ui/Button";
import CardChoice from "../../select/CardChoice";
import CardChoiceSlide from "../../select/CardChoiceSlide";

type Props = {
  nextSlide: any;
};

function SelectLicenseSlide(props: Props) {
  const { t } = useTranslation("upload");
  const licenseType = useSelector((state: RootState) => state.user.licenseType);

  return (
    <div className="flex flex-col justify-between h-full">
      <div className="px-6 pt-4 text-center whitespace-pre-wrap">
        <p className="mt-4 font-semibold">{t("selectLicense")}</p>
      </div>
      <div>
        <CardChoiceSlide>
          <CardChoice id="plastic" />
          <CardChoice id="paper" />
          <CardChoice id="gray" />
        </CardChoiceSlide>
        <div className="grid grid-cols-1 gap-4 px-6 pb-4 mt-4">
          <Button
            title={t("button.confirm")}
            onClick={props.nextSlide}
            disabled={
              !(
                licenseType === LicenseType.Plastic ||
                licenseType === LicenseType.Paper ||
                licenseType === LicenseType.Gray
              )
            }
          />
        </div>
      </div>
    </div>
  );
}

export default SelectLicenseSlide;
