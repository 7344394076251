import { Slot } from "@radix-ui/react-slot";
import Text from "./Text";

type CaptionProps = {
  current: number;
  count: number;
  screenSize?: "default" | "small";
  asChild?: boolean;
};

const Caption = ({ current, count, screenSize = "default", asChild, ...restProps }: CaptionProps) => {
  const Comp = asChild ? Slot : "div";

  const type = screenSize === "small" ? "Headline L" : "Headline XL";

  return (
    <Comp
      {...restProps}
      className="underline decoration-easycheck-shade-light underline-offset-4 decoration-2"
    >
      <Text
        type={type}
        asChild
      >
        <span>{current}</span>
      </Text>
      <Text
        type={type}
        asChild
      >
        <span className="text-easycheck-shade-light">/{count}</span>
      </Text>
    </Comp>
  );
};

export default Caption;
