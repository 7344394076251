import moment from "moment";

const logKey = "easycheck-log";
const dateFormat = "DD.MM.YY HH:mm:ss.SSS";

export function setItem(key: string, value: any) {
  localStorage.setItem(key, value);
}

export function getItem(key: string) {
  localStorage.getItem(key);
}

export function removeItem(key: string) {
  localStorage.removeItem(key);
}

export function addToLog(value: any) {
  let storage = JSON.parse(localStorage.getItem(logKey) || "{}");
  let log = Object.values(storage);

  log.push(moment().format(dateFormat) + " - " + value);

  setItem(logKey, JSON.stringify(log));
}

export function removeLog() {
  localStorage.removeItem(logKey);
}

export function clearOldStorage() {
  const logKey = "easycheck-log";

  let storage = JSON.parse(localStorage.getItem(logKey) || "{}");

  let log = Object.values(storage);

  if (log.length > 0) {
    let first = log[0] as String;
    try {
      let oldestEntry = moment(first.slice(0, 14), dateFormat);
      let limit = moment().subtract(3, "days");
      let entryIsOld = moment(oldestEntry).isBefore(limit);

      if (entryIsOld) {
        removeLog();
      }
    } catch (error) {
      console.log(error);
    }
  } else {
    console.log("no log found");
  }
}
