import React from "react";
import { Mail, Share2, XCircle } from "react-feather";
import { removeItem } from "../helpers/localStorageHelper";
import { useLocalStorage } from "../hooks/useLocalStorage";

interface Props {}

const Log: React.FC<Props> = (props) => {
  const logKey = "easycheck-log";

  const [log, setLog] = useLocalStorage(logKey, []);

  return (
    <div className="p-4">
      <div className="flex items-center justify-between w-full">
        <h2 className="text-xl font-bold">easycheck logging</h2>
        <button
          onClick={() => {
            removeItem(logKey);
            setLog([]);
          }}
          className="inline-flex items-center space-x-1 text-red-700 stroke-1 hover:text-red-900"
        >
          <div>Clear log</div>
          <XCircle />
        </button>
      </div>
      <div className="flex flex-col mt-8 space-y-2">
        <a
          href={
            "mailto:?subject=easycheck%20-%20error%20logging&body=" +
            encodeURIComponent(JSON.stringify(log, null, " "))
          }
          className="inline-flex items-center p-2 space-x-2 text-blue-700 stroke-1 hover:text-blue-900"
        >
          <Mail />
          <div>Send to email</div>
        </a>
        {navigator.share ? (
          <button
            onClick={() => {
              navigator
                .share({
                  title: "easycheck logging",
                  text: `${log}`,
                })
                .then(() => {
                  console.log("Successfully shared");
                })
                .catch((error) => {
                  console.error("Something went wrong sharing", error);
                });
            }}
            className="inline-flex items-center p-2 space-x-2 stroke-1 text-violet-700 hover:text-violet-900"
          >
            <Share2 />
            <div>Share</div>
          </button>
        ) : null}
      </div>
      <div className="flex flex-col mt-4 prose">
        <div className="whitespace-pre-wrap">
          {JSON.stringify(log, null, " ")}
        </div>
      </div>
    </div>
  );
};

export default Log;
