import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import PageProgress from "./components/common/PageProgress";

// pages
import Open from "./pages/Open";
import App from "./pages/App";
import Demo from "./pages/Demo";

import Welcome from "./pages/Welcome";
import Select from "./pages/Select";

import Tutorial from "./pages/Tutorial";

import Touch from "./pages/Touch";
import Swipe from "./pages/Swipe";
import Success from "./pages/Success";
import Failure from "./pages/Failure";
import Faq from "./pages/Faq";
import Notification from "./pages/Notification";
import Log from "./pages/Log";

import Upload from "./pages/Upload";

import PageShell from "./components/common/layouts/PageShell";
import UploadForm from "./pages/UploadForm";
import UploadSuccess from "./pages/UploadSuccess";
import Rework from "./pages/Rework";
import UIKit from "./pages/UIKit";
import NewOnboarding from "./pages/NewOnboarding";
import NewSelect from "./pages/NewSelect";

type RouterComponentSpecificProps = {
  history: any;
  location: any;
  match: any;
};

class RouterComponent extends React.Component<RouterComponentSpecificProps> {
  currentPathname: null;
  currentSearch: null;

  constructor(props: RouterComponentSpecificProps) {
    super(props);

    // Store the previous pathname and search strings
    this.currentPathname = null;
    this.currentSearch = null;
  }

  componentDidMount() {
    const { history } = this.props;

    history.listen((newLocation: { pathname: null; search: null }, action: string) => {
      if (action === "PUSH") {
        if (newLocation.pathname !== this.currentPathname || newLocation.search !== this.currentSearch) {
          // Save new location
          this.currentPathname = newLocation.pathname;
          this.currentSearch = newLocation.search;

          // Clone location object and push it to history
          history.push({
            pathname: newLocation.pathname,
            search: newLocation.search,
          });
        }
      } else {
        // Send user back if they try to navigate back
        history.go(1);
      }
    });
  }

  getTransitions = (path: string) => {
    let transitionClass = "";

    switch (path) {
      case "/swipe":
        transitionClass = "slideFromBottom";
        break;
      default:
        transitionClass = "slideFromRight";
        break;
    }
    return transitionClass;
  };

  render() {
    return (
      <Route
        render={({ location }) => (
          <div>
            <TransitionGroup>
              <CSSTransition
                key={location.pathname}
                classNames={this.getTransitions(location.pathname)}
                timeout={600}
              >
                <Switch location={location}>
                  <Route
                    path="/open"
                    component={Open}
                  />
                  <Route
                    exact
                    path="/"
                    component={App}
                  />
                  <Route
                    exact
                    path="/welcome"
                    component={PageShell(Welcome)}
                  />
                  <Route
                    exact
                    path="/select"
                    component={PageShell(Select)}
                  />
                  <Route
                    path="/touch"
                    component={Touch}
                  />
                  <Route
                    path="/tutorial"
                    component={Tutorial}
                  />
                  <Route
                    path="/swipe"
                    component={Swipe}
                  />
                  <Route
                    path="/success"
                    component={Success}
                  />
                  <Route
                    path="/failure"
                    component={Failure}
                  />
                  <Route
                    path="/faq"
                    component={Faq}
                  />

                  <Route
                    path="/error"
                    component={Notification}
                  />

                  <Route
                    path="/rework/:version/:page"
                    component={Rework}
                  />

                  <Route
                    path="/demo"
                    component={PageShell(Demo)}
                  />
                  <Route
                    path="/debuglog"
                    component={Log}
                  />

                  <Route
                    path="/upload"
                    component={PageShell(Upload)}
                  />
                  <Route
                    path="/form"
                    component={PageShell(UploadForm)}
                  />
                  <Route
                    path="/uploadsuccess"
                    component={PageShell(UploadSuccess)}
                  />

                  <Route
                    path="/uikit"
                    component={UIKit}
                  />
                  <Route
                    path="/onboarding"
                    component={NewOnboarding}
                  />
                  <Route
                    path="/newselect"
                    component={NewSelect}
                  />

                  {/* INFO catch-all route must be last in the old router version */}
                  <Route component={Notification} />
                </Switch>
              </CSSTransition>
              <PageProgress />
            </TransitionGroup>
          </div>
        )}
      />
    );
  }
}

export default withRouter(RouterComponent);
