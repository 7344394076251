import anime from "animejs";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";

import Lottie from "react-lottie-player";
import lottieJson from "./assets/pulse.json";

interface Props {}

const WarningBackground: React.FC<Props> = () => {
  const { showWarningBackground } = useSelector((state: RootState) => {
    return {
      showWarningBackground: state.interactiveHelp.showWarningBackground,
    };
  });

  anime.set("#bg_orange", {
    opacity: 0,
  });

  anime({
    targets: "#bg_orange",
    opacity: 0.6,
    duration: 500,
    easing: "linear",
    loop: 1,
    complete: function () {
      anime({
        targets: "#bg_orange",
        opacity: 0.6,
        duration: 200,
        easing: "linear",
      });
    },
  });

  if (showWarningBackground) {
    return (
      <div style={{ touchAction: "none", pointerEvents: "none" }}>
        <div
          id="bg_orange"
          className="absolute top-0 left-0 z-0 flex items-center justify-center w-screen h-dvh bg-status-orange"
          style={{ opacity: 0, touchAction: "none" }}
        >
          <Lottie
            loop
            animationData={lottieJson}
            play
            speed={1.5}
            id="pulse"
            key="pulse"
          />
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default WarningBackground;
