import React from "react";
import { RootState } from "../../redux";
import { connect } from "react-redux";
import classNames from "classnames";

// types
const mapStateToProps = (state: RootState) => ({
  scaleFactor: state.swipe.scaleFactor,
});

const mapDispatchToProps = {};

type ScaledImageUnpositionedSpecificProps = {
  src: string;
  id: string;
  alt: string;
  className?: string;
  opacity: number;
};

type ScaledImageUnpositionedProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  ScaledImageUnpositionedSpecificProps;

type ScaledImageUnpositionedStates = {
  width: number;
};

// component
class ScaledImageUnpositioned extends React.Component<
  ScaledImageUnpositionedProps,
  ScaledImageUnpositionedStates
> {
  static defaultProps = {
    opacity: 1,
  };

  constructor(props: ScaledImageUnpositionedProps) {
    super(props);

    this.state = {
      width: 0,
    };
  }

  componentDidMount() {
    let img = new Image();
    img.src = this.props.src;
    img.onload = () => {
      this.setState({
        width: img.width,
      });
    };
  }

  componentWillUnmount() {
    // fix warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  render() {
    return (
      <img
        src={this.props.src}
        id={this.props.id}
        alt={this.props.alt}
        className={classNames(
          "transform select-none max-w-none",
          this.props.className
        )}
        style={{
          width: this.state.width * this.props.scaleFactor,
          opacity: this.props.opacity,
        }}
      />
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScaledImageUnpositioned);
