import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import QRCode from "qrcode.react";

interface Props {}

const Code: React.FC<Props> = () => {
  const { originalURL } = useSelector((state: RootState) => {
    return {
      originalURL: state.user.originalURL,
    };
  });

  return (
    <QRCode value={originalURL} style={{ width: "100%", height: "100%" }} />
  );
};

export default Code;
