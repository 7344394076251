import React, { ReactNode } from "react";
import classNames from "classnames";

// types
type HeaderBarProps = {
  children: ReactNode;
  className?: string;
};

// component
class HeaderBar extends React.Component<HeaderBarProps> {
  render() {
    return (
      <div
        className={classNames(
          " w-screen border-b-2 border-edding-red flex items-center bg-white",
          this.props.className
        )}
      >
        {this.props.children}
      </div>
    );
  }
}

export default HeaderBar;
