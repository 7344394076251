import React from "react";
import { RootState } from "../redux";
import { connect } from "react-redux";
import Button from "../components/common/ui/Button";
import { compose } from "redux";
import { withTranslation, WithTranslation } from "react-i18next";
import ScrollableHeightWithHeader from "../components/common/layouts/ScrollableHeightWithHeader";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { setErrorCode } from "../redux/modules/error";

import paper from "../assets/select/license_paper@2x.png";
import plastic from "../assets/select/license_plastic@2x.png";
import gray from "../assets/select/license_gray@2x.png";
import { LicenseType } from "../enums";
import StartDateChecker from "../components/common/StartDateChecker";

// types
const mapStateToProps = (state: RootState) => ({
  greeting: state.user.greeting,
  licenseType: state.user.licenseType,
  screenIsBigEnough: state.app.screenIsBigEnough,
  scaleFactor: state.swipe.scaleFactor,
  invocationIsValid: state.app.invocationIsValid,
});

const mapDispatchToProps = {
  setErrorCode,
};

type WelcomeBackgroundProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  WithTranslation &
  RouteComponentProps;

type WelcomeBackgroundStates = {
  redirect: any;
};

// component
class WelcomeBackground extends React.Component<
  WelcomeBackgroundProps,
  WelcomeBackgroundStates
> {
  render() {
    let title = "";
    let src = "";

    if (this.props.licenseType === LicenseType.Paper) {
      title = this.props.t("paper");
      src = paper;
    } else if (this.props.licenseType === LicenseType.Gray) {
      title = this.props.t("gray");
      src = gray;
    } else {
      title = this.props.t("plastic");
      src = plastic;
    }

    return (
      <StartDateChecker>
        <ScrollableHeightWithHeader>
          <div className="flex flex-col justify-between flex-grow w-full px-6 pt-4 text-center whitespace-pre-wrap">
            <div>
              <h1 className="text-lg font-bold">
                {this.props.t("welcome:title")}
                <br />
                {this.props.greeting}
              </h1>
            </div>
            <div>
              <div className="px-6">
                <div className={"px-4 py-2 mx-1 mb-4 rounded-lg shadow-lg"}>
                  <img
                    src={src}
                    alt={title}
                    className="object-contain w-auto h-40 mx-auto rounded-lg"
                  />
                  <div className="mt-2 font-semibold text-left text-edding-blue">
                    {title}
                  </div>
                </div>
              </div>

              <Button
                title={this.props.t("welcome:button")}
                onClick={() => {}}
                className="w-full my-4"
              />
            </div>
          </div>
        </ScrollableHeightWithHeader>
      </StartDateChecker>
    );
  }
}

export default withRouter(
  compose<any>(
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps)
  )(WelcomeBackground)
);
