import { ConnectivityStatus } from "@prismadelabs/prismaid";
import i18n from "../../i18n";
import { typedAction } from "../helpers";

export type SwipeResult = {
  title: string;
  message: string;
  shouldRender?: boolean;
};

type SwipeState = {
  scaleFactor: number;
  networkStatus: ConnectivityStatus;
  progress: number;
  showDot: boolean;
  showRedAlert: boolean;
  showGreenAlert: boolean;
  swipeResults: SwipeResult[];
  count: number;
  transactionData: string | undefined;
  transactionDataSignature: string | undefined;
  showSuccessAfterInteractiveHelp: boolean;
};

const initialState: SwipeState = {
  scaleFactor: 0.5,
  networkStatus: ConnectivityStatus.ok,
  progress: 0,
  showDot: false,
  showRedAlert: false,
  showGreenAlert: false,
  count: 0,
  swipeResults: [
    {
      title: i18n.t("swipe:place.title"),
      message: i18n.t("swipe:place.body"),
      shouldRender: true,
    },
  ],
  transactionData: undefined,
  transactionDataSignature: undefined,
  showSuccessAfterInteractiveHelp: false,
};

export const resetData = () => {
  return typedAction("swipe/RESET_DATA");
};

export const setScaleFactor = (scaleFactor: number) => {
  return typedAction("swipe/SET_SCALEFACTOR", scaleFactor);
};

export const setNetworkStatus = (networkStatus: ConnectivityStatus) => {
  return typedAction("swipe/SET_NETWORK_STATUS", networkStatus);
};

export const setProgress = (progress: number) => {
  return typedAction("swipe/SET_PROGRESS", progress);
};

export const setShowDot = (showDot: boolean) => {
  return typedAction("swipe/SET_SHOW_DOT", showDot);
};

export const setShowRedAlert = (showRedAlert: boolean) => {
  return typedAction("swipe/SET_SHOW_RED_ALERT", showRedAlert);
};

export const setShowGreenAlert = (showGreenAlert: boolean) => {
  return typedAction("swipe/SET_SHOW_GREEN_ALERT", showGreenAlert);
};

export const setCount = (count: number) => {
  return typedAction("swipe/SET_COUNT", count);
};

export const addSwipeResult = (result: SwipeResult) => {
  return typedAction("swipe/ADD_SWIPE_RESULT", result);
};

export const setSwipeResults = (results: SwipeResult[]) => {
  return typedAction("swipe/SET_SWIPE_RESULTS", results);
};

export const setTransactionData = (results: string | undefined) => {
  return typedAction("swipe/SET_TRANSACTION_DATA", results);
};

export const setTransactionDataSignature = (results: string | undefined) => {
  return typedAction("swipe/SET_TRANSACTION_DATA_SIGNATURE", results);
};

export const clearSwipeResults = () => {
  return typedAction("swipe/CLEAR_SWIPE_RESULTS");
};

export const getTransactionData = () => {
  return;
};

export const setShowSuccessAfterInteractiveHelp = (
  showSuccessAfterInteractiveHelp: boolean
) => {
  return typedAction(
    "swipe/SET_SHOW_SUCCESS_AFTER_INTERACTIVE_HELP",
    showSuccessAfterInteractiveHelp
  );
};

type SwipeAction = ReturnType<
  | typeof resetData
  | typeof setScaleFactor
  | typeof setNetworkStatus
  | typeof setProgress
  | typeof setShowDot
  | typeof setShowRedAlert
  | typeof setShowGreenAlert
  | typeof setCount
  | typeof addSwipeResult
  | typeof setSwipeResults
  | typeof setTransactionData
  | typeof setTransactionDataSignature
  | typeof clearSwipeResults
  | typeof setShowSuccessAfterInteractiveHelp
>;

export function swipeReducer(
  state = initialState,
  action: SwipeAction
): SwipeState {
  switch (action.type) {
    case "swipe/RESET_DATA":
      return {
        ...initialState,
      };
    case "swipe/SET_SCALEFACTOR":
      return {
        ...state,
        scaleFactor: action.payload,
      };
    case "swipe/SET_NETWORK_STATUS":
      return {
        ...state,
        networkStatus: action.payload,
      };
    case "swipe/SET_PROGRESS":
      return {
        ...state,
        progress: action.payload,
      };
    case "swipe/SET_SHOW_DOT":
      return {
        ...state,
        showDot: action.payload,
      };
    case "swipe/SET_SHOW_RED_ALERT":
      return {
        ...state,
        showRedAlert: action.payload,
      };
    case "swipe/SET_SHOW_GREEN_ALERT":
      return {
        ...state,
        showGreenAlert: action.payload,
      };
    case "swipe/SET_COUNT":
      return {
        ...state,
        count: action.payload,
      };
    case "swipe/ADD_SWIPE_RESULT":
      return {
        ...state,
        swipeResults: [...state.swipeResults, action.payload],
      };
    case "swipe/SET_SWIPE_RESULTS":
      return {
        ...state,
        swipeResults: action.payload,
      };
    case "swipe/SET_TRANSACTION_DATA":
      return {
        ...state,
        transactionData: action.payload,
      };
    case "swipe/SET_TRANSACTION_DATA_SIGNATURE":
      return {
        ...state,
        transactionDataSignature: action.payload,
      };
    case "swipe/CLEAR_SWIPE_RESULTS":
      return {
        ...state,
        count: 0,
        swipeResults: [
          {
            title: i18n.t("swipe:place.title"),
            message: i18n.t("swipe:place.body"),
            shouldRender: true,
          },
        ],
        showSuccessAfterInteractiveHelp: false,
      };
    case "swipe/SET_SHOW_SUCCESS_AFTER_INTERACTIVE_HELP":
      return {
        ...state,
        showSuccessAfterInteractiveHelp: action.payload,
      };
    default:
      return state;
  }
}
