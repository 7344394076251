import { HelpScreen } from "@prismadelabs/prismaid/dist/model/HelpScreen";
import { typedAction } from "../helpers";

type InteractiveHelpState = {
  showWarningBackground: boolean;
  showInteractiveHelp: boolean;
  helpScreen: HelpScreen | null;
};

const initialState: InteractiveHelpState = {
  showWarningBackground: false,
  showInteractiveHelp: false,
  helpScreen: null,
};

export const resetData = () => {
  return typedAction("interactiveHelp/RESET_DATA");
};

export const setShowWarningBackground = (showWarningBackground: boolean) => {
  return typedAction(
    "interactiveHelp/SET_SHOW_WARNING_BACKGROUND",
    showWarningBackground
  );
};

export const setShowInteractiveHelp = (showInteractiveHelp: boolean) => {
  return typedAction(
    "interactiveHelp/SET_SHOW_INTERACTIVE_HELP",
    showInteractiveHelp
  );
};

export const setHelpScreen = (helpScreen: HelpScreen | null) => {
  return typedAction("interactiveHelp/SET_HELP_SCREEN", helpScreen);
};

type InteractiveHelpAction = ReturnType<
  | typeof resetData
  | typeof setShowWarningBackground
  | typeof setShowInteractiveHelp
  | typeof setHelpScreen
>;

export function interactiveHelpReducer(
  state = initialState,
  action: InteractiveHelpAction
): InteractiveHelpState {
  switch (action.type) {
    case "interactiveHelp/RESET_DATA":
      return {
        ...initialState,
      };
    case "interactiveHelp/SET_SHOW_WARNING_BACKGROUND":
      return {
        ...state,
        showWarningBackground: action.payload,
      };
    case "interactiveHelp/SET_SHOW_INTERACTIVE_HELP":
      return {
        ...state,
        showInteractiveHelp: action.payload,
      };
    case "interactiveHelp/SET_HELP_SCREEN":
      return {
        ...state,
        helpScreen: action.payload,
      };
    default:
      return state;
  }
}
