import React, { createRef } from "react";
import { RootState } from "../redux";
import { connect } from "react-redux";
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
import ActiveSwipeCard from "../components/swipe/card/ActiveSwipeCard";
import ProgressBar from "../components/swipe/ProgressBar";
import NetworkIndicator from "../components/swipe/NetworkIndicator";
import SwipeField from "../components/swipe/card/SwipeField";
import { Helmet } from "react-helmet";
import { clearSwipeResults } from "../redux/modules/swipe";
import LockedHeightNoHeader from "../components/common/layouts/LockedHeightNoHeader";
import CloseButton from "../components/common/ui/CloseButton";
import { Redirect, RouteComponentProps, withRouter } from "react-router-dom";
import OrientationLock from "../components/common/layouts/OrientationLock";
import { setErrorCode } from "../redux/modules/error";
import { ErrorCode } from "../enums";
import { GA, GAEventType } from "../helpers/GA/ga";
import InteractiveHelp from "../components/interactiveHelp/InteractiveHelp";
import WarningBackground from "../components/interactiveHelp/WarningBackground";
import StartDateChecker from "../components/common/StartDateChecker";
import { setPageProgress } from "../redux/modules/app";

// types
const mapStateToProps = (state: RootState) => ({
  needsTouchSensitivity: state.app.needsTouchSensitivity,
  invocationIsValid: state.app.invocationIsValid,
});

const mapDispatchToProps = {
  clearSwipeResults,
  setErrorCode,
  setPageProgress,
};

type SwipeProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  RouteComponentProps;

type SwipeStates = {
  redirect: any;
};

// component
class Swipe extends React.Component<SwipeProps, SwipeStates> {
  private containerRef = createRef<HTMLDivElement>();

  constructor(props: SwipeProps) {
    super(props);

    this.state = {
      redirect: null,
    };
  }

  componentDidMount() {
    const container = this.containerRef.current;
    if (container) {
      disableBodyScroll(container);
    }

    if (!this.props.invocationIsValid) {
      new GA().trackEvent(window, GAEventType.Error_InvalidInvocation);
      this.setErrorCodeAndRedirect(ErrorCode.InvalidInvocation);
      return;
    }

    this.props.setPageProgress(50);

    new GA().trackPageView(window, "/swipe");
  }

  componentDidUpdate() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  componentWillUnmount() {
    clearAllBodyScrollLocks();

    // fix warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  setErrorCodeAndRedirect = (errorCode: ErrorCode) => {
    this.props.setErrorCode(errorCode);
    this.setState({
      redirect: "/error",
    });
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }

    return (
      <StartDateChecker>
        <OrientationLock>
          <div>
            <Helmet>
              <meta
                name="viewport"
                content="viewport-fit=cover, width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"
              />
            </Helmet>
            <div ref={this.containerRef} id="swipeScreen">
              <LockedHeightNoHeader>
                <ActiveSwipeCard style={{ touchAction: "none" }} />
                <SwipeField />
                <ProgressBar />
                <NetworkIndicator />
              </LockedHeightNoHeader>
            </div>
            <div className="absolute top-0 left-0">
              <CloseButton
                onClick={() => {
                  new GA().trackEvent(window, GAEventType.Swipe_ClickToGoBack);

                  this.props.clearSwipeResults();
                  this.props.setPageProgress(10);
                  this.props.history.push("/tutorial");
                }}
              />
            </div>
          </div>
          <WarningBackground />
          <InteractiveHelp />
        </OrientationLock>
      </StartDateChecker>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Swipe));
