import React from "react";
import { Redirect } from "react-router-dom";
import { RootState } from "../redux";
import { connect } from "react-redux";
import { setErrorCode } from "../redux/modules/error";
import { ErrorCode, LicenseType } from "../enums";
import { GA, GAEventType } from "../helpers/GA/ga";

// types
const mapStateToProps = (state: RootState) => ({
  invocationIsValid: state.app.invocationIsValid,
  deviceIsSupported: state.app.deviceIsSupported,
  browserIsSupported: state.app.browserIsSupported,
  screenIsBigEnough: state.app.screenIsBigEnough,
  displayZoomEnabled: state.app.displayZoomEnabled,

  licenseType: state.user.licenseType,
});

const mapDispatchToProps = {
  setErrorCode,
};

type AppProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

type AppStates = {
  redirect: any;
};

// component
class App extends React.Component<AppProps, AppStates> {
  constructor(props: AppProps) {
    super(props);

    this.state = {
      redirect: null,
    };

    document.addEventListener("touchstart", tapHandler);

    var tappedTwice = false;

    function tapHandler(event: { preventDefault: () => void }) {
      if (!tappedTwice) {
        tappedTwice = true;
        setTimeout(function () {
          tappedTwice = false;
        }, 300);
        return false;
      }
      event.preventDefault();
    }
  }

  componentDidMount() {
    if (!this.props.invocationIsValid) {
      new GA().trackEvent(window, GAEventType.Error_InvalidInvocation);
      this.setErrorCodeAndRedirect(ErrorCode.InvalidInvocation);
      return;
    }

    if (!this.props.browserIsSupported) {
      new GA().trackEvent(window, GAEventType.Error_UnsupportedBrowser);
      this.setErrorCodeAndRedirect(ErrorCode.UnsupportedBrowser);
      return;
    }

    if (!this.props.deviceIsSupported) {
      new GA().trackEvent(window, GAEventType.Error_UnsupportedDevice);
      this.setErrorCodeAndRedirect(ErrorCode.UnsupportedDevice);
      return;
    }

    if (this.props.displayZoomEnabled) {
      new GA().trackEvent(window, GAEventType.Error_DisplayZoomEnabled);
      this.setErrorCodeAndRedirect(ErrorCode.DisplayZoomEnabled);
      return;
    }

    new GA().trackPageView(window, "/app");
  }

  setErrorCodeAndRedirect = (errorCode: ErrorCode) => {
    this.props.setErrorCode(errorCode);
    this.setState({
      redirect: "/error",
    });
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }

    if (
      this.props.licenseType === LicenseType.Paper ||
      this.props.licenseType === LicenseType.Plastic ||
      this.props.licenseType === LicenseType.Gray
    ) {
      return <Redirect to="welcome" />;
    }

    return <Redirect to="select" />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
