import React from "react";
import { GA, GAEventType } from "../../../helpers/GA/ga";
import upload from "../../../assets/failure/icon_photoupload.svg";

type Props = {
  uploadUrl: string;
};

const OptionPhotoUpload2 = (props: Props) => {
  return (
    <div className="bg-edding-blue bg-opacity-15">
      <div className="px-2 py-3 mx-auto rounded md:max-w-sm">
        <a
          href={props.uploadUrl}
          onClick={() => {
            new GA().trackEvent(window, GAEventType.Failure_ClickToGoToPhotoUpload);
          }}
          className="flex flex-col items-center justify-between text-left"
        >
          <img
            src={upload}
            alt=""
            className="w-16 mt-2 mb-2 h-14"
          />
          <div
            className="text-sm underline"
            dangerouslySetInnerHTML={{
              __html: "Photo-Upload",
            }}
          ></div>
        </a>
      </div>
    </div>
  );
};

export default OptionPhotoUpload2;
