import classNames from "classnames";
import React, { ReactNode } from "react";

interface Props {
  children: ReactNode;
  className?: string;
}

const LockedHeightNoHeader: React.FC<Props> = (props) => {
  return <div className={classNames("w-screen h-dvh overflow-hidden bg-white", props.className)}>{props.children}</div>;
};

export default LockedHeightNoHeader;
