export function screenHeightIsBigEnough(scaleFactor: number) {
  const screenHeight = window.innerHeight;
  const minNeededScreenHeight = 1098 * scaleFactor; // 1098=height of cardImage

  if (screenHeight > minNeededScreenHeight) {
    return true;
  } else {
    return false;
  }
}
