import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { LicenseSide, LicenseType } from "../../../enums";

import plastic from "../img/license_plastic-front@2x.png";
import paper from "../img/license_paper-front@2x.png";
import gray from "../img/license_gray-front@2x.png";

import backPlastic from "../img/license_plastic-back@2x.png";
import backPaper from "../img/license_paper-back@2x.png";
import backGray from "../img/license_gray-back@2x.png";
import Button from "../../common/ui/Button";
import SlideCard from "./SlideCard";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux";
import { GA, GAEventType } from "../../../helpers/GA/ga";

type Props = {
  side: LicenseSide;
  nextSlide: any;
};

function PlacementSlide(props: Props) {
  const { t } = useTranslation("upload");
  const licenseType = useSelector((state: RootState) => state.user.licenseType);

  const [src, setSrc] = useState("");
  const [title, setTitle] = useState("");
  const [lines, setLines] = useState([""]);

  useEffect(() => {
    let src = "";
    let title = "";
    let lines = [""];

    switch (props.side) {
      case LicenseSide.Front1:
        switch (licenseType) {
          case LicenseType.Plastic:
            src = plastic;
            title = t("front.title.plastic");
            lines = [t("front.place.plastic")];
            break;
          case LicenseType.Paper:
            src = paper;
            title = t("front.title.paper");
            lines = [t("front.place.paper")];
            break;
          case LicenseType.Gray:
            src = gray;
            title = t("front.title.gray");
            lines = [t("front.place.gray")];
            break;
          default:
            break;
        }
        break;

      case LicenseSide.Back1:
        switch (licenseType) {
          case LicenseType.Plastic:
            src = backPlastic;
            title = t("back.title.plastic");
            lines = [t("back.labelVisible")];
            break;
          case LicenseType.Paper:
            src = backPaper;
            title = t("back.title.paper");
            lines = [t("back.labelVisible")];
            break;
          case LicenseType.Gray:
            src = backGray;
            title = t("back.title.gray");
            lines = [t("back.labelVisible")];
            break;
          default:
            break;
        }
        break;

      default:
        break;
    }

    setSrc(src);
    setTitle(title);
    setLines(lines);
  }, [props]);

  const handleOnClick = () => {
    props.nextSlide();
    new GA().trackEvent(window, GAEventType.Upload_Placement_ClickConfirm);
  };

  return (
    <div className="flex flex-col justify-end h-full">
      <div className="relative flex items-center w-2/3 mx-auto mb-8">
        <img
          src={src}
          alt="license"
          className="object-contain"
        />
        {/* currently this is implicitly only called for frontside 1/2 */}
        {/* HINT: if needed check for side */}

        {licenseType === LicenseType.Plastic && (
          <div className="absolute inset-x-0 text-sm font-semibold text-center -top-12 text-edding-red">{t("readability.name.plastic")}</div>
        )}
        {licenseType === LicenseType.Paper && (
          <div className="absolute inset-x-0 text-sm font-semibold text-center -top-12 text-edding-red">{t("readability.name.paper")}</div>
        )}
        {licenseType === LicenseType.Gray && (
          <div className="absolute inset-x-0 text-sm font-semibold text-center -top-12 text-edding-red">{t("readability.name.gray")}</div>
        )}

        {licenseType === LicenseType.Paper && <div className="absolute top-0 border-2 rounded border-edding-red bottom-75/100 left-5/100 right-80/100" />}
        {licenseType === LicenseType.Gray && <div className="absolute border-2 rounded top-10/100 border-edding-red bottom-75/100 left-10/100 right-65/100" />}
      </div>
      <div className="px-6 mb-6">
        <SlideCard
          title={title}
          lines={lines}
        />
      </div>
      <div>
        <div className="grid grid-cols-1 px-6 pb-4">
          <Button
            title={t("button.next")}
            onClick={handleOnClick}
          />
        </div>
      </div>
    </div>
  );
}

export default PlacementSlide;
