import React from "react";
import { RootState } from "../../redux";
import { connect } from "react-redux";
import anime from "animejs";

// types
const mapStateToProps = (state: RootState) => ({
  pageProgress: state.app.pageProgress,
});

const mapDispatchToProps = {};

type PageProgressSpecificProps = {};

type PageProgressProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  PageProgressSpecificProps;

type PageProgressStates = {};

// component
class PageProgress extends React.Component<
  PageProgressProps,
  PageProgressStates
> {
  static defaultProps = {};

  componentDidUpdate(prevProps: PageProgressProps) {
    if (this.props.pageProgress !== prevProps.pageProgress) {
      anime({
        targets: "#pageProgressBar",
        keyframes: [
          { scaleY: 3, scaleX: 1.1 },
          { scaleY: 1, scaleX: 1 },
        ],
        duration: 750,
        easing: "easeOutQuad",
      });
    }
  }

  componentWillUnmount() {
    // fix warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  render() {
    if (this.props.pageProgress === 0) return null;

    return (
      <div>
        <div
          className="absolute top-0 left-0 w-full h-1"
          style={{
            backgroundImage:
              // 10%steps light gray with dark ticks
              //   "linear-gradient(90deg, lightgray 9%, gray 9%, gray 10%, lightgray 10%,lightgray 19%, gray 19%, gray 20%, lightgray 20%,lightgray 29%, gray 29%, gray 30%, lightgray 30%,lightgray 39%, gray 39%, gray 40%, lightgray 40%,lightgray 49%, gray 49%, gray 50%, lightgray 50%,lightgray 59%, gray 59%, gray 60%, lightgray 60%,lightgray 69%, gray 69%, gray 70%, lightgray 70%,lightgray 79%, gray 79%, gray 80%, lightgray 80%,lightgray 89%, gray 89%, gray 90%, lightgray 90%)",
              // 10% steps light gray with white ticks
              //   "linear-gradient(90deg, lightgray 9%, white 9%, white 10%, lightgray 10%,lightgray 19%, white 19%, white 20%, lightgray 20%,lightgray 29%, white 29%, white 30%, lightgray 30%,lightgray 39%, white 39%, white 40%, lightgray 40%,lightgray 49%, white 49%, white 50%, lightgray 50%,lightgray 59%, white 59%, white 60%, lightgray 60%,lightgray 69%, white 69%, white 70%, lightgray 70%,lightgray 79%, white 79%, white 80%, lightgray 80%,lightgray 89%, white 89%, white 90%, lightgray 90%)",
              // 10% steps light gray with small white ticks
              "linear-gradient(90deg, lightgray 9.5%, white 9.5%, white 10%, lightgray 10%,lightgray 19.5%, white 19.5%, white 20%, lightgray 20%,lightgray 29.5%, white 29.5%, white 30%, lightgray 30%,lightgray 39.5%, white 39.5%, white 40%, lightgray 40%,lightgray 49.5%, white 49.5%, white 50%, lightgray 50%,lightgray 59.5%, white 59.5%, white 60%, lightgray 60%,lightgray 69.5%, white 69.5%, white 70%, lightgray 70%,lightgray 79.5%, white 79.5%, white 80%, lightgray 80%,lightgray 89.5%, white 89.5%, white 90%, lightgray 90%)",
          }}
        >
          <div
            id="pageProgressBar"
            className="absolute top-0 left-0 w-full h-1 transition-all bg-status-green animate-progress"
            style={{ width: `${this.props.pageProgress}%` }}
          />
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PageProgress);
