import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import Anime from "@mollycule/react-anime";
import CenteredLayout from "../common/ui/CenteredLayout";
import Card from "../common/ui/Card";
import Button from "../common/ui/Button";
import { GA } from "../../helpers/GA/ga";
import { setHelpScreen, setShowInteractiveHelp } from "../../redux/modules/interactiveHelp";
import { HelpScreen, HelpScreenButtonType } from "@prismadelabs/prismaid/dist/model/HelpScreen";
import { useBodyScrollLock } from "../../hooks/BodyScrollLock";

import cardSize from "../../assets/interactiveHelp/icon_original-size@2x.png";
import cardSlipped from "../../assets/interactiveHelp/icon_card-slipped@2x.png";
import deviceInHand from "../../assets/interactiveHelp/icon_hold-phone@2x.png";
import holdFirm from "../../assets/interactiveHelp/icon_hold@2x.png";
import phone from "../../assets/interactiveHelp/icon_phone@2x.png";
import progress from "../../assets/interactiveHelp/icon_progress-bar@2x.png";
import screenProtector from "../../assets/interactiveHelp/icon_screen-protector@2x.png";

import Lottie from "react-lottie-player";
import flatFingerLottie from "../../assets/interactiveHelp/hand-swipe-angle.json";

interface Props {}

const InteractiveHelp: React.FC<Props> = () => {
  const { showInteractiveHelp, helpScreen } = useSelector((state: RootState) => {
    return {
      showInteractiveHelp: state.interactiveHelp.showInteractiveHelp,
      helpScreen: state.interactiveHelp.helpScreen,
    };
  });

  const dispatch = useDispatch();
  const { t } = useTranslation();

  useBodyScrollLock();

  if (showInteractiveHelp && helpScreen) {
    let images = null;
    let buttons = null;

    switch (helpScreen!.screenId) {
      case "card_size":
        images = (
          <div>
            <img
              src={cardSize}
              id="cardSize"
              alt=""
              className="w-auto h-32 mx-auto"
            />
          </div>
        );
        break;
      case "card_slide":
        images = (
          <div>
            <img
              src={cardSlipped}
              id="slippingCard"
              alt=""
              className="w-auto h-32 mx-auto"
            />
          </div>
        );
        break;
      case "flat_finger":
        images = (
          <div>
            <Lottie
              loop
              animationData={flatFingerLottie}
              play
              id="flat_finger"
              key="flat_finger"
              className="w-auto h-32 mx-auto"
            />
          </div>
        );
        break;
      case "device_in_hand":
        images = (
          <div>
            <img
              src={deviceInHand}
              id="deviceInHand"
              alt=""
              className="w-auto h-32 mx-auto"
            />
          </div>
        );
        break;
      case "hold_firm":
        images = (
          <div>
            <img
              src={holdFirm}
              id="holdFirm"
              alt=""
              className="w-auto h-32 mx-auto"
            />
          </div>
        );
        break;
      case "greenbar_full":
        images = (
          <div>
            <div className="grid grid-cols-1">
              <Anime
                appear
                in
                duration={2500}
                delay={500}
                easing="linear"
                loop
                endDelay={500}
                onEntering={{ scaleY: [0, 1] }}
              >
                <img
                  src={progress}
                  id="progress"
                  alt=""
                  className="top-0 w-auto h-32 col-start-1 row-start-1 mx-auto origin-bottom"
                  style={{ transformOrigin: "50% 90%" }}
                />
              </Anime>
              <img
                src={phone}
                id="phone"
                alt=""
                className="z-50 w-auto h-32 col-start-1 row-start-1 mx-auto"
              />
            </div>
          </div>
        );
        break;
      case "greenbar_visible":
        images = (
          <div>
            <div className="grid grid-cols-1">
              <Anime
                appear
                in
                duration={2500}
                delay={500}
                easing="linear"
                loop
                endDelay={500}
                onEntering={{ scaleY: [0, 1] }}
              >
                <img
                  src={progress}
                  id="progress"
                  alt=""
                  className="top-0 w-auto h-32 col-start-1 row-start-1 mx-auto origin-bottom"
                  style={{ transformOrigin: "50% 90%" }}
                />
              </Anime>
              <img
                src={phone}
                id="phone"
                alt=""
                className="z-50 w-auto h-32 col-start-1 row-start-1 mx-auto"
              />
            </div>
          </div>
        );
        break;
      case "screen_protector":
        images = (
          <div>
            <img
              src={screenProtector}
              id="screenProtector"
              alt=""
              className="w-auto h-32 mx-auto"
            />
          </div>
        );
        break;
      case "swipe_without_card":
        images = (
          <div>
            <img
              src={screenProtector}
              id="screenProtector"
              alt=""
              className="w-auto h-32 mx-auto"
            />
          </div>
        );
        break;
      default:
        images = null;
        break;
    }

    const onButtonClick = (helpScreen: HelpScreen, buttonAction: { (): void; (): void }, buttonType: HelpScreenButtonType) => {
      new GA().trackInteractiveHelpClick(window, helpScreen.screenId, buttonType);

      buttonAction();
      dispatch(setHelpScreen(null));
      dispatch(setShowInteractiveHelp(false));
    };

    let buttonCount = helpScreen!.actions.length;
    buttons = (
      <div className={classNames("grid gap-4", { "grid-cols-1": buttonCount === 1 }, { "grid-cols-2": buttonCount === 2 })}>
        {helpScreen!.actions.map((action) => (
          <Button
            title={t("interactiveHelp:" + action.buttonType.toString())}
            onClick={() => onButtonClick(helpScreen, action.buttonAction, action.buttonType)}
            key={action.buttonType}
          />
        ))}
      </div>
    );

    // TODO remove if is not being sent anymore
    if (helpScreen!.screenId === "device_in_hand") return null;

    return (
      <div className="absolute top-0 z-30 w-screen h-dvh overflow-hidden">
        <CenteredLayout className="bg-gray-200 bg-opacity-50 backdrop-blur">
          <Card className="relative w-full h-full border-4 border-status-orange">
            <div className="mt-4 space-y-6 text-lg">
              <div>
                <p>{t("interactiveHelp:" + helpScreen!.screenId)}</p>
              </div>
              {images}
              {buttons}
            </div>
          </Card>
        </CenteredLayout>
      </div>
    );
  } else {
    return null;
  }
};

export default InteractiveHelp;
